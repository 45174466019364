import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, CardTitle } from "reactstrap";

import { IArke } from "@intreba/arke-api-client";
import history from "../../services/history";
interface InjectedProps extends IProps {
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("arke")
@observer
class ChooseAccount extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  private navigateToHostPage = () => {
    history.push("/accounts/host");
  };

  private navigateToNewPage = () => {
    history.push("/accounts/new");
  };

  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardTitle>
          <p>{t("chooseIntro")}</p>
        </CardTitle>
        <Button
          onClick={this.navigateToHostPage}
          color="primary"
          outline={true}
          style={{ marginBottom: "1rem" }}
        >
          {t("hostButton")}
        </Button>
        <Button color="primary" outline={true} onClick={this.navigateToNewPage}>
          {t("accountButton")}
        </Button>
      </>
    );
  }
}

export default withTranslation("newAccount")(ChooseAccount);
