import { SitePermissions, SiteResponseV1 } from "@intreba/arke-api-client";
import * as React from "react";
import {
  Home,
  LogIn,
  MessageSquare,
  Settings,
  Users,
  ChevronDown,
  ChevronUp,
  MapPin,
  Target,
} from "react-feather";
import { Route } from "react-router-dom";
import { NavItem } from "reactstrap";
import ShowWithPermission from "../../components/user/ShowWithPermission";
import { MenuLink } from "./MenuLink";
import { useTranslation } from "react-i18next";

export const SiteMenu: React.FC<{ site: SiteResponseV1 }> = (props) => {
  const { t } = useTranslation("menu");
  const settingsLink = `/sites/${props.site.siteId}/settings`;
  const visitsLink = `/sites/${props.site.siteId}/visits`;
  const messageLink = `/sites/${props.site.siteId}/messaging`;
  return (
    <>
      <NavItem className="mb-3">
        <MapPin
          style={{
            verticalAlign: "middle",
            marginRight: "5px",
          }}
        />
        <span
          className="font-weight-bold"
          style={{
            fontSize: "1.5rem",
          }}
        >
          {props.site.name}
        </span>
      </NavItem>
      <ShowWithPermission permission={SitePermissions.sitestatisticsread}>
        <NavItem key="dashboard">
          <MenuLink
            to={`/sites/${props.site.siteId}/dashboard`}
            activeOnlyWhenExact={true}
          >
            <Home />
            {t("dashboard")}
          </MenuLink>
        </NavItem>
      </ShowWithPermission>

      <ShowWithPermission permission={SitePermissions.visitsread}>
        <NavItem key="visits">
          <MenuLink
            to={`/sites/${props.site.siteId}/visits`}
            routePath={`/sites/${props.site.siteId}/visits`}
            activeOnlyWhenExact={false}
          >
            <LogIn />
            {t("visits")}
            <Route
              path={visitsLink}
              exact={false}
              children={({ match }) => {
                return match === null ? <ChevronDown /> : <ChevronUp />;
              }}
            />
          </MenuLink>
          <Route
            path={visitsLink}
            exact={false}
            children={({ match }) => {
              return match === null ? null : (
                <ul>
                  <ShowWithPermission permission={SitePermissions.visitsread}>
                    <MenuLink
                      to={`/sites/${props.site.siteId}/visits`}
                      activeOnlyWhenExact={true}
                    >
                      {t("visits")}
                    </MenuLink>
                  </ShowWithPermission>
                  <ShowWithPermission permission={SitePermissions.visitscreate}>
                    <MenuLink
                      to={`/sites/${props.site.siteId}/visits/create`}
                      activeOnlyWhenExact={true}
                    >
                      {t("createvisit")}
                    </MenuLink>
                  </ShowWithPermission>
                </ul>
              );
            }}
          />
        </NavItem>
      </ShowWithPermission>
      <ShowWithPermission permission={SitePermissions.watchlistread}>
        <NavItem key="watchlist">
          <MenuLink
            to={`/sites/${props.site.siteId}/watchlist`}
            activeOnlyWhenExact={false}
          >
            <Target />
            {t("watchlist")}
          </MenuLink>
        </NavItem>
      </ShowWithPermission>
      <ShowWithPermission permission={SitePermissions.hostsread}>
        <NavItem key="hosts">
          <MenuLink
            to={`/sites/${props.site.siteId}/hosts`}
            activeOnlyWhenExact={false}
          >
            <Users />
            {t("hosts")}
          </MenuLink>
        </NavItem>
      </ShowWithPermission>
      <NavItem key="messaging">
        <MenuLink
          to={`${messageLink}/conversations`}
          activeOnlyWhenExact={false}
        >
          <MessageSquare />
          {t("messaging")}
          <Route
            path={messageLink}
            exact={false}
            children={({ match }) => {
              return match === null ? <ChevronDown /> : <ChevronUp />;
            }}
          />
        </MenuLink>
        <Route
          path={`/sites/${props.site.siteId}/messaging`}
          exact={false}
          children={({ match }) => {
            return match === null ? null : (
              <ul>
                <MenuLink
                  to={`/sites/${props.site.siteId}/messaging/conversations`}
                  activeOnlyWhenExact={false}
                >
                  {t("conversations")}
                </MenuLink>
                <ShowWithPermission
                  permission={SitePermissions.broadcastcreate}
                >
                  <MenuLink
                    to={`/sites/${props.site.siteId}/messaging/broadcasts`}
                    activeOnlyWhenExact={false}
                  >
                    {t("broadcasts")}
                  </MenuLink>
                </ShowWithPermission>
              </ul>
            );
          }}
        />
      </NavItem>
      <ShowWithPermission permission={SitePermissions.sitesettingsread}>
        <NavItem key="settings">
          <MenuLink
            to={`/sites/${props.site.siteId}/settings/general`}
            routePath={`/sites/${props.site.siteId}/settings`}
            activeOnlyWhenExact={false}
          >
            <Settings />
            {t("settings")}
            <Route
              path={settingsLink}
              exact={false}
              children={({ match }) => {
                return match === null ? <ChevronDown /> : <ChevronUp />;
              }}
            />
          </MenuLink>
          <Route
            path={settingsLink}
            exact={false}
            children={({ match }) => {
              return match === null ? null : (
                <ul>
                  <ShowWithPermission
                    permission={SitePermissions.sitesettingsread}
                  >
                    <MenuLink
                      to={`/sites/${props.site.siteId}/settings/general`}
                      activeOnlyWhenExact={true}
                    >
                      {t("sitesettings:general")}
                    </MenuLink>
                  </ShowWithPermission>
                  <ShowWithPermission
                    permission={SitePermissions.sitesettingsread}
                  >
                    <MenuLink
                      to={`/sites/${props.site.siteId}/settings/additionalfields`}
                      activeOnlyWhenExact={false}
                    >
                      {t("sitesettings:additionalfields")}
                    </MenuLink>
                  </ShowWithPermission>
                  <ShowWithPermission
                    permission={SitePermissions.sitesettingsread}
                  >
                    <MenuLink
                      to={`/sites/${props.site.siteId}/settings/screening`}
                      activeOnlyWhenExact={true}
                    >
                      {t("sitesettings:screening")}
                    </MenuLink>
                  </ShowWithPermission>

                  <ShowWithPermission
                    permission={SitePermissions.siteusersettingsread}
                  >
                    <MenuLink
                      to={`/sites/${props.site.siteId}/settings/users`}
                      activeOnlyWhenExact={false}
                    >
                      {t("sitesettings:users")}
                    </MenuLink>
                  </ShowWithPermission>
                  <ShowWithPermission
                    permission={SitePermissions.sitemessagetemplatesread}
                  >
                    <MenuLink
                      to={`/sites/${props.site.siteId}/settings/messages`}
                      activeOnlyWhenExact={true}
                    >
                      {t("sitesettings:messages")}
                    </MenuLink>
                  </ShowWithPermission>
                  <ShowWithPermission
                    permission={SitePermissions.sitenotificationsettingsread}
                  >
                    <MenuLink
                      to={`/sites/${props.site.siteId}/settings/notifications`}
                      activeOnlyWhenExact={true}
                    >
                      {t("sitesettings:notifications")}
                    </MenuLink>
                  </ShowWithPermission>
                  <ShowWithPermission
                    permission={
                      SitePermissions.sitehostprovisioningsettingsread
                    }
                  >
                    <MenuLink
                      to={`/sites/${props.site.siteId}/settings/hosts`}
                      activeOnlyWhenExact={true}
                    >
                      {t("sitesettings:hosts")}
                    </MenuLink>
                  </ShowWithPermission>
                  <ShowWithPermission
                    permission={SitePermissions.siteaccesssettingsread}
                  >
                    {" "}
                    <MenuLink
                      to={`/sites/${props.site.siteId}/settings/access`}
                      activeOnlyWhenExact={true}
                    >
                      {t("sitesettings:access")}
                    </MenuLink>
                  </ShowWithPermission>
                </ul>
              );
            }}
          />
        </NavItem>
      </ShowWithPermission>
    </>
  );
};
