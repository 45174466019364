import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CardText, CardTitle } from "reactstrap";
import { Spinner } from "../../components";
import AppState from "../../services/AppState";

export interface InjectedProps extends IProps {
  appState: AppState;
}

export interface IProps extends WithTranslation {}

@inject("appState")
@observer
class Loading extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public render() {
    const t = this.props.t!;
    return (
      <>
        <CardTitle>
          {t("welcome")}{" "}
          {this.injectedprops.appState.authState.hasUser &&
            this.injectedprops.appState.authState.userName}
        </CardTitle>
        <CardText>
          {this.injectedprops.appState.authState.error && (
            <span className="text-danger">{t("error")}</span>
          )}
          {!this.injectedprops.appState.authState.error &&
            (this.injectedprops.appState.authState.hasUser
              ? t("gatheringDetails")
              : t("loggingIn"))}
        </CardText>

        {!this.injectedprops.appState.authState.error && <Spinner />}
      </>
    );
  }
}

export default withTranslation("splashscreen")(Loading);
