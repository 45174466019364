import {
  PhoneNumberFormat,
  PhoneNumberType,
  PhoneNumberUtil
} from "google-libphonenumber";

export default class PhoneNumberUtility {
  public phoneNumberUtil: libphonenumber.PhoneNumberUtil = PhoneNumberUtil.getInstance();

  public validatePhone(inputNumber: string, countryCode: string): boolean {
    try {
      const phoneNumber = this.phoneNumberUtil.parse(inputNumber, countryCode);
      const validNumber = this.phoneNumberUtil.isValidNumber(phoneNumber);
      if (!validNumber && inputNumber[0] !== "+") {
        inputNumber = "+" + inputNumber;
        return this.validateMobile(inputNumber, countryCode);
      }
      if (validNumber) {
        const nationalNumber = phoneNumber.getNationalNumber();
        if (nationalNumber === undefined) {
          return false;
        } else {
          // ZA lib marks less than 9 digit numbers as valid mobiles even though they're not.
          if (countryCode === "ZA" && nationalNumber.toString().length < 9) {
            return false;
          }
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public validateMobile(inputNumber: string, countryCode: string): boolean {
    try {
      const phoneNumber = this.phoneNumberUtil.parse(inputNumber, countryCode);
      const validNumber = this.phoneNumberUtil.isValidNumber(phoneNumber);
      if (!validNumber && inputNumber[0] !== "+") {
        inputNumber = "+" + inputNumber;
        return this.validateMobile(inputNumber, countryCode);
      }
      if (validNumber) {
        const type = this.phoneNumberUtil.getNumberType(phoneNumber);
        const isValidMobile =
          type === PhoneNumberType.FIXED_LINE_OR_MOBILE ||
          type === PhoneNumberType.MOBILE ||
          type === PhoneNumberType.UNKNOWN;
        const nationalNumber = phoneNumber.getNationalNumber();
        if (nationalNumber === undefined) {
          return false;
        } else {
          // ZA lib marks less than 9 digit numbers as valid mobiles even though they're not.
          if (countryCode === "ZA" && nationalNumber.toString().length < 9) {
            return false;
          }
          return isValidMobile;
        }
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  public friendlyFormat(inputNumber: string, countryCode: string): string {
    try {
      let phoneNumber = this.phoneNumberUtil.parse(inputNumber, countryCode);
      const validNumber = this.phoneNumberUtil.isValidNumber(phoneNumber);
      if (!validNumber && inputNumber[0] !== "+") {
        inputNumber = "+" + inputNumber;
        phoneNumber = this.phoneNumberUtil.parse(inputNumber, countryCode);
      }
      return this.phoneNumberUtil.format(
        phoneNumber,
        PhoneNumberFormat.NATIONAL
      );
    } catch (e) {
      return inputNumber;
    }
  }
  public format(inputNumber: string, countryCode: string): string | null {
    try {
      let phoneNumber = this.phoneNumberUtil.parse(inputNumber, countryCode);
      const validNumber = this.phoneNumberUtil.isValidNumber(phoneNumber);
      if (!validNumber && inputNumber[0] !== "+") {
        inputNumber = "+" + inputNumber;
        phoneNumber = this.phoneNumberUtil.parse(inputNumber, countryCode);
      }
      return this.phoneNumberUtil
        .format(phoneNumber, PhoneNumberFormat.E164)
        .replace("+", "");
    } catch (e) {
      console.error(e);
      // tslint:disable-next-line:no-null-keyword
      return null;
    }
  }
}
