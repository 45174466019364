import { Link, Route } from "react-router-dom";
import { NavLink } from "reactstrap";
import * as React from "react";

const MenuLink = (props: {
  to: string;
  activeOnlyWhenExact: boolean;
  routePath?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Route
      path={props.routePath === undefined ? props.to : props.routePath}
      exact={props.activeOnlyWhenExact}
      // tslint:disable-next-line:jsx-no-lambda
      children={({ match }) => {
        return (
          <NavLink tag={Link} to={props.to} active={match !== null}>
            {props.children}
          </NavLink>
        );
      }}
    />
  );
};
export { MenuLink };
