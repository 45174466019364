import { VisitDocumentResponseV1 } from "@intreba/arke-api-client";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { ChevronLeft, Truck, User } from "react-feather";
import {
  withTranslation,
  WithTranslation,
  useTranslation
} from "react-i18next";
import { RouteComponentProps } from "react-router";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import ListGroup from "reactstrap/lib/ListGroup";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import { Spinner } from "../../components";
import VisitsStore from "./VisitsStore";

const VisitDocument = (props: {
  document: VisitDocumentResponseV1;
  t: i18n.TFunction;
}) => {
  const documentNodes = [];
  const { t } = useTranslation("iddocuments");
  for (const key of Object.keys(props.document.content)
    .filter(k => props.document.content[k] !== "")
    .sort()) {
    const value = props.document.content[key];
    documentNodes.push(
      <React.Fragment key={key}>
        <span
          style={{ textTransform: "capitalize", display: "inline" }}
          className="font-weight-bold"
        >
          {t(key)}:{" "}
        </span>
        <span style={{ marginBottom: 0, display: "inline" }}>{value}</span>
        <br />
      </React.Fragment>
    );
  }

  return (
    <Row>
      <Col sm={12}>
        {props.document.itemType === 1000 && <User />}
        {props.document.itemType === 2000 && <Truck />}
        <h5>{props.document.summary}</h5>
        <h6>{t(`type${props.document.documentType}`)}</h6>
        <Row>
          {props.document.imageUrls.length > 0 && (
            <Col sm={3}>
              {props.document.imageUrls.map(imageUrl => (
                <img src={imageUrl} alt="" />
              ))}
            </Col>
          )}
          <Col sm={9} style={{ columnCount: 3 }}>
            {documentNodes}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

interface InjectedProps extends IProps {
  visitsStore: VisitsStore;
}

interface IProps extends WithTranslation {}

@inject("visitsStore")
@observer
class VisitDocumentPageContent extends React.Component<
  InjectedProps & RouteComponentProps<{ visitId: string }>,
  {}
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public async componentWillMount() {
    this.injectedprops.visitsStore.signalIsGettingItems();
  }
  public async componentDidMount() {
    await this.injectedprops.visitsStore.getVisitAndDetails(
      this.props.match.params.visitId
    );
    await this.injectedprops.visitsStore.getVisitDocuments(
      this.props.match.params.visitId
    );
  }

  private goBack = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.props.history.push(this.props.visitsStore.currentVisitUrl);
  };
  public render() {
    const t = this.props.t;
    return (
      <>
        <CardHeader>
          <a
            href={this.props.visitsStore.currentVisitUrl}
            style={{ marginLeft: "-10px" }}
            onClick={this.goBack}
          >
            <ChevronLeft />
            {t("back")}
          </a>
          <h2 style={{ marginTop: "15px" }}>{t("title")}</h2>
        </CardHeader>
        <CardBody>
          {this.injectedprops.visitsStore.isGettingItems === true && (
            <Spinner />
          )}
          {this.injectedprops.visitsStore.isGettingItems === false &&
            this.injectedprops.visitsStore.selectedItem === null && (
              <h2>{t("common:notfound")}</h2>
            )}
          <ListGroup>
            {this.injectedprops.visitsStore.visitDocuments.map(d => (
              <ListGroupItem key={d.documentId}>
                <VisitDocument document={d} t={t} />
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </>
    );
  }
}

const VisitDocumentPage = withTranslation("visitDocuments")(
  VisitDocumentPageContent
);

export { VisitDocumentPage };
