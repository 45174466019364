import { inject, observer, Provider } from "mobx-react";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Card from "reactstrap/lib/Card";
import CardImg from "reactstrap/lib/CardImg";
import ArkeLogoDark from "../../images/arke_logo_dark.png";
import AppState from "../../services/AppState";
import ChooseAccount from "../../views/accounts/ChooseAccount";
import Host from "../../views/accounts/Host";
import NewAccount from "../../views/accounts/NewAccount";
import StatusPage from "../../views/accounts/StatusPage";
import NewSite from "../../views/sites/NewSite";

interface IProps {
  appState: AppState;
}
@inject("appState")
@observer
export default class AccountRouter extends React.Component<
  IProps & RouteComponentProps<{ accountId: string }>,
  {}
> {
  public componentWillMount() {
    this.props.appState.setAccount(this.props.match.params.accountId);
  }

  public render() {
    console.debug(
      `Rendering account router for account ${
        (this.props as any).match.params.accountId
      }`
    );
    return (
      <Provider account={this.props.appState.itemContainer.account}>
        <div className="wrapper-center">
          <Card body={true} style={{ maxWidth: "500px" }}>
            <CardImg top={true} src={ArkeLogoDark} alt="Arke logo" />
            <Switch>
              <Route path="/accounts/choose" component={ChooseAccount} />
              <Route path="/accounts/new" component={NewAccount} />
              <Route path="/accounts/host" component={Host} />
              <Route
                path="/accounts/:accountId/status"
                component={StatusPage}
              />
              <Route
                path={`${this.props.match.path}/sites/add`}
                component={NewSite}
              />
            </Switch>
          </Card>
        </div>
      </Provider>
    );
  }
}
