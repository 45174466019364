import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import history from "../../services/history";
import AuthState from "./AuthState";
import { Signin } from "./Signin";
import { SigninCallback } from "./SigninCallback";
const LoginRedirect = () => {
  const location = history.location.pathname + history.location.search;
  window.localStorage.setItem("lastLocation", `${location}`);
  console.debug("Redirecting to login");
  return <Redirect to="/login" />;
};

export default class AuthFlow extends React.Component<
  { authState: AuthState },
  {}
> {
  private loginRedirect = () => {
    if (!this.props.authState.hasUser) {
      return <LoginRedirect />;
    } else {
      console.debug("Already has user, not redirecting to login");
      return null;
    }
  };

  public render() {
    return (
      <Switch>
        <Route path="/" exact={true} component={this.loginRedirect} />
        <Route path="/login" exact={true} component={Signin} />
        <Route path="/login/callback" exact={true} component={SigninCallback} />
        <Route component={this.loginRedirect} />
      </Switch>
    );
  }
}
