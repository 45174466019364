import * as React from "react";
import { Route, Switch } from "react-router-dom";
import AccountRouter from "./AccountRouter";
import ErrorHandler from "./ErrorHandler";
import SiteRouter from "./SiteRouter";

export default class MainRouter extends React.Component {
  public render() {
    console.debug("Rendering main router");
    return (
      <ErrorHandler>
        <Switch>
          <Route path="/sites/:siteId" component={SiteRouter} />
          <Route path="/accounts/:accountId" component={AccountRouter} />
        </Switch>
      </ErrorHandler>
    );
  }
}
