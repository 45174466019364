import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  Col,
  FormGroup,
  FormText,
  Label,
  Row,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  IArke,
  SiteResponseV1,
  VisitLimitMode,
} from "@intreba/arke-api-client";
import Input from "reactstrap/lib/Input";
import { AccessSettingsState } from "./AccessSettingsState";
import {
  WithTranslation,
  withTranslation,
  useTranslation,
} from "react-i18next";
import { DailyLimitDropdown } from "./DailyLimitDropdown";
import FunctionalityRequiresClientUpgradeAlert from "../../../../services/sitestatus/FunctionalityRequiresClientUpgradeAlert";
import { useState } from "react";

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
  accessSettings: AccessSettingsState;
}

interface IProps extends WithTranslation {
  accessSettings: AccessSettingsState;
}

const VisitLimitModeSelector = (props: {
  value: number | null;
  onModeChange: (key: number) => void;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation("sitesettingsaccess");
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const changeVisitMode = (key: number) => {
    props.onModeChange(key);
  };
  if (props.value === null) {
    return null;
  }

  return (
    <Row>
      <Col sm={4}>
        <Label for="doorFiltering">{t("visitLimitMode")}</Label>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle style={{ width: "100%" }} caret disabled={false}>
              {VisitLimitMode[props.value]}
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%" }}>
              {Object.keys(VisitLimitMode)
                .filter((k) => typeof VisitLimitMode[k as any] === "number")
                .map((value, key) => {
                  return (
                    <DropdownItem
                      key={key}
                      onClick={() => {
                        changeVisitMode(key);
                      }}
                    >
                      {value}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </Dropdown>

          <FormText color="muted">{t("visitLimitModeHelp")}</FormText>
        </FormGroup>
      </Col>{" "}
    </Row>
  );
};

@inject("itemContainer", "arke")
@observer
class DailyVisitLimitContent extends React.Component<
  IProps,
  {
    visitLimitModeDropdownOpen: boolean;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  private accessSettings: AccessSettingsState;
  public constructor(props: any) {
    super(props);
    this.accessSettings = this.injectedprops.accessSettings;
    this.state = {
      visitLimitModeDropdownOpen: false,
    };
  }

  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardBody>
          <Row>
            <Col>
              <FunctionalityRequiresClientUpgradeAlert
                version="2.1.20"
                messageName={"simultaneousVisits"}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup row={true}>
                <Col sm={10}>
                  <VisitLimitModeSelector
                    value={
                      this.injectedprops.accessSettings.globalLimitSettings
                        .visitLimitMode
                    }
                    onModeChange={(key: number) => {
                      this.injectedprops.accessSettings.onVisitLimitModeChange(
                        key
                      );
                    }}
                  />

                  <Row>
                    <Col sm={4}>
                      <Label for="defaultVisitLimit">
                        {t("defaultVisitLimitValue")}
                      </Label>
                    </Col>
                    <Col sm={6}>
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <Label>
                              <Input
                                disabled={this.accessSettings.disabled}
                                type="number"
                                name="groupDailyVisitLimitCount"
                                id="groupDailyVisitLimitCount"
                                min={1}
                                value={
                                  this.accessSettings.globalLimitSettings
                                    .globalDailyVisitLimit
                                    ? this.accessSettings.globalLimitSettings
                                        .globalDailyVisitLimit
                                    : ""
                                }
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={(event) =>
                                  this.accessSettings.changeHostDailyLimit(
                                    event.target.valueAsNumber
                                  )
                                }
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col sm={4}></Col>
                      </Row>
                    </Col>
                  </Row>
                  <p style={{ fontSize: 15, color: "grey" }}>
                    {t("dailyVisitLimitByGroup")}
                  </p>
                  <hr style={{ marginTop: -10 }} />
                  {this.accessSettings.globalLimitSettings.groupVisitLimitOverridesObjects.map(
                    (g, key) => {
                      return (
                        <Row key={key} style={{ paddingBottom: 5 }}>
                          <Col sm={4}>{g.groupName}</Col>
                          <Col sm={6}>
                            <DailyLimitDropdown
                              key={key}
                              groupId={g.groupId}
                              state={g.inputState}
                              disabled={this.accessSettings.disabled}
                              onInputChange={
                                this.accessSettings.onDailyVisitLimitInputChange
                              }
                              onDropdownChange={
                                this.accessSettings.onDailyLimitDropdownChange
                              }
                              value={g.dailyLimit}
                              editable={true}
                            />
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup row={true}>
                <Col lg={10}>
                  <FormText color="muted">
                    {t("allowDailyVisitLimitOverrideByStaffHelp")}
                  </FormText>
                </Col>
                <Col lg={10}>
                  <FormGroup check={true}>
                    <Label check={true}>
                      <Input
                        disabled={this.accessSettings.disabled}
                        type="checkbox"
                        name="allowDailyVisitLimitOverrideByStaff"
                        id="allowDailyVisitLimitOverrideByStaff"
                        checked={
                          this.accessSettings.globalLimitSettings
                            .allowDailyVisitLimitOverrideByStaff
                        }
                        onChange={
                          this.accessSettings
                            .changeAllowDailyVisitLimitOverrideByStaff
                        }
                      />
                      {t("allowDailyVisitLimitOverrideByStaffLongLabel")}
                    </Label>
                  </FormGroup>
                  <FormText color="muted">
                    {t("allowDailyVisitLimitOverrideByStaffHelp")}
                  </FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

const DailyVisitLimit = withTranslation("sitesettingsaccess")(
  DailyVisitLimitContent
);

export { DailyVisitLimit };
