import {
  AccountResponseV1,
  Arke,
  IArke,
  SiteResponseV1,
} from "@intreba/arke-api-client";
import {
  action,
  computed,
  IObservableArray,
  observable,
  runInAction,
} from "mobx";
import AuthState from "../services/authentication/AuthState";
import EnvironmentConfig from "../services/environment";
import { resolveAccessToken } from "./authentication/resolveAccessToken";
import Config from "./environment";
import { ItemContainer } from "../AppStateContext";

export default class AppState {
  @observable
  public sites: IObservableArray<SiteResponseV1> = observable([]);
  @observable
  public loadedSites: boolean = false;

  @observable
  public accounts: IObservableArray<AccountResponseV1> = observable([]);
  @observable
  public loadedAccounts: boolean = false;

  @observable
  public selectedSiteId: string | undefined = undefined;
  @observable
  public selectedAccountId: string | undefined = undefined;

  @observable
  public itemContainer: ItemContainer = {
    site: null,
    account: null,
    sites: this.sites,
    accounts: this.accounts,
  };

  public arke: IArke;

  public authState: AuthState;

  public constructor() {
    this.arke = new Arke(Config.arkeApi, () =>
      resolveAccessToken(this.authState)
    );
    this.authState = new AuthState(EnvironmentConfig.identity, this.arke);
  }

  @action
  public async loadSites() {
    try {
      const sites = await this.arke.sites.list();
      runInAction(() => {
        this.sites.clear();
        sites
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
          .forEach((s) => this.sites.push(s));
        this.loadedSites = true;
      });
    } catch (e) {
      runInAction(() => {
        this.loadingError = true;
      });
    }
  }

  @action
  public async loadAccounts() {
    try {
      const accounts = await this.arke.accounts.list();
      runInAction(() => {
        this.accounts.clear();
        accounts.forEach((a) => this.accounts.push(a));
        this.loadedAccounts = true;
      });
    } catch (e) {
      runInAction(() => {
        this.loadingError = true;
      });
    }
  }

  @action
  public async loadSite(siteId: string) {
    this.setSite(siteId);
  }
  @computed
  public get site(): SiteResponseV1 | null {
    const site = this.sites.filter((s) => s.siteId === this.selectedSiteId);
    return site.length > 0 ? site[0] : null;
  }
  @computed
  public get account(): AccountResponseV1 | null {
    const account = this.accounts.filter(
      (a) => a.accountId === this.selectedAccountId
    );
    return account.length > 0 ? account[0] : null;
  }
  @action
  public setSite(siteId: string) {
    console.debug("Setting site id ", siteId);
    this.selectedSiteId = siteId;
    this.itemContainer.site = this.site;
  }

  @action
  public setAccount(accountId: string) {
    console.debug("Setting account id ", accountId);
    this.selectedAccountId = accountId;
    this.itemContainer.account = this.account;
  }
  @observable
  public loadingError: boolean = false;

  @computed
  public get hasSplashscreenError(): boolean {
    return this.authState.error || this.loadingError;
  }
}
