import { IArke } from "@intreba/arke-api-client";
import { action, computed, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import i18n from "i18next";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import EnvironmentConfig from "../../services/environment";
import history from "../../services/history";
import { Spinner } from "../../components";

class SetupState {
  private arke: IArke;
  @observable
  public downloadUrl: string = "";
  private siteId: string;
  private t: i18n.TFunction;
  public constructor(arke: IArke, siteId: string, t: i18n.TFunction) {
    this.t = t;
    this.arke = arke;
    this.downloadUrl = `${
      EnvironmentConfig.arkeApi
    }/v1/clients/installers/net2/latest`;
    this.siteId = siteId;
  }

  @computed
  public get canConnect(): boolean {
    return (
      this.subscriptionId.length === 36 &&
      this.thumbprintCharacters.length === 4
    );
  }
  @observable
  public isConnecting: boolean = false;

  @computed
  public get buttonDisabled() {
    return this.disabled || !this.canConnect;
  }
  @computed
  public get disabled() {
    return this.isConnecting;
  }

  @observable
  public subscriptionId: string = "";

  @observable
  public thumbprintCharacters: string = "";

  @action
  public setSubscriptionId = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.subscriptionId = event.target.value;
  };

  @action
  public setThumbprintCharacters = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.thumbprintCharacters = event.target.value;
  };

  @action
  public onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.isConnecting = true;
    try {
      await this.arke.sites.updateClientSettings(this.siteId, {
        subscriptionId: this.subscriptionId,
        thumbprintCharacters: this.thumbprintCharacters
      });
      toast.success(this.t("siteSetupSuccess"));
      history.push(`/sites/${this.siteId}/dashboard`);
    } catch (e) {
      console.error(e);
      toast.error(this.t("siteSetupFail"));
      runInAction(() => {
        this.isConnecting = false;
      });
    }
  };
}
interface InjectedProps extends IProps {
  arke: IArke;
}

interface IProps
  extends WithTranslation,
    RouteComponentProps<{ siteId: string }> {}

@inject("arke")
@observer
class Setup extends React.Component<IProps, {}> {
  private setupState: SetupState;
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public constructor(props: any) {
    super(props);
    this.setupState = new SetupState(
      this.injectedprops.arke,
      this.props.match.params.siteId,
      this.injectedprops.t
    );
  }

  public render() {
    const t = this.props.t!;
    return (
      <>
        <CardHeader tag="h2">{t("title")}</CardHeader>
        <CardBody>
          <Col sm={12}>
            <h3>{t("step1")}</h3>
            <p>{t("intro")}</p>
          </Col>
          <Col>
            <Button
              outline={true}
              color="primary"
              disabled={this.setupState.disabled}
              href={this.setupState.downloadUrl}
              target="_blank"
            >
              {t("common:downloadButton")}
            </Button>
          </Col>
          <Col sm={12} style={{ marginTop: "1rem" }}>
            <h3>{t("step2")}</h3>
          </Col>
          <Form onSubmit={this.setupState.onSubmit}>
            <FormGroup>
              <Col sm={12}>
                <Label for="subscription">{t("subscriptionLabel")}</Label>
              </Col>

              <Col sm={3}>
                <Input
                  type="text"
                  name="subscription"
                  id="subscription"
                  value={this.setupState.subscriptionId}
                  onChange={this.setupState.setSubscriptionId}
                  disabled={this.setupState.disabled}
                  pattern="[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}"
                  title="A valid subscription id"
                  placeholder="00000000-0000-0000-0000-000000000000"
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={12}>
                <Label for="thumbprintCharacters">{t("thumbprintLabel")}</Label>
              </Col>
              <Col sm={3}>
                <Input
                  type="text"
                  disabled={this.setupState.disabled}
                  value={this.setupState.thumbprintCharacters}
                  onChange={this.setupState.setThumbprintCharacters}
                  name="thumbprintCharacters"
                  id="thumbprintCharacters"
                />
              </Col>
            </FormGroup>
            <Col sm={12}>
              <h3>{t("step3")}</h3>
            </Col>
            <Col sm={12}>
              <Button color="primary" disabled={this.setupState.buttonDisabled}>
                {t("connectButton")}
              </Button>
            </Col>
            {this.setupState.isConnecting && <Spinner />}
          </Form>
        </CardBody>
      </>
    );
  }
}

export default withTranslation("sitesetup")(Setup);
