import * as React from "react";
import { ModalHeader, ModalBody, ModalFooter, Button, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";

export function ConfirmModal(props: {
  toggle: () => void;
  open: boolean;
  working: boolean;
  text: string;
  onConfirm: () => Promise<void>;
}) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={props.open} toggle={props.toggle} backdrop={true}>
      <ModalHeader toggle={props.toggle}>{t("common:areYouSure")}</ModalHeader>
      <ModalBody>{props.text}</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={props.working}
          onClick={async () => {
            await props.onConfirm();
          }}
        >
          {t("common:yes")}
        </Button>{" "}
        <Button
          color="secondary"
          disabled={props.working}
          onClick={props.toggle}
        >
          {t("common:cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
