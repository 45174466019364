import { inject, observer } from "mobx-react";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { RouteComponentProps } from "react-router";
import { CardBody, CardHeader, Col, Row, Button, CardText } from "reactstrap";
import { UsersStore, userUrl } from "./UsersStore";
import PaginationView from "../../../../services/pagination/PaginationView";
import { ChevronRight } from "react-feather";
import { Ctd } from "../../../../components";
import { CannotEditWithPermissionWarning } from "../CannotEditWarning";
import { SitePermissions } from "@intreba/arke-api-client";

interface InjectedProps extends IProps {
  usersStore: UsersStore;
}

interface IProps extends WithTranslation {}

class UserRow extends React.Component<
  { email: string; onSelect: (email: string) => void },
  {}
> {
  private onClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    this.props.onSelect(this.props.email);
    // this.props.history.push(this.props.visitsStore.currentVisitUrl);
  };

  public render() {
    const userHref = userUrl(this.props.email);
    return (
      <tr onClick={this.onClick} style={{ cursor: "pointer" }}>
        <Ctd href={userHref}>{this.props.email}</Ctd>
        <Ctd href={userHref}>
          <ChevronRight />
        </Ctd>
      </tr>
    );
  }
}

@inject("usersStore")
@observer
class ViewUsersContent extends React.Component<
  InjectedProps & RouteComponentProps<{}>,
  {}
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  private onUserSelect = (email: string) => {
    this.props.usersStore.selectItem(email);
    this.props.history.push(this.props.usersStore.selectedUserUrl);
  };
  public renderItem = ({ item }: { item: string }) => {
    return <UserRow email={item} onSelect={this.onUserSelect} />;
  };
  public extractKey(item: string) {
    return item;
  }
  public componentDidMount() {
    this.props.usersStore.goToPage(1);
  }

  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardHeader tag="div">
          <Row>
            <Col md={6}>
              <h2>
                {t("title", {
                  userCount:
                    this.props.usersStore.totalItems !== undefined
                      ? this.props.usersStore.totalItems
                      : ""
                })}
              </h2>
            </Col>

            <Col md={{ offset: 4, size: 2 }}>
              <Button
                outline={true}
                color="secondary"
                block={true}
                onClick={() => this.props.history.push("users/add")}
              >
                {t("addUser")}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {this.props
            .t("usersText")
            .split("\n")
            .map((p: string, i: number) => (
              <CardText key={i}>{p}</CardText>
            ))}
          <Row>
            <Col>
              <CannotEditWithPermissionWarning
                editPermission={SitePermissions.siteusersettingscreate}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PaginationView
                renderItem={this.renderItem}
                keyExtractor={this.extractKey}
                store={this.props.usersStore}
                columns={[]}
              />
            </Col>
          </Row>
          {/* {this.props.visitsStore.isGettingItems && <Spinner />} */}
        </CardBody>
      </>
    );
  }
}

const ViewUsers = withTranslation("sitesettingsusers")(ViewUsersContent);

export { ViewUsers };
