import { configure } from "mobx";
import "moment/min/locales.min";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import history from "./services/history";
let moment = require("moment");

configure({
  computedRequiresReaction: true,
  enforceActions: "observed"
});

const culture =
  (window.navigator as any).userLanguage || window.navigator.language;

moment.locale(culture);
console.debug(
  "Setting locale to %s. Culture of browser: %s",
  moment.locale(),
  culture
);

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById("root") as HTMLElement
);
