import * as React from "react";
import { Alert } from "reactstrap";
import ShowWithoutPermission from "../../../components/user/ShowWithoutPermission";
import BaseSettingsState from "../BaseSettingsState";
import { SitePermissions } from "@intreba/arke-api-client";
import { useTranslation } from "react-i18next";
export const CannotEditWarning: React.FC<{
  state: BaseSettingsState;
}> = (props) => {
  return (
    <CannotEditWithPermissionWarning
      editPermission={props.state.editPermission}
    />
  );
};

export const CannotEditWithPermissionWarning = (props: {
  editPermission: SitePermissions;
}) => {
  const { t } = useTranslation("sitesettings");
  return (
    <ShowWithoutPermission permission={props.editPermission}>
      <Alert color="danger">{t("cannotEditAlert")}</Alert>
    </ShowWithoutPermission>
  );
};
