import i18n from "i18next";
import { inject, observer } from "mobx-react";
import * as React from "react";

import { IArke, SiteResponseV1 } from "@intreba/arke-api-client";
import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Spinner } from "../../components";
import PhoneNumberUtility from "../../components/phonenumber/PhoneNumberUtility";

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  t: i18n.TFunction;
}

class ManualHostState {
  private arke: IArke;
  private site: SiteResponseV1;
  private t: i18n.TFunction;
  private onClose: () => void;
  private phoneNumberUtility: PhoneNumberUtility;
  constructor(
    arke: IArke,
    site: SiteResponseV1,
    t: i18n.TFunction,
    onClose: () => void
  ) {
    this.arke = arke;
    this.t = t;
    this.site = site;
    this.onClose = onClose;
    this.phoneNumberUtility = new PhoneNumberUtility();
  }
  @computed
  public get canCancel(): boolean {
    return !this.isAdding;
  }
  @observable
  public name: string = "";
  @observable
  public phoneNumber: string = "";
  @observable
  public isAdding: boolean = false;

  @computed public get canAdd() {
    return !this.isAdding && this.isValidNumber;
  }
  @action
  public changePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.phoneNumber = event.target.value;
  };

  @computed public get isValidNumber() {
    return this.phoneNumberUtility.validateMobile(
      this.phoneNumber,
      this.site.countryCode
    );
  }
  @action
  public changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.name = event.target.value;
  };
  @action
  public add = async () => {
    try {
      this.isAdding = true;
      await this.arke.hosts.create(
        this.site.siteId,
        this.name,
        this.phoneNumber
      );
      toast.success(this.t("hostAddSuccess"));
      this.onClose();
    } catch {
      toast.error(this.t("hostAddFail"));
    } finally {
      runInAction(() => (this.isAdding = false));
    }
  };
}

@inject("itemContainer", "arke")
@observer
export default class AddManualHostModal extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public constructor(props: any) {
    super(props);
    this.addState = new ManualHostState(
      this.injectedprops.arke,
      this.injectedprops.itemContainer.site,
      this.injectedprops.t,
      this.props.toggle
    );
  }

  public onClosed = () => {
    this.addState = new ManualHostState(
      this.injectedprops.arke,
      this.injectedprops.itemContainer.site,
      this.injectedprops.t,
      this.props.toggle
    );
  };

  private toggle = () => {
    if (this.addState.canCancel) {
      this.props.toggle();
    }
  };

  private addState: ManualHostState;

  public render() {
    const t = this.injectedprops.t!;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.toggle}
        onClosed={this.onClosed}
      >
        <ModalHeader toggle={this.toggle}>{t("addHost")}</ModalHeader>
        <ModalBody>
          {t("addHostIntro")}
          <FormGroup row={true} style={{ marginTop: "1rem" }}>
            <Label sm={4}>{t("name")}</Label>
            <Col sm={8}>
              <Input
                type="text"
                name="name"
                id="name"
                maxLength={150}
                value={this.addState.name}
                onChange={this.addState.changeName}
                disabled={this.addState.isAdding}
              />
            </Col>
          </FormGroup>
          <FormGroup row={true}>
            <Label sm={4}>{t("common:phoneNumber")}</Label>
            <Col sm={8}>
              <Input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                maxLength={150}
                value={this.addState.phoneNumber}
                onChange={this.addState.changePhoneNumber}
                disabled={this.addState.isAdding}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        {this.addState.isAdding && <Spinner />}
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.addState.add}
            disabled={!this.addState.canAdd}
          >
            {t("common:add")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={this.toggle}
            disabled={!this.addState.canCancel}
          >
            {t("common:cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
