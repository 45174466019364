import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import {
  BroadcastResponseV1,
  SitePermissions,
  userHasSitePermission,
  SiteResponseV1,
} from "@intreba/arke-api-client";

import { Col, Row, Button } from "reactstrap";
import PaginationView from "../../services/pagination/PaginationView";
import { Spinner } from "../../components";
import { RouteComponentProps } from "react-router";
import BroadcastStore from "./BroadcastStore";
import ShowWithPermission from "../../components/user/ShowWithPermission";
import { MessageOverviewRow } from "./MessageOverviewRow";
import { ChatContent } from "./ChatComponents";
import { EditorState } from "draft-js";
import { EnhancedSearch } from "../../components/EnhancedSearch";

interface InjectedProps extends IProps {
  broadcastStore: BroadcastStore;
  itemContainer: { site: SiteResponseV1 };
}

interface IProps extends WithTranslation {}

@inject("broadcastStore", "itemContainer")
@observer
class BroadcastsOverview extends React.Component<
  InjectedProps & RouteComponentProps<{ siteId: string; broadcastId: string }>,
  {
    mainChatVisible: boolean;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  private permissions: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = {
      mainChatVisible: true,
    };

    this.permissions = userHasSitePermission(
      this.injectedprops.itemContainer.site.permissions,
      SitePermissions.broadcastcreate
    );

    if (!this.permissions) {
      window.location.replace(
        `${this.injectedprops.broadcastStore.getUrl()}/conversations/${
          this.props.match.params.broadcastId
        }`
      );
    }
  }

  public setMainChatVisibleFalse = () => {
    this.setState({ mainChatVisible: false });
  };

  private onConversationSelect = (broadcastId: string) => {
    this.setState({ mainChatVisible: true });
    this.injectedprops.broadcastStore.selectBroadcast(broadcastId);
  };

  private extractKey = (item: BroadcastResponseV1) => {
    return item.broadcastId;
  };
  private renderItem = ({ item }: { item: BroadcastResponseV1 }) => {
    return (
      <MessageOverviewRow
        item={this.injectedprops.broadcastStore.convertBroadcast(item)}
        url={this.props.broadcastStore.getUrl()}
        onSelect={this.onConversationSelect}
      />
    );
  };

  public componentDidMount() {
    this.injectedprops.broadcastStore.setBroadcastId(
      this.props.match.params.broadcastId
    );
    this.injectedprops.broadcastStore.goToPage(1);
  }

  public async replyToBroadcast() {
    await this.injectedprops.broadcastStore.startConversationFromBroadcast();
  }

  render() {
    const t = this.injectedprops.t!;
    return this.permissions ? (
      <>
        <div className="layout">
          <div className="sidebar" style={{ height: "90vh" }}>
            <div className="tab-content h-100" role="tablist">
              <div
                className="tab-pane fade h-100 show active"
                id="tab-content-dialogs"
                role="tabpanel"
              >
                <div className="d-flex flex-column h-100">
                  <div className="hide-scrollbar">
                    <div className="container-fluid py-6">
                      <Row
                        style={{
                          padding: "0.3rem",
                        }}
                      >
                        <Col
                          style={{
                            margin: "auto",
                          }}
                        >
                          <h2 className="font-bold mb-6"> {t("title")}</h2>
                        </Col>
                        <Col
                          style={{
                            margin: "auto",
                          }}
                        >
                          <ShowWithPermission
                            permission={SitePermissions.broadcastcreate}
                          >
                            <Button
                              outline={true}
                              color="secondary"
                              block={true}
                              onClick={() => {
                                this.props.history.replace(
                                  `${this.injectedprops.broadcastStore.getNewBroadcastUrl()}`
                                );
                              }}
                            >
                              {t("sendBroadcastMessage")}
                            </Button>
                          </ShowWithPermission>
                        </Col>
                      </Row>
                      <EnhancedSearch
                        type="text"
                        value={this.injectedprops.broadcastStore.search}
                        onChange={
                          this.injectedprops.broadcastStore.onSearchChange
                        }
                        placeholder={t("searchPlaceholder")}
                        disabled={
                          this.injectedprops.broadcastStore.isGettingItems ||
                          this.injectedprops.broadcastStore.isWorking
                        }
                        resetSearch={
                          this.injectedprops.broadcastStore.resetSearch
                        }
                        onSubmit={(page: number) =>
                          this.injectedprops.broadcastStore.goToPage(page)
                        }
                      />
                      <nav className="nav d-block list-discussions-js mb-n6">
                        <PaginationView
                          renderItem={this.renderItem}
                          keyExtractor={this.extractKey}
                          store={this.injectedprops.broadcastStore}
                          columns={[]}
                        />
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.injectedprops.broadcastStore.selectedBroadcast && (
            <ChatContent
              mainChatVisible={this.state.mainChatVisible}
              subject={
                this.injectedprops.broadcastStore.selectedBroadcast.subject
              }
              isStaff={this.permissions}
              status={
                this.injectedprops.broadcastStore.selectedBroadcast.status
              }
              closeChat={() => this.setMainChatVisibleFalse()}
              selectedConversation={
                this.injectedprops.broadcastStore.selectedBroadcast
              }
              pageNumber={1}
              isWorking={this.injectedprops.broadcastStore.isWorking}
              messageMarkdowncontent={
                this.injectedprops.broadcastStore.messageMarkdowncontent
              }
              setMessageMarkdowncontent={(state: EditorState) =>
                this.injectedprops.broadcastStore.setMessageMarkdowncontent(
                  state
                )
              }
              sendMessage={() => this.replyToBroadcast()}
              selectedConversationUrl={this.injectedprops.broadcastStore.getSelectedBroadcastUrl()}
              disabledReply={true}
            />
          )}
          <div style={{ position: "absolute", zIndex: 99 }}>
            {this.injectedprops.broadcastStore.isGettingItems && <Spinner />}
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  }
}

export default withTranslation("broadcasts")(BroadcastsOverview);
