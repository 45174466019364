import { IArke } from "@intreba/arke-api-client";
import i18n from "i18next";
import { observer } from "mobx-react";
import * as React from "react";
import { DateRangePicker } from "react-dates";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Spinner, UserEmail } from "../../components";
import { ExportVisitsState } from "./ExportVisitsState";
@observer
class ExportVisitsModal extends React.Component<
  {
    arke: IArke;
    siteId: string;
    toggle: () => void;
    isOpen: boolean;
    t: i18n.TFunction;
  },
  {}
> {
  private exportState: ExportVisitsState;
  constructor(props: any) {
    super(props);
    this.exportState = new ExportVisitsState(
      this.props.arke,
      this.props.siteId
    );
  }

  private onClosed = () => {
    this.exportState = new ExportVisitsState(
      this.props.arke,
      this.props.siteId
    );
  };

  private toggle = () => {
    if (this.exportState.canCancel) {
      this.props.toggle();
    }
  };

  private export = async () => {
    const result = await this.exportState.export();
    if (result !== null) {
      toast.success(this.props.t("exportSuccess"));
      this.props.toggle();
    } else {
      toast.error(this.props.t("exportFail"));
    }
  };
  public render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.toggle}
        onClosed={this.onClosed}
      >
        <ModalHeader toggle={this.toggle}>Visit export</ModalHeader>
        <ModalBody>
          <DateRangePicker
            startDate={this.exportState.startDate}
            startDateId="exportStartDate"
            endDate={this.exportState.endDate}
            endDateId="exportEndDate"
            onDatesChange={this.exportState.onDatesChange}
            focusedInput={this.exportState.focusedInput}
            onFocusChange={this.exportState.onDateFocusChange}
            isOutsideRange={this.exportState.isOutsideRange}
            disabled={this.exportState.isExporting}
          />
          <br />
          We'll send you an export of the visits to <UserEmail /> when we're
          done collecting the information.
        </ModalBody>
        {this.exportState.isExporting && <Spinner />}
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.export}
            disabled={!this.exportState.canExport}
          >
            Export visits
          </Button>{" "}
          <Button
            color="secondary"
            onClick={this.toggle}
            disabled={!this.exportState.canCancel}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export { ExportVisitsModal };
