import React from "react";
import { Row, Col, Nav, CardBody, Card } from "reactstrap";
import moment from "moment";
import { Lock, Info } from "react-feather";
import { Link } from "react-router-dom";
import { ConversationContent } from "./ConversationStore";

type MessageProps = {
  item: ConversationContent;
  url: string;
  onSelect: (conversationId: string) => void;
};

export const MessageOverviewRow = (props: MessageProps) => {
  const onSelectConversation = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    props.onSelect(props.item.conversationId);
  };

  return (
    <>
      <tr style={{ cursor: "pointer" }}>
        <td style={{ borderWidth: 0 }}>
          <Nav className="nav d-block list-discussions-js mb-n6">
            <Link
              className="text-reset nav-link p-0 mb-6"
              to="#"
              onClick={onSelectConversation}
            >
              <Card
                className="card card-active-listener"
                style={{ position: "relative" }}
              >
                <CardBody className="card-body" style={{ maxWidth: "40ch" }}>
                  <Row>
                    <Col className="d-flex align-items-center mb-1">
                      <h6
                        className="text-truncate mb-0 mr-auto"
                        style={{ fontWeight: "bold" }}
                      >
                        {props.item.subject}
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="small text-muted col"
                      style={{ textOverflow: "ellipsis" }}
                    >
                      {props.item.participants
                        ? Object.entries(props.item.participants).map(
                            (value, key) => {
                              if (key === 0) return `${value[1]}`;
                              return `, ${value[1]}`;
                            }
                          )
                        : "Management"}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="small text-muted col">
                      {moment.utc(props.item.updatedAt).local().format("LT l")}
                    </Col>
                  </Row>
                </CardBody>

                {props.item.status > -1 ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor:
                          props.item.status === 0
                            ? "rgb(109, 190, 70)"
                            : "#f0682c",
                        width: 2,
                        height: "100%",
                        right: 0,
                        top: 0,
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        minWidth: 2,
                        height: "100%",
                        right: 4,
                        top: 0,
                      }}
                    >
                      {props.item.status === 0 ? (
                        <></>
                      ) : (
                        <Lock size={15} color={"#6c757d"} />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor: "#3a97a6",
                        width: 2,
                        height: "100%",
                        right: 0,
                        top: 0,
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        minWidth: 2,
                        height: "100%",
                        right: 4,
                        top: 0,
                      }}
                    >
                      <Info size={15} color={"#6c757d"} />
                    </div>
                  </>
                )}
              </Card>
            </Link>
          </Nav>
        </td>
      </tr>
    </>
  );
};
