import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, CardBody, Form, FormGroup, Input, Label } from "reactstrap";

import { IArke } from "@intreba/arke-api-client";
import { action, computed, observable, runInAction } from "mobx";
import { Spinner } from "../../components";
interface InjectedProps extends IProps {
  arke: IArke;
}

interface IProps extends WithTranslation {}

class NewAccountState {
  private arke: IArke;
  constructor(arke: IArke) {
    this.arke = arke;
  }

  @observable
  public loading: boolean = false;

  @observable
  public done: boolean = false;
  @computed
  public get disabled() {
    return this.loading;
  }

  @observable public estimationType: string = "units";
  @observable
  public acceptsTermsAndConditions: boolean = false;

  @observable
  public companyName: string = "";
  @observable
  public estimatedNumberOfVisitors: string = "";
  @action
  public create = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.loading = true;
    await this.arke.accounts.create({
      companyName: this.companyName,
      estimation: `${this.estimatedNumberOfVisitors} estimated ${
        this.estimationType
      }`
    });
    runInAction(() => {
      this.done = true;
      this.loading = false;
    });
  };
  @action
  public changeAcceptsTermsAndConditions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.acceptsTermsAndConditions = !this.acceptsTermsAndConditions;
  };
  @computed
  public get canCreate() {
    return (
      this.companyName !== "" &&
      this.estimatedNumberOfVisitors !== "" &&
      this.acceptsTermsAndConditions
    );
  }

  @action
  public setType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.estimationType = event.target.value;
  };
  @action
  public onCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.companyName = event.target.value;
  };
  @action
  public onExpectedNumberOfVisitorsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.estimatedNumberOfVisitors = event.target.value;
  };
}

@inject("arke")
@observer
class NewAccount extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public constructor(props: any) {
    super(props);
    this.createState = new NewAccountState(this.injectedprops.arke);
  }
  private createState: NewAccountState;

  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardBody>
          {this.createState.done && (
            <div
              style={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: t("newAccountCreated") }}
            />
          )}
          {!this.createState.done && (
            <>
              <div dangerouslySetInnerHTML={{ __html: t("newAccountIntro") }} />
              <Form
                style={{ marginTop: "1rem" }}
                onSubmit={this.createState.create}
              >
                <FormGroup>
                  <Label for="companyName">{t("companyName")}</Label>
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    disabled={this.createState.disabled}
                    value={this.createState.companyName}
                    onChange={this.createState.onCompanyNameChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label style={{ marginRight: "5px" }}>
                    Expected number of
                  </Label>
                  <Input
                    type="select"
                    style={{
                      display: "inline-block",
                      width: "39%",
                      padding: "0px",
                      marginRight: "1%"
                    }}
                    onChange={this.createState.setType}
                    value={this.createState.estimationType}
                  >
                    <option value="units">{t("units")}</option>
                    <option value="residents">{t("residents")}</option>
                    <option value="visitors per month">
                      {t("visitorsPerMonth")}
                    </option>
                  </Input>
                  <Input
                    type="text"
                    name="expectedVisitors"
                    id="expectedVisitors"
                    disabled={this.createState.disabled}
                    value={this.createState.estimatedNumberOfVisitors}
                    onChange={this.createState.onExpectedNumberOfVisitorsChange}
                    style={{ display: "inline-block", width: "22%" }}
                  />
                </FormGroup>
                <FormGroup />
                <FormGroup check={true}>
                  <Label check={true} style={{ textAlign: "left" }}>
                    <Input
                      disabled={this.createState.disabled}
                      type="checkbox"
                      name="acceptTermsAndConditions"
                      id="acceptTermsAndConditions"
                      checked={this.createState.acceptsTermsAndConditions}
                      onChange={
                        this.createState.changeAcceptsTermsAndConditions
                      }
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("acceptTermsAndConditions")
                      }}
                    />
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Button
                    block={true}
                    color="primary"
                    disabled={
                      this.createState.disabled || !this.createState.canCreate
                    }
                  >
                    {t("createButton")}
                  </Button>
                  {this.createState.loading && <Spinner />}
                </FormGroup>
              </Form>
            </>
          )}
        </CardBody>
      </>
    );
  }
}

export default withTranslation("newAccount")(NewAccount);
