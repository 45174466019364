import * as React from "react";
import { ButtonProps, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface IClickAgainToConfirmButtonProps extends ButtonProps {}

const ClickAgainToConfirmButton = (props: IClickAgainToConfirmButtonProps) => {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);

  function click(event: React.MouseEvent<HTMLButtonElement>) {
    if (props.onClick === undefined) {
      return;
    }
    if (clicked === true) {
      props.onClick(event);
    } else {
      setClicked(true);
    }
  }

  return (
    <Button {...props} onClick={click}>
      {clicked === true ? t("common:clickAgainToConfirm") : props.children}
    </Button>
  );
};

export { ClickAgainToConfirmButton };
