import { WatchlistResponseV1, WatchlistType } from "@intreba/arke-api-client";
import i18n from "i18next";
import * as React from "react";
import { ChevronRight } from "react-feather";
import { Ctd, LocalisedPhoneNumber } from "../../components";
import { useTranslation } from "react-i18next";

let moment = require("moment");

export const WatchlistEntryRow = (props: {
  entry: WatchlistResponseV1;
  type: WatchlistType;
  t: i18n.TFunction;
  onSelect: (entry: WatchlistResponseV1) => void;
}) => {
  const onClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    props.onSelect(props.entry);
  };

  const { t } = useTranslation("watchlist");
  const visitHref = `visits/${props.entry.watchlistId}`;
  return (
    <tr style={{ cursor: "pointer" }} onClick={onClick}>
      <Ctd href={visitHref}>
        {WatchlistType[props.entry.type].toString() ===
        WatchlistType.PhoneNumber.toString() ? (
          <LocalisedPhoneNumber phoneNumber={props.entry.pattern} />
        ) : (
          props.entry.pattern
        )}
      </Ctd>
      <Ctd href={visitHref}>{t("common:" + props.type)}</Ctd>
      <Ctd style={{ maxWidth: 100 }} href={visitHref}>
        {props.entry.description}
      </Ctd>
      <Ctd href={visitHref}>
        {moment(props.entry.dateAdded).local().format("L")}
      </Ctd>
      <Ctd
        href={visitHref}
        style={{ verticalAlign: "middle", textAlign: "center" }}
      >
        <ChevronRight />
      </Ctd>
    </tr>
  );
};
