import {
  VisitResponseV1,
  FullVisitResponse,
  VisitStatus
} from "@intreba/arke-api-client";
import i18n from "i18next";
import * as React from "react";
import {
  ChevronRight,
  LogIn,
  LogOut,
  Mail,
  UserX,
  CheckCircle
} from "react-feather";
import { Send } from "react-feather";
import { XCircle } from "react-feather";
import { Ctd, ShowVisitor } from "../../components";
import { ClientName } from "../../services/ClientName";
import { Clock } from "react-feather";

let moment = require("moment");

export class VisitRow extends React.Component<
  {
    visit: VisitResponseV1 | FullVisitResponse;
    t: i18n.TFunction;
    onSelect: (visit: VisitResponseV1 | FullVisitResponse) => void;
    showStartTimeOnly: boolean;
  },
  {}
> {
  public async componentWillMount() {}

  private hostName: string | null = null;

  private onClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    this.props.onSelect(this.props.visit);
  };

  public render() {
    const visitHref = `visits/${this.props.visit.visitId}`;
    return (
      <tr
        style={{ cursor: "pointer" }}
        onClick={this.onClick}
        className={this.props.visit.status > 400 ? "text-danger" : ""}
      >
        {"hostName" in this.props.visit ? (
          <Ctd
            href={visitHref}
            style={{ minWidth: "100px", maxWidth: "100px" }}
          >
            {this.props.visit.hostName}
          </Ctd>
        ) : (
          ""
        )}
        <Ctd href={visitHref}>
          <ShowVisitor visitor={this.props.visit.mainVisitor} />
        </Ctd>
        <Ctd href={visitHref}>
          {this.props.t(`visitstatus:${this.props.visit.status}`)} <br />
          {this.renderIcon(this.props.visit.status)}
        </Ctd>
        <Ctd href={visitHref}>
          <ClientName clientId={this.props.visit.createdByClientId} />
        </Ctd>
        <Ctd href={visitHref}>
          {this.props.showStartTimeOnly &&
            moment(this.props.visit.startAt)
              .local()
              .format("LT")}
          {!this.props.showStartTimeOnly &&
            moment(this.props.visit.startAt)
              .local()
              .format("l LT")}
        </Ctd>
        <Ctd
          href={visitHref}
          style={{ verticalAlign: "middle", textAlign: "center" }}
        >
          <ChevronRight />
        </Ctd>
      </tr>
    );
  }

  private renderIcon(status: VisitStatus) {
    switch (status) {
      case VisitStatus.Arrived:
        return <LogIn width={18} height={18} />;
      case VisitStatus.Departed:
        return <LogOut width={18} height={18} />;
      case VisitStatus.Requested:
        return <Mail width={18} height={18} />;
      case VisitStatus.Invited:
        return <Send width={18} height={18} />;
      case VisitStatus.Scheduled:
        return <Clock width={18} height={18} />;
      case VisitStatus.Finished:
        return <CheckCircle width={18} height={18} />;
      case VisitStatus.Expired:
        return <UserX width={18} height={18} />;
      default:
        return <XCircle width={18} height={18} />;
    }
  }
}
