import {
  CultureResponseV1,
  IArke,
  SitePermissions,
  SiteResponseV1,
  TimeZoneResponseV1,
} from "@intreba/arke-api-client";
import i18n from "i18next";
import {
  action,
  computed,
  IObservableArray,
  observable,
  runInAction,
} from "mobx";
import { toast } from "react-toastify";
import BaseSettingsState from "../../BaseSettingsState";
import { IPhoneNumberInputState } from "../../../../components/phonenumber/PhoneNumberInput";
import {
  showErrorToast,
  showApiErrorToast,
  showSuccessToast,
} from "../../../../services/commonToasts";
export class GeneralSettingsState extends BaseSettingsState {
  public async save() {
    await this.arke.sites.updateSettings(this.siteId, {
      cultureId: this.cultureId,
      name: this.name,
      retentionDays: this.retentionDays,
      timeZoneId: this.timeZoneId,
      emergencyPhoneNumber: this.emergencyPhoneNumber,
    });
    if (this.uploadedRepresentativeLogoFile !== null) {
      console.debug("Representative changed, uploading");
      try {
        this.arke.sites.updateLogo(
          this.siteId,
          "representative-logo",
          this.uploadedRepresentativeLogoFile
        );
      } catch (e) {
        showApiErrorToast(e);
      }
    } else {
      console.debug("Logo stayed the same, not uploading");
    }
    if (this.uploadedSiteLogoFile !== null) {
      console.debug("Site logo changed, uploading");
      try {
        this.arke.sites.updateLogo(
          this.siteId,
          "site-logo",
          this.uploadedSiteLogoFile
        );
      } catch (e) {
        showApiErrorToast(e);
      }
    } else {
      console.debug("Logo stayed the same, not uploading");
    }
    runInAction(() => {
      this.site.name = this.name;
      this.site.cultureId = this.cultureId;
      this.site.timeZoneId = this.timeZoneId;
    });
  }
  @action
  public async retrieve(): Promise<void> {
    await this.retrieveItems();
  }
  @action
  public async retrieveItems() {
    const settings = await this.arke.sites.findSettings(this.siteId);
    if (settings !== null) {
      runInAction(() => {
        this.name = settings.name;
        this.cultureId = settings.cultureId;
        this.timeZoneId = settings.timeZoneId;
        this.countryCode = settings.countryCode;
        this.retentionDays = settings.retentionDays;
        this.emergencyPhoneNumber =
          settings.emergencyPhoneNumber === null
            ? ""
            : `+${settings.emergencyPhoneNumber}`;
        try {
          this.originalRepresentativeImageSource = this.arke.sites.getLogoSource(
            this.siteId,
            "representative-logo"
          );
        } catch (e) {
          console.debug(e);
        }
        try {
          this.originalSiteLogoimageSource = this.arke.sites.getLogoSource(
            this.siteId,
            "site-logo"
          );
        } catch (e) {
          console.debug(e);
        }
      });
    }

    if (this.cultures.length === 0) {
      const cultures = (await this.arke.system.listCultures()) as CultureResponseV1[];
      runInAction(() => {
        this.cultures.clear();
        cultures.forEach((c) => this.cultures.push(c));
      });
    }
    if (this.timeZones.length === 0) {
      const timezones = (await this.arke.system.listTimezones()) as TimeZoneResponseV1[];
      runInAction(() => {
        this.timeZones.clear();
        timezones.forEach((t) => this.timeZones.push(t));
      });
    }
  }

  private arke: IArke;
  private site: SiteResponseV1;
  public constructor(t: i18n.TFunction, arke: IArke, site: SiteResponseV1) {
    super(t, SitePermissions.sitesettingsupdate, site.permissions, site.siteId);
    this.arke = arke;
    this.site = site;
  }
  @observable
  public countryCode: string = "";
  @observable
  public retentionDays: number | null = null;
  @computed
  public get retentionDaysValue(): string {
    return this.retentionDays === null ? "" : this.retentionDays.toString(10);
  }

  @observable
  public name: string = "";
  @observable
  public cultureId: string = "";
  @observable
  public timeZoneId: string = "";
  @observable
  public emergencyPhoneNumber: string = "";
  @observable
  public emergencyPhoneNumberValid: boolean = false;
  @action
  public changeTimeZone = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.timeZoneId = event.target.value;
  };
  @action
  public changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.name = event.target.value;
  };
  @action
  public changeCultureId = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.cultureId = event.target.value;
  };
  @action
  public changeEmergencyPhoneNumber = (state: IPhoneNumberInputState) => {
    this.emergencyPhoneNumber = state.value;
    this.emergencyPhoneNumberValid = state.isValid;
  };
  @observable
  public originalRepresentativeImageSource: string | null = null;

  @observable
  public uploadedRepresentativeLogoSource: string | null = null;

  @observable
  public originalSiteLogoimageSource: string | null = null;

  @observable
  public uploadedSiteLogoSource: string | null = null;

  public uploadedRepresentativeLogoFile: File | null = null;
  public uploadedSiteLogoFile: File | null = null;

  @observable
  public showRepresentativeLogoDeletionModal = false;
  @observable
  public showSiteLogoDeletionModal = false;

  @computed
  public get representativeLogoSource(): string | null {
    if (this.uploadedRepresentativeLogoSource === null) {
      return this.originalRepresentativeImageSource;
    }
    return this.uploadedRepresentativeLogoSource;
  }

  @computed
  public get siteLogoSource(): string | null {
    if (this.uploadedSiteLogoSource === null) {
      return this.originalSiteLogoimageSource;
    }
    return this.uploadedSiteLogoSource;
  }

  @action toggleRepresentativeLogoDeletionModal = () => {
    this.showRepresentativeLogoDeletionModal = false;
  };

  @action toggleSiteLogoDeletionModal = () => {
    this.showRepresentativeLogoDeletionModal = false;
  };

  @action
  removeRepresentativeLogo = async () => {
    try {
      await this.arke.sites.deleteLogo(this.siteId, "representative-logo");
      showSuccessToast();
      runInAction(() => {
        this.originalRepresentativeImageSource = "";
        this.showRepresentativeLogoDeletionModal = false;
      });
    } catch (e) {
      console.error(e);
      showApiErrorToast(e);
    }
  };

  @action
  removeSiteLogo = async () => {
    try {
      await this.arke.sites.deleteLogo(this.siteId, "site-logo");
      showSuccessToast();
      runInAction(() => {
        this.originalSiteLogoimageSource = "";
        this.showSiteLogoDeletionModal = false;
      });
    } catch (e) {
      console.error(e);
      showApiErrorToast(e);
    }
  };

  @action
  public confirmAndDeleteSiteLogo = () => {
    if (this.uploadedSiteLogoSource === null) {
      this.showSiteLogoDeletionModal = true;
    } else {
      this.uploadedSiteLogoSource = null;
      this.uploadedSiteLogoFile = null;
    }
  };

  @action
  public confirmAndDeleteRepresentativeLogo = () => {
    if (this.uploadedRepresentativeLogoSource === null) {
      this.showRepresentativeLogoDeletionModal = true;
    } else {
      this.uploadedRepresentativeLogoSource = null;
      this.uploadedRepresentativeLogoFile = null;
    }
  };

  @action
  public changeSiteLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length !== 1) {
      return;
    }
    const file = event.target.files[0];

    const image = new Image();
    var reader = new FileReader();
    image.onload = () => {
      if (image.width !== 500 || image.height !== 250) {
        showErrorToast();
      } else {
        runInAction(() => {
          this.uploadedSiteLogoSource = reader.result as string;
          this.uploadedSiteLogoFile = file;
        });
      }
    };

    reader.onloadend = () => {
      image.src = reader.result as string;
    };
    reader.readAsDataURL(file);
  };

  @action
  public changeRepresentativeLogo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files === null || event.target.files.length !== 1) {
      return;
    }
    const file = event.target.files[0];

    const image = new Image();
    var reader = new FileReader();
    image.onload = () => {
      if (image.width !== 500 || image.height !== 250) {
        showErrorToast();
      } else {
        runInAction(() => {
          this.uploadedRepresentativeLogoSource = reader.result as string;
          this.uploadedRepresentativeLogoFile = file;
        });
      }
    };

    reader.onloadend = () => {
      image.src = reader.result as string;
    };
    reader.readAsDataURL(file);
  };
  @action
  public changeRetentionDays = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value === undefined ||
      event.target.value === "0" ||
      event.target.value === null ||
      event.target.value === ""
    ) {
      this.retentionDays = null;
    }
    this.retentionDays = parseInt(event.target.value, 10);
  };
  @observable
  public timeZones: IObservableArray<TimeZoneResponseV1> = observable([]);
  @observable
  public cultures: IObservableArray<CultureResponseV1> = observable([]);

  @action
  public startSync = async () => {
    this.loading = true;
    try {
      await this.arke.sites.sync(this.siteId);
      toast.success(this.t("syncSuccess"));
    } catch {
      toast.error(this.t("syncFailed"));
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
