export interface IEnvironmentConfig {
  identity: {
    authority: string;
    client_id: string;
    post_logout_redirect_uri: string;
    redirect_uri: string;
    response_type: string;
    scope: string;
    silent_redirect_uri: string;
  };
  arkeApi: string;
  manageProfileUrl: string;
}

const DevelopmentConfig = {
  arkeApi: "http://localhost:49315",
  manageProfileUrl: "http://localhost",
  identity: {
    authority: "http://localhost:80",
    client_id: "F88B7FA9-BC72-4481-837C-B5E9503B0D0F",
    post_logout_redirect_uri: "http://localhost:3000/logout",
    redirect_uri: "http://localhost:3000/login/callback",
    response_type: "id_token token",
    scope: "openid profile intreba.arke.api",
    silent_redirect_uri: "http://localhost:3000/silent_login/callback.html",
  },
};

const DevelopmentToStagingConfig = {
  arkeApi: "https://api.staging.arke.io",
  manageProfileUrl: "https://identity.staging.arke.io",
  identity: {
    authority: "https://identity.staging.arke.io",
    client_id: "F88B7FA9-BC72-4481-837C-B5E9503B0D0F",
    post_logout_redirect_uri: "http://localhost:3000/logout",
    redirect_uri: "http://localhost:3000/login/callback",
    response_type: "id_token token",
    scope: "openid profile intreba.arke.api",
    silent_redirect_uri: "http://localhost:3000/silent_login/callback.html",
  },
};
const DevelopmentAndApiToStagingIdentityConfig = {
  arkeApi: "http://localhost:49315",
  manageProfileUrl: "https://identity.staging.arke.io",
  identity: {
    authority: "https://identity.staging.arke.io",
    client_id: "F88B7FA9-BC72-4481-837C-B5E9503B0D0F",
    post_logout_redirect_uri: "http://localhost:3000/logout",
    redirect_uri: "http://localhost:3000/login/callback",
    response_type: "id_token token",
    scope: "openid profile intreba.arke.api",
    silent_redirect_uri: "http://localhost:3000/silent_login/callback.html",
  },
};

const StagingConfig = {
  arkeApi: "https://api.staging.arke.io",
  manageProfileUrl: "https://identity.staging.arke.io",
  identity: {
    authority: "https://identity.staging.arke.io",
    client_id: "F88B7FA9-BC72-4481-837C-B5E9503B0D0F",
    post_logout_redirect_uri: "https://app.staging.arke.io/logout",
    redirect_uri: "https://app.staging.arke.io/login/callback",
    response_type: "id_token token",
    scope: "openid profile intreba.arke.api",
    silent_redirect_uri:
      "https://app.staging.arke.io/silent_login/callback.html",
  },
};

const ProductionConfig = {
  arkeApi: "https://api.arke.io",
  manageProfileUrl: "https://identity.arke.io",
  identity: {
    authority: "https://identity.arke.io",
    client_id: "F88B7FA9-BC72-4481-837C-B5E9503B0D0F",
    post_logout_redirect_uri: "https://app.arke.io/logout",
    redirect_uri: "https://app.arke.io/login/callback",
    response_type: "id_token token",
    scope: "openid profile intreba.arke.api",
    silent_redirect_uri: "https://app.arke.io/silent_login/callback.html",
  },
};

let config = null;
switch (window.ArkeEnvironment) {
  case "Development":
    config = DevelopmentAndApiToStagingIdentityConfig;
    break;
  case "Staging":
    config = StagingConfig;
    break;
  case "Production":
    config = ProductionConfig;
    break;
}

export default config as IEnvironmentConfig;
