import {
  IArke,
  SiteNet2DepartmentResponseV1,
  SitePermissions,
  SiteResponseV1
} from "@intreba/arke-api-client";
import i18n from "i18next";
import { action, IObservableArray, observable, runInAction } from "mobx";
import BaseSettingsState from "../../BaseSettingsState";

enum HostDailyLimitInputState {
  "global",
  "custom",
  "disabled"
}

interface HostDeptVisitLimitStatus {
  hostDepartmentName: string;
  hostDailyLimitValue: number;
  hostDailyLimitState: HostDailyLimitInputState;
}

export class Net2HostProvisioningState extends BaseSettingsState {
  private arke: IArke;

  constructor(t: i18n.TFunction, arke: IArke, site: SiteResponseV1) {
    super(
      t,
      SitePermissions.sitehostprovisioningsettingsupdate,
      site.permissions,
      site.siteId
    );
    this.arke = arke;
  }
  public async save(): Promise<void> {
    const departmentMapped = this.departments.map(d => ({
      departmentId: d.departmentId,
      synchronised: d.synchronised
    }));

    await this.arke.sites.updateNet2HostProvisioningSettings(this.siteId, {
      automaticallySyncNewDepartments: this.automaticallySyncNewDepartments,
      telephoneField: this.telephoneField,
      departments: departmentMapped
    });
  }

  public async retrieve(): Promise<void> {
    const settings = await this.arke.sites.findNet2HostProvisioningSettings(
      this.siteId
    );
    if (settings !== null) {
      runInAction(() => {
        this.automaticallySyncNewDepartments =
          settings.automaticallySyncNewDepartments;
        this.telephoneField = settings.telephoneField;
        this.departments.clear();
        settings.departments.forEach(d => this.departments.push(d));
      });
    }
  }

  @observable
  public automaticallySyncNewDepartments: boolean = false;
  @observable
  public telephoneField: "telephone" | "fax" | "field8" = "telephone";
  public departments: IObservableArray<
    SiteNet2DepartmentResponseV1
  > = observable([]);
  @action
  public changeAutomaticallySyncNewDepartments = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.automaticallySyncNewDepartments = !this
      .automaticallySyncNewDepartments;
  };
  @action
  public changeSyncedDepartment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const department = this.departments.filter(
      d => d.departmentId.toString() === event.currentTarget.value
    )[0];
    department.synchronised = !department.synchronised;
  };
}
