import { observer, inject } from "mobx-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  CardBody,
  CardHeader,
  FormGroup,
  Label,
  Col,
  FormText,
  Button,
  Input,
  Row,
  Form,
} from "reactstrap";
import { SingleDatePicker } from "react-dates";
import {
  ValidatedPhoneNumberInput,
  PhoneValidation,
} from "../../components/phonenumber/PhoneNumberInput";
import { SiteResponseV1, IArke, ErrorResponse } from "@intreba/arke-api-client";
import { Spinner } from "../../components";
import moment, { Moment } from "moment";
import {
  showApiErrorToast,
  showSuccessToast,
} from "../../services/commonToasts";
import { toast } from "react-toastify";
import { Trash } from "react-feather";
import { AddVisitVisitor } from "@intreba/arke-api-client/dist/types/ResourceGroups/Visits/AddVisitRequest";

interface ICreateVisitProps {
  site: SiteResponseV1;
  arke: IArke;
}

interface IVisitorDetails {
  key: number;
  firstName: string;
  lastName: string;
  rawPhoneNumber: string;
  formattedPhoneNumber: string;
  isValidPhoneNumber: boolean;
}

interface ICreateVisitState {
  working: boolean;
  startDateSelectorFocus: boolean;
}

const VisitorEntryRow = (props: {
  visitor: IVisitorDetails;
  showLabels: boolean;
  disabled: boolean;
  defaultCountryCode: string;
  onValidEntry: () => void;
  onChange: (visitor: IVisitorDetails) => void;
  onRemove: (visitor: IVisitorDetails) => void;
}) => {
  const { t } = useTranslation("createVisit");
  return (
    <Row>
      <Col sm={4}>
        <FormGroup>
          {props.showLabels && <Label>{t("phoneNumber")}</Label>}
          <ValidatedPhoneNumberInput
            disabled={props.disabled}
            id={"visitorEntryPhoneNumber"}
            countryCode={props.defaultCountryCode}
            onChange={(state) => {
              props.visitor.rawPhoneNumber = state.value;
              props.visitor.isValidPhoneNumber = state.isValid;
              props.visitor.formattedPhoneNumber = state.formattedValue;
              props.onChange(props.visitor);
              if (state.isValid) {
                props.onValidEntry();
              }
            }}
            value={props.visitor.rawPhoneNumber}
            placeHolder={t("phoneNumber")}
            validationType={PhoneValidation.Mobile}
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          {props.showLabels && <Label>{t("firstName")}</Label>}
          <Input
            type="text"
            className="form-control"
            placeholder={t("firstNamePlaceholder")}
            maxLength={150}
            value={props.visitor.firstName}
            disabled={props.disabled}
            onChange={(v) => {
              props.visitor.firstName = v.target.value;
              props.onChange(props.visitor);
            }}
          />
        </FormGroup>
      </Col>
      <Col sm={3}>
        <FormGroup>
          {props.showLabels && <Label>{t("lastName")}</Label>}
          <Input
            type="text"
            className="form-control"
            placeholder={t("lastNamePlaceholder")}
            maxLength={150}
            value={props.visitor.lastName}
            disabled={props.disabled}
            onChange={(v) => {
              props.visitor.lastName = v.target.value;
              props.onChange(props.visitor);
            }}
          />
        </FormGroup>
      </Col>
      <Col sm={1}>
        <FormGroup>
          {props.showLabels && (
            <Label className="d-none d-sm-block" style={{ width: "100%" }}>
              &nbsp;
            </Label>
          )}
          {props.visitor.rawPhoneNumber !== "" && (
            <Trash onClick={() => props.onRemove(props.visitor)} />
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const createDefaultVisitorDetails = (nextKey: number): IVisitorDetails => {
  return {
    firstName: "",
    lastName: "",
    rawPhoneNumber: "",
    key: nextKey,
    isValidPhoneNumber: false,
    formattedPhoneNumber: "",
  };
};

const CreateVisitPageContent = (props: ICreateVisitProps) => {
  const now = moment();
  const { t } = useTranslation("createVisit");
  const [date, setDate] = React.useState<Moment | null>(null);
  const [time, setTime] = React.useState<string>("12:00");

  const [createState, setCreateState] = React.useState<ICreateVisitState>({
    working: false,
    startDateSelectorFocus: false,
  });

  const [visitorList, setVisitorList] = React.useState<IVisitorDetails[]>([
    createDefaultVisitorDetails(0),
  ]);

  function setDefaultState() {
    setCreateState((prevState) => {
      return {
        ...prevState,
        visitorFirstName: undefined,
        visitorLastName: undefined,
        rawPhoneNumber: "",
        isValidNumber: false,
        working: false,
        startDateSelectorFocus: false,
      };
    });
  }

  function normalizeTime() {
    const split = time.split(":");
    let minutes = parseInt(split[1], 10);
    minutes = Math.floor(minutes / 5) * 5;
    const newTime = `${split[0]}:${minutes.toString().padStart(2, "0")}`;
    return newTime;
  }

  async function createVisit(event: React.FormEvent) {
    event.preventDefault();
    setCreateState({ ...createState, working: true });
    let startDateTime: string | undefined = undefined;

    if (date !== null) {
      const time = normalizeTime();
      startDateTime = `${date.format("YYYY-MM-DD")} ${time}`;
    }

    try {
      const visitVisitors: AddVisitVisitor[] = visitorList
        .filter((v) => v.isValidPhoneNumber)
        .map((v) => {
          return {
            firstName: v.firstName,
            lastName: v.lastName,
            email: undefined,
            phoneNumber: v.formattedPhoneNumber,
          };
        });
      const result = await props.arke.visits.add(props.site.siteId, {
        visitors: visitVisitors,
        startDateTime: startDateTime,
      });
      const error = result as ErrorResponse;
      if (error.errorMessage) {
        toast.error(error.errorMessage);
      } else {
        showSuccessToast(t("visitCreatedSucessfully"));
        setVisitorList([createDefaultVisitorDetails(0)]);
        setDate(null);
      }
    } catch (e) {
      showApiErrorToast(e);
    } finally {
      setDefaultState();
    }
  }

  return (
    <>
      <CardHeader>
        <h2 style={{ marginTop: "15px" }}>{t("title")}</h2>

        <hr />
      </CardHeader>
      <CardBody>
        <Form inline={false}>
          <FormGroup>
            <Label for="visitwhen" className="font-weight-bold">
              {t("when")}
            </Label>
            <div className="input-group bg-transparent border-0">
              <SingleDatePicker
                id="visitWhen"
                focused={createState.startDateSelectorFocus}
                date={date}
                disabled={createState.working}
                placeholder="Now"
                isOutsideRange={(d) => d < now || d.diff(now, "days") > 350}
                onDateChange={setDate}
                numberOfMonths={1}
                orientation={"vertical"}
                withFullScreenPortal={true}
                onFocusChange={() =>
                  setCreateState({
                    ...createState,
                    startDateSelectorFocus: !createState.startDateSelectorFocus,
                  })
                }
              />
              {date !== null ? (
                <Input
                  type="time"
                  disabled={createState.working}
                  id="visitWhenTime"
                  min="00:00"
                  max="23:59"
                  step={60}
                  pattern="[0-9]{2}:[0-9]{2}"
                  style={{
                    display: "inline-block",
                    width: "40%",
                    marginLeft: ".5rem",
                    paddingTop: "2px",
                  }}
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              ) : null}
            </div>
            <FormText color="muted">{t("whenHelp")}</FormText>
          </FormGroup>
          <hr className="border-primary" />
          <Label className="font-weight-bold">Visitors</Label>

          {visitorList.map((visitor: IVisitorDetails, index: number) => {
            return (
              <>
                {index === 1 && (
                  <Label className="text-muted">{t("otherVisitors")}</Label>
                )}
                {index > 0 && (
                  <hr className="m-0 mb-1 border-dark d-block d-sm-none" />
                )}
                <VisitorEntryRow
                  key={visitor.key}
                  showLabels={index === 0}
                  visitor={visitor}
                  disabled={createState.working}
                  defaultCountryCode={props.site.countryCode}
                  onChange={(visitor: IVisitorDetails) => {
                    setVisitorList((oldList) => {
                      const newList = [...oldList];
                      var index = newList.indexOf(visitor);
                      newList[index] = visitor;
                      return newList;
                    });
                  }}
                  onRemove={(visitor: IVisitorDetails) => {
                    setVisitorList((oldList) => {
                      const nextKey =
                        oldList.reduce((previous, current) =>
                          previous.key > current.key ? previous : current
                        ).key + 1;
                      const newList = [...oldList];
                      const filteredNewList = newList.filter(
                        (v) => v.key !== visitor.key
                      );

                      if (filteredNewList.length === 0) {
                        filteredNewList[nextKey] = createDefaultVisitorDetails(
                          nextKey
                        );
                      }
                      return filteredNewList;
                    });
                  }}
                  onValidEntry={() => {
                    const nextKey =
                      visitorList.reduce((previous, current) =>
                        previous.key > current.key ? previous : current
                      ).key + 1;
                    if (
                      visitorList[visitorList.length - 1].rawPhoneNumber !== ""
                    ) {
                      setVisitorList((oldList) => {
                        const newList = [...oldList];
                        newList.push(createDefaultVisitorDetails(nextKey));
                        return newList;
                      });
                    }
                  }}
                />
              </>
            );
          })}

          <hr className="border-primary" />
          <FormGroup row={true}>
            <Col>
              <Button
                color="primary"
                style={{ marginRight: ".5rem" }}
                disabled={
                  visitorList.filter((v) => v.isValidPhoneNumber).length < 1 ||
                  visitorList.filter(
                    (v) => !v.isValidPhoneNumber && v.rawPhoneNumber !== ""
                  ).length > 0 ||
                  createState.working
                }
                onClick={createVisit}
              >
                {t("createVisit")}
              </Button>
              <Button
                color="secondary"
                disabled={createState.working}
                onClick={() => {
                  setDefaultState();
                  setDate(null);
                  setVisitorList([createDefaultVisitorDetails(0)]);
                }}
              >
                {t("common:cancel")}
              </Button>
              {createState.working ? <Spinner inline={true} /> : null}
            </Col>
          </FormGroup>
        </Form>
      </CardBody>
    </>
  );
};

interface InjectedProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

@inject("itemContainer", "arke")
@observer
class CreateVisitPage extends React.Component<InjectedProps, {}> {
  public render() {
    return (
      <CreateVisitPageContent
        site={this.props.itemContainer.site}
        arke={this.props.arke}
      />
    );
  }
}

export { CreateVisitPage };
