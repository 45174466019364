import * as React from "react";
import { User, ChevronDown, LogOut, Plus } from "react-feather";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Row,
  Nav,
} from "reactstrap";
import { UserEmail, Username } from "../../components";
import environment from "../../services/environment";
import AuthState from "../../services/authentication/AuthState";
import { useTranslation } from "react-i18next";
import ArkeLogoDark from "../../images/arke_logo_dark.png";
import AppState from "../../services/AppState";
import {
  AccountPermissions,
  userHasAccountPermission,
  SiteResponseV1,
} from "@intreba/arke-api-client";
import { SiteDropdownItem } from "../../services/sidemenu/SiteDropdownItem";
import { decideHomePage } from "../../services/routing/SiteHomePage";
import history from "../../services/history";
import { toast } from "react-toastify";
import { Fragment } from "react";

function filterSite(site: SiteResponseV1, searchText: string): boolean {
  if (searchText.length > 0) {
    const search = searchText.toUpperCase();
    if (
      site.name.toUpperCase().indexOf(search) > -1 ||
      site.siteId.toUpperCase().indexOf(search) > -1
    ) {
      return true;
    }
    return false;
  } else {
    return true;
  }
}

export function TopBar(props: { authState: AuthState; appState: AppState }) {
  const { t } = useTranslation("menu");

  const onSiteSelect = async (siteId: string) => {
    setSearchText("");
    await props.appState.loadSite(siteId);
    props.appState.setSite(siteId);
    window.localStorage.setItem("selectedSiteId", siteId);
    if (props.appState.site !== null) {
      const homePage = decideHomePage(props.appState.site.permissions, siteId);
      history.push(homePage);
    }
  };

  const addNewSite = () => {
    setSearchText("");
    if (props.appState.accounts.length === 1) {
      history.push(
        `/accounts/${props.appState.accounts[0].accountId}/sites/add`
      );
    } else {
      toast.error(t("tooManyAccounts"));
    }
  };
  const userCanCreateSite =
    props.appState.itemContainer.accounts
      .map((a) =>
        userHasAccountPermission(
          a.permissions,
          AccountPermissions.accountsitescreate
        )
      )
      .filter((a) => a === true).length > 0;
  const [searchText, setSearchText] = React.useState("");
  const showSearch = props.appState.itemContainer.sites.length >= 5;
  return (
    <div
      className="navbar navbar-expand-md navbar-light d-md-flex row"
      style={{
        backgroundColor: "white",
        borderWidth: "0px 0px 1px 0px",
        borderStyle: "solid",
        borderColor: "#e3ebf6",
      }}
    >
      <Col md={3} lg={2} xl={2} className="text-center">
        <img
          src={ArkeLogoDark}
          className="logo img-fluid"
          alt="Arke logo"
          style={{ maxHeight: "45px" }}
        />
      </Col>
      <Col md={9} lg={10} xl={10} className="justify-content-between">
        <Row className="justify-content-between">
          <Col>
            <Nav>
              {(userCanCreateSite || props.appState.sites.length > 1) && (
                <UncontrolledDropdown nav={true} inNavbar={true}>
                  <DropdownToggle nav={true}>
                    Sites <ChevronDown />
                  </DropdownToggle>
                  <DropdownMenu positionFixed={false} className="pt-0">
                    {showSearch && (
                      <input
                        type="text"
                        placeholder="Search a site"
                        className="form-control mb-1 px-4"
                        style={{
                          paddingTop: "1.4rem",
                          paddingBottom: "1.4rem",
                          borderRight: "none",
                          borderLeft: "none",
                          borderTop: "none",
                        }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    )}
                    {props.appState.sites
                      .filter((s) => filterSite(s, searchText))
                      .map((s, index) => (
                        <Fragment key={index}>
                          {index === 0 && !showSearch && (
                            <div style={{ marginTop: "0.5rem" }}></div>
                          )}
                          {index === 0 && showSearch && (
                            <div style={{ marginTop: "0.25rem" }}></div>
                          )}
                          <SiteDropdownItem
                            site={s}
                            selected={
                              props.appState.itemContainer.site !== null &&
                              s.siteId ===
                                props.appState.itemContainer.site.siteId
                            }
                            onSelect={onSiteSelect}
                            key={s.siteId}
                          />
                          <DropdownItem divider={true} />
                        </Fragment>
                      ))}
                    {userCanCreateSite && (
                      <>
                        <DropdownItem onClick={addNewSite}>
                          <Plus /> {t("addNewSite")}
                        </DropdownItem>
                      </>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
          </Col>
          <Col className="d-flex justify-content-end">
            <UncontrolledDropdown nav={false} inNavbar={true} direction="down">
              <DropdownToggle nav={true} className="text-primary">
                <User />
                <ChevronDown
                  style={{ verticalAlign: "middle", marginLeft: "5px" }}
                />
              </DropdownToggle>
              <DropdownMenu right={true}>
                <DropdownItem disabled={true}>
                  <Username />
                </DropdownItem>
                <DropdownItem disabled={true}>
                  <UserEmail />
                </DropdownItem>
                <DropdownItem divider={true} />
                <DropdownItem
                  onClick={() => {
                    window.open(environment.manageProfileUrl);
                  }}
                >
                  {t("manageAccount")}
                </DropdownItem>
                <DropdownItem onClick={() => props.authState.signout()}>
                  <LogOut /> {t("logOut")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
