import {
  IArke,
  WatchlistRequestV1,
  WatchlistResponseV1,
  WatchlistType,
  UpdateWatchlistRequestV1,
} from "@intreba/arke-api-client";
import { History } from "history";
import { action, computed, observable, runInAction } from "mobx";
import { PaginationStore } from "../../services/pagination/PaginationStore";
import { WatchlistVisitsStore } from "./WatchlistVisitsStore";
import PhoneNumberUtility from "../../components/phonenumber/PhoneNumberUtility";
import { ChangeEvent } from "react";

export default class WatchlistStore extends PaginationStore<
  WatchlistResponseV1
> {
  @action
  public async getEntryAndDetails(watchlistId: string) {
    this.isGettingItems = true;
    try {
      const watchlistDetils = await this.arke.visits.watchlistEntryDetails(
        this.siteId,
        watchlistId
      );
      this.visitPaginationStore = new WatchlistVisitsStore(
        this.arke,
        this.siteId,
        watchlistId
      );
      this.visitPaginationStore.goToPage(1);
      runInAction(() => {
        this.selectedEntryDetails = watchlistDetils;
      });
    } finally {
      runInAction(() => {
        this.isGettingItems = false;
      });
    }
  }

  @action
  public onNotifyStaffChange(value: boolean) {
    if (this.selectedEntryDetails) {
      this.selectedEntryDetails = {
        ...this.selectedEntryDetails,
        notifyStaff: value === true ? value : undefined,
      };
    }
  }

  @action
  public onBlockVisitsfChange(value: boolean) {
    if (this.selectedEntryDetails) {
      this.selectedEntryDetails = {
        ...this.selectedEntryDetails,
        blockVisits: value === true ? value : undefined,
      };
    }
  }

  @computed public get watchlistUrl(): string {
    return `/sites/${this.siteId}/watchlist`;
  }

  @computed
  public get currentEntryUrl(): string {
    if (this.selectedItem === null) {
      return "";
    }
    return `/sites/${this.siteId}/watchlist/${this.selectedItem.watchlistId}`;
  }

  @observable
  public isWorking: boolean = false;
  private arke: IArke;
  private siteId: string;
  private phoneNumberUtility = new PhoneNumberUtility();
  private countryCode?: string;
  public visitPaginationStore: WatchlistVisitsStore | null = null;
  @observable
  public dropdownTypeFilter: WatchlistType | "All" = "All";
  @observable
  public search: string = "";

  @observable
  public selectedEntryDetails: WatchlistResponseV1 | null = null;

  @action public selectEntry = async (entry: WatchlistResponseV1 | null) => {
    this.selectItem(entry);
    if (entry === null) {
      this.selectedEntryDetails = null;
    } else {
      await this.getEntryAndDetails(entry.watchlistId);
    }
  };

  @action
  public onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.search = event.target.value;
  };

  @action
  public async addNewWatchlistEntry(entry: WatchlistRequestV1) {
    this.isWorking = true;
    try {
      let request = { ...entry };
      if (request.type === WatchlistType.PhoneNumber) {
        if (!this.countryCode) return;
        let phoneNumber = this.phoneNumberUtility.format(
          request.pattern,
          this.countryCode
        );
        request.pattern = phoneNumber ? phoneNumber : "";
      }
      await this.arke.visits.addToWatchlist(this.siteId, request);
      this.goToPage(1);
    } catch (e) {
      console.debug(e);
    } finally {
      runInAction(() => {
        this.isWorking = false;
      });
    }
  }

  @action
  public async updateCurrentEntry() {
    if (!this.selectedEntryDetails) return;
    this.isWorking = true;
    let request: UpdateWatchlistRequestV1 = {
      pattern: this.selectedEntryDetails.pattern,
      type: this.selectedEntryDetails.type,
      description: this.selectedEntryDetails.description,
      notifyStaff: this.selectedEntryDetails.notifyStaff,
      blockVisits: this.selectedEntryDetails.blockVisits,
      watchlistId: this.selectedEntryDetails.watchlistId,
    };
    try {
      await this.arke.visits.updateWatchlist(this.siteId, request);
      this.goToPage(1);
    } catch (e) {
      console.debug(e);
    } finally {
      runInAction(() => {
        this.isWorking = false;
      });
    }
  }

  @action
  public async removeWatchlistEntry(watchlistId: string) {
    this.isWorking = true;
    try {
      await this.arke.visits.watchlistEntryDelete(this.siteId, watchlistId);
      this.goToPage(1);
    } catch (e) {
      console.debug(e);
    } finally {
      runInAction(() => {
        this.isWorking = false;
      });
    }
  }

  @action
  public setCountryCode(value: string) {
    this.countryCode = value;
  }

  @action
  public setDropdownTypeFilter(value?: WatchlistType) {
    if (value != null) {
      this.dropdownTypeFilter = value;
    } else {
      this.dropdownTypeFilter = "All";
    }
    this.goToPage(1);
  }

  public getDropdownTypeFilter() {
    if (WatchlistType[this.dropdownTypeFilter] !== undefined)
      return this.dropdownTypeFilter;
    return "All";
  }

  constructor(arke: IArke, siteId: string, history: History) {
    super();
    this.arke = arke;
    this.siteId = siteId;
  }

  public getItems(): Promise<{
    items: WatchlistResponseV1[];
    totalAmount: number;
  }> {
    return this.arke.visits.listWatchlist(
      this.siteId,
      this.pageToBeRetrieved,
      this.itemsPerPage,
      this.search,
      this.dropdownTypeFilter
    );
  }
}
