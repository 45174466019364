import { IArke, SiteResponseV1 } from "@intreba/arke-api-client";
import { inject, observer, Provider } from "mobx-react";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import ViewWatchlist from "./ViewWatchlist";
import WatchlistStore from "./WatchlistStore";
import { WatchlistEntryDetailPage } from "./WatchlistEntryDetails";
import { AddWatchlistEntryStore } from "./AddWatchlistEntryStore";

interface IProps {
  arke: IArke;
  itemContainer: { site: SiteResponseV1 };
}

@inject("arke", "itemContainer")
@observer
export default class WatchlistRouter extends React.Component<
  IProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  private watchlistStore: WatchlistStore;
  private addWatchlistEntryStore: AddWatchlistEntryStore;
  public constructor(props: any) {
    super(props);

    this.watchlistStore = new WatchlistStore(
      this.props.arke,
      this.props.match.params.siteId,
      this.props.history
    );

    this.addWatchlistEntryStore = new AddWatchlistEntryStore();
  }
  public render() {
    return (
      <Provider
        watchlistStore={this.watchlistStore}
        addWatchlistEntryStore={this.addWatchlistEntryStore}
      >
        <Switch>
          <Route
            path={`${this.props.match.path}`}
            exact={true}
            component={ViewWatchlist}
          />
          <Route
            path={`${this.props.match.path}/:watchlistId`}
            exact={true}
            component={WatchlistEntryDetailPage}
          />
        </Switch>
      </Provider>
    );
  }
}
