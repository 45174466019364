import { ScreeningAdditionalField } from "@intreba/arke-api-client";
import React from "react";
import { Input } from "reactstrap";

export const ScreeningOrderSelect = (props: {
  otherQuestions: ScreeningAdditionalField[];
  disabled: boolean;
  value: number;
  onChange: (newOrder: number) => void;
  nextOrder?: number;
}) => {
  return (
    <Input
      disabled={props.disabled}
      type="select"
      name="selectMulti"
      id="selectOrderForQuestion"
      value={props.value}
      onChange={(e) => {
        props.onChange(parseInt(e.target.value, 10));
      }}
    >
      {props.otherQuestions.map((s) => (
        <option key={s.additionalFieldId} value={s.order}>
          {s.order}
        </option>
      ))}
      <option value={props.nextOrder}>{props.nextOrder}</option>
    </Input>
  );
};
