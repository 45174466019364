import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  CardHeader,
  CardBody
} from "reactstrap";

import { IArke, SiteResponseV1 } from "@intreba/arke-api-client";
import { Spinner } from "../../../../components";
import { CannotEditWarning } from "../CannotEditWarning";
import { Net2HostProvisioningState as HostsSettingsState } from "./HostsSettingsState";
import { WithTranslation, withTranslation } from "react-i18next";

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke")
@observer
class HostsSettingsContent extends React.Component<IProps, {}> {
  private settingsState: HostsSettingsState;
  public constructor(props: any) {
    super(props);
    this.settingsState = new HostsSettingsState(
      this.injectedprops.t,
      this.injectedprops.arke,
      this.injectedprops.itemContainer.site
    );
  }
  public async componentDidMount() {
    await this.settingsState.load();
  }
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardHeader tag="div">
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={12}>
              <h2>{t("title")}</h2>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <Form onSubmit={this.settingsState.onSubmit}>
                <CannotEditWarning state={this.settingsState} />
                <FormGroup row={true}>
                  <Label for="phoneField" lg={2}>
                    {t("phoneFieldLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      disabled={true}
                      type="select"
                      name="phoneField"
                      id="phoneField"
                      value={this.settingsState.telephoneField}
                    >
                      <option value="telephone">
                        {t("phoneFieldTelephone")}
                      </option>
                      <option value="fax">{t("phoneFieldFax")}</option>
                      <option value="field8">{t("phoneFieldField8")}</option>
                    </Input>
                    <FormText color="muted">{t("phoneFieldHelp")}</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="autoSyncNewDepartments" lg={2}>
                    {t("autoSyncNewDepartmentsShortLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          disabled={this.settingsState.disabled}
                          type="checkbox"
                          name="autoSyncNewDepartments"
                          id="autoSyncNewDepartments"
                          checked={
                            this.settingsState.automaticallySyncNewDepartments
                          }
                          onChange={
                            this.settingsState
                              .changeAutomaticallySyncNewDepartments
                          }
                        />
                        {t("autoSyncNewDepartmentsLongLabel")}
                      </Label>
                      <FormText color="muted">
                        {t("autoSyncNewDepartmentsHelp")}
                      </FormText>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="syncedDepartments" lg={2}>
                    {t("syncedDepartmentsLabel")}
                  </Label>
                  <Col lg={10}>
                    {this.settingsState.departments.map(d => {
                      return (
                        <FormGroup check={true} key={d.departmentId}>
                          <Label check={true}>
                            <Input
                              disabled={this.settingsState.disabled}
                              type="checkbox"
                              name="syncedDepartments"
                              id="syncedDepartments"
                              value={d.departmentId}
                              checked={d.synchronised}
                              onChange={
                                this.settingsState.changeSyncedDepartment
                              }
                            />
                            {d.name}
                          </Label>
                        </FormGroup>
                      );
                    })}
                    <FormText color="muted">
                      {t("syncedDepartmentsHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  disabled={this.settingsState.disabled}
                >
                  Save
                </Button>
              </Form>
              {this.settingsState.loading && <Spinner />}
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

const HostsSettings = withTranslation("sitesettingshosts")(
  HostsSettingsContent
);

export { HostsSettings };
