import { createBrowserHistory } from "history";

declare var ga: any;

const history = createBrowserHistory();
history.listen(location => {
  window.scrollTo(0, 0);
  if (ga !== undefined && ga !== null) {
    console.debug("Tracking page view: + " + location.pathname);
    ga("send", "pageview", location.pathname);
  } else {
    console.error("GA not defined");
  }
});
export default history;
