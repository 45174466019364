import { SiteResponseV1, IArke } from "@intreba/arke-api-client";
import {
  ScreeningSettingsResponseV1,
  UpdateSiteScreeningSettingsRequestV1,
} from "@intreba/arke-api-client";

import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import {
  CardHeader,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { Spinner } from "../../../../components";
import { TimeSpanInput } from "../../../../components/timespan/TimeSpanInput";
import { ScreeningQuestions } from "./ScreeningQuestions";

enum FormState {
  Loading,
  Loaded,
  Error,
  Saving,
}

const ScreeningScreenDetails = (props: { arke: IArke; siteId: string }) => {
  const [
    screeningState,
    setScreeningState,
  ] = React.useState<ScreeningSettingsResponseV1 | null>(null);
  const [formState, setFormState] = React.useState<FormState>(
    FormState.Loading
  );
  useEffect(() => {
    const fetchData = async () => {
      const settings = await props.arke.sites.getScreeningSettings(
        props.siteId
      );
      settings.questions = settings.questions.sort((a, b) => a.order - b.order);
      setScreeningState(settings);
      setFormState(FormState.Loaded);
    };
    fetchData();
  }, []);

  const { t } = useTranslation("sitesettingsscreening");
  const formDisabled =
    formState === FormState.Saving || formState === FormState.Loading;
  return (
    <>
      <CardHeader tag="div">
        <Row style={{ marginBottom: "1rem" }}>
          <Col md={12}>
            <h2>{t("title")}</h2>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form
          disabled={formDisabled}
          onSubmit={async (e) => {
            e.preventDefault();
            if (screeningState === null) {
              return;
            }
            setFormState(FormState.Saving);
            const update = new UpdateSiteScreeningSettingsRequestV1();
            update.screeningBeforeVisitTime =
              screeningState.screeningBeforeVisitTime;
            update.screeningEnabled = screeningState.screeningEnabled;
            update.questions = screeningState.questions;
            await props.arke.sites.updateScreeningSettings(
              props.siteId,
              update
            );
            setFormState(FormState.Loaded);
          }}
        >
          {screeningState === null && <Spinner />}
          {screeningState !== null && (
            <>
              <FormGroup tyle={{ marginTop: "1rem" }} check={true}>
                <Label check={true}>
                  <Input
                    name="screeningEnabled"
                    type="checkbox"
                    disabled={formDisabled}
                    checked={screeningState.screeningEnabled}
                    onChange={(e) => {
                      setScreeningState({
                        ...screeningState,
                        screeningEnabled: e.target.checked,
                      });
                    }}
                  />
                  {t("screeningEnabled")}
                </Label>
              </FormGroup>
              <div style={{ marginTop: 25 }}>{t("chooseWhen")}</div>
              <TimeSpanInput
                disabled={formDisabled}
                value={screeningState.screeningBeforeVisitTime}
                onChange={(newTimeSpan) => {
                  setScreeningState({
                    ...screeningState,
                    screeningBeforeVisitTime: newTimeSpan.toString(),
                  });
                }}
                granularity={{
                  secondsDisabled: true,
                }}
              />
              <ScreeningQuestions
                disabled={formDisabled}
                additionalFields={screeningState.screeningAdditionalFields}
                selectedQuestions={screeningState.questions}
                onChange={(questions) => {
                  let sortedAndMappedQuestions = questions
                    .sort((qa, qb) => qa.order - qb.order)
                    .map((q, index) => {
                      return { ...q, order: index + 1 };
                    });
                  setScreeningState({
                    ...screeningState,
                    questions: sortedAndMappedQuestions,
                  });
                }}
              />
            </>
          )}
          <div style={{ marginTop: 100 }}>
            <Button type="submit" disabled={formDisabled} color="primary">
              {t("common:save")}
            </Button>
          </div>
          <div style={{ position: "absolute", width: "100%" }}>
            {formDisabled && <Spinner />}
          </div>
        </Form>
      </CardBody>
    </>
  );
};

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke")
@observer
export default class ScreeningScreen extends React.Component<
  InjectedProps & RouteComponentProps<{}>
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  render() {
    return (
      <>
        <ScreeningScreenDetails
          arke={this.injectedprops.arke}
          siteId={this.injectedprops.itemContainer.site.siteId}
        />
      </>
    );
  }
}
