import {
  IArke,
  ConversationResponseV1,
  ConversationMessageResponseV1,
  BroadcastResponseV1,
  ConversationStatus,
} from "@intreba/arke-api-client";
import { action, observable, runInAction, computed } from "mobx";
import { ChangeEvent } from "react";
import { PaginationStore } from "../../services/pagination/PaginationStore";
import {
  showApiErrorToast,
  showSuccessToast,
} from "../../services/commonToasts";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { draftjsToMd } from "draftjs-md-converter";
import { debounce } from "lodash";

type StorageInfo = {
  site: string;
  to: string;
};

type StoredEditorState = {
  subject: string;
  editorState: any;
};

export class MessageContent {
  public messageId!: string;
  public from!: string;
  public sentAt!: string;
  public markdownContent!: string;
  public onBehalfOf?: string;
}

export class ConversationContent {
  conversationId!: string;
  subject!: string;
  status!: number;
  sentAt!: string;
  updatedAt!: string;
  participants: { [key: string]: string } = {};
  messageList!: MessageContent[];
}

export default class ConversationgStore extends PaginationStore<
  ConversationResponseV1
> {
  private arke: IArke;
  private siteId: string;
  @observable
  public search: string = "";
  public url: string = "";
  public conversationId: string = "";
  @observable
  public selectedConversation: ConversationContent | null = null;
  @observable
  public statusFilter: ConversationStatus = ConversationStatus.Open;
  @observable
  public messageList: ConversationMessageResponseV1[] = [];
  @observable
  public conversationList: Map<string, ConversationContent> = new Map<
    string,
    ConversationContent
  >();
  @observable
  public isWorking: boolean = false;
  @observable
  public messageSubject: string = "";
  @observable
  public conversationMessagePageToBeRetrieved = 1;
  @observable
  public numberOfConversationMessages = 5;
  @observable
  public selectedIndex: number = 0;
  @observable
  public messageMarkdowncontent: EditorState = EditorState.createEmpty();
  constructor(arke: IArke, siteId: string) {
    super();
    this.arke = arke;
    this.siteId = siteId;
  }

  @computed
  public get canGoNext(): boolean {
    return this.items.length >= this.itemsPerPage;
  }

  @action
  public setStatusFilter(value: ConversationStatus) {
    this.statusFilter = value;
  }

  @action
  public onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.search = event.target.value;
  };

  @action
  public resetSearch = () => {
    this.search = "";
    this.goToPage(1);
  };

  @action
  public setUrl(url: string) {
    this.url = url;
  }

  @action
  public setMessageSubject(value: string) {
    this.messageSubject = value;
  }

  @action
  public clearSelectedConversation() {
    this.selectedConversation = null;
  }

  @action
  public getSelectedConversationUrl() {
    if (!this.selectedConversation) return "";
    let value = `${this.url}/conversations/${this.selectedConversation.conversationId}`;
    return value;
  }

  @action
  public getNewConversationUrl() {
    let value = `${this.url}/conversations/new`;
    return value;
  }

  @action
  public async createConversation(sendTo: string[]) {
    this.isWorking = true;
    try {
      const mdOutput = draftjsToMd(
        convertToRaw(this.messageMarkdowncontent.getCurrentContent())
      );
      await this.arke.conversation.createConversation(this.siteId, {
        subject: this.messageSubject,
        participants: sendTo,
        message: { markdownContent: mdOutput },
      });
      await showSuccessToast("Message sent sucessfully");
    } catch (e) {
      await showApiErrorToast(e);
    } finally {
      runInAction(() => {
        this.isWorking = false;
      });
    }
  }

  public async getHostDetails(hostId: string) {
    return await this.arke.hosts.find(this.siteId, hostId);
  }

  @action
  public setConversationId(conversationId: string) {
    if (conversationId === undefined) return;
    this.conversationId = conversationId;
  }

  @action
  public setMessageMarkdowncontent(editorState: EditorState, to?: string) {
    this.messageMarkdowncontent = editorState;
    if (to) {
      this.debounceSaveNewConversationState(to);
    } else {
      this.debounceSaveEditorState();
    }
  }

  @action
  public async selectConversation(conversationId: string) {
    this.conversationMessagePageToBeRetrieved = 1;
    let value = this.conversationList.get(conversationId);
    if (value) {
      this.setSelectedConversation(value);
    }
  }

  @action
  public async setSelectedConversation(conversation: ConversationContent) {
    this.conversationMessagePageToBeRetrieved = 1;
    this.selectedConversation = conversation;
    await this.getConversationMessages();
  }

  public getCurrentConversation() {
    return this.selectedConversation;
  }

  public getUrl() {
    return this.url;
  }

  @action
  public goToPage(page: number) {
    this.pageToBeRetrieved = page;
    this.getPage().then(() => {
      this.items.map((value) => {
        this.conversationList.set(value.conversationId, {
          conversationId: value.conversationId,
          subject: value.subject,
          sentAt: value.sentAt,
          updatedAt: value.updatedAt,
          status: value.status,
          participants: value.participants,
          messageList: [],
        });
        return null;
      });
      if (this.conversationId !== "") {
        runInAction(() => {
          this.getConversation(this.conversationId);
          this.conversationId = "";
        });
      }
    });
  }

  @action
  public async updateConversationStatus(status: ConversationStatus) {
    if (!this.selectedConversation) return;
    this.isWorking = true;
    try {
      await this.arke.conversation.updateConversationStatus(
        this.siteId,
        this.selectedConversation.conversationId,
        { status: status }
      );
      runInAction(() => {
        this.isWorking = false;
      });
    } catch (e) {
      runInAction(() => {
        this.isWorking = false;
      });
      await showApiErrorToast(e);
    } finally {
      runInAction(() => {
        this.isWorking = false;
        if (this.selectedConversation)
          this.selectedConversation.status === 0
            ? (this.selectedConversation.status = 1)
            : (this.selectedConversation.status = 0);
      });
    }
  }

  public async getConversation(conversationId: string) {
    this.isGettingItems = true;
    try {
      const conversation = await this.arke.conversation.getConversation(
        this.siteId,
        conversationId
      );
      this.setSelectedConversation({
        conversationId: conversation.conversationId,
        subject: conversation.subject,
        sentAt: conversation.sentAt,
        updatedAt: conversation.updatedAt,
        status: conversation.status,
        participants: conversation.participants,
        messageList: [],
      });
    } catch (e) {
      await showApiErrorToast(e);
    } finally {
      runInAction(() => {
        this.isGettingItems = false;
      });
    }
  }

  @action
  public async getConversationMessages() {
    if (!this.selectedConversation) return;
    this.isWorking = true;
    if (this.isBroadcast) {
      await this.getBroadcastDetails();
      return;
    }
    try {
      let messages = await this.arke.conversation.getConversationMessages(
        this.siteId,
        this.selectedConversation.conversationId,
        this.conversationMessagePageToBeRetrieved,
        this.numberOfConversationMessages
      );
      runInAction(() => {
        if (!this.selectedConversation) return;
        this.selectedConversation.messageList = messages.items.map(
          (value, index) => {
            return {
              messageId: value.messageId,
              markdownContent: value.markdownContent,
              sentAt: value.sentAt,
              from: value.from,
              onBehalfOf: value.onBehalfOf,
            };
          }
        );
        this.getStoredConversationState();
        this.isWorking = false;
      });
    } catch (e) {
      runInAction(() => {
        this.isWorking = false;
      });
      await showApiErrorToast(e);
    }
  }

  @action
  public async getBroadcastDetails() {
    if (!this.selectedConversation) return;
    this.isWorking = true;
    try {
      const broadcast = await this.arke.conversation.getBroadcastMessage(
        this.siteId,
        this.selectedConversation.conversationId
      );
      runInAction(() => {
        this.selectedConversation = this.convertBroadcast(broadcast);
      });
    } finally {
      runInAction(() => {
        this.getStoredConversationState();
        this.isWorking = false;
      });
    }
  }

  public convertBroadcast(broadcast: BroadcastResponseV1) {
    return {
      conversationId: broadcast.broadcastId,
      subject: broadcast.subject,
      sentAt: broadcast.sentAt,
      updatedAt: broadcast.sentAt,
      status: -1,
      participants: { Management: "Management" },
      messageList: [
        {
          messageId: broadcast.broadcastId,
          sentAt: broadcast.sentAt,
          markdownContent: broadcast.markdownContent,
          from: "Management",
          onBehalfOf: "Management",
        },
      ],
    };
  }

  private get isBroadcast() {
    return (
      this.selectedConversation != null &&
      this.selectedConversation.status === -1
    );
  }

  @action
  public async sendConversationMessage() {
    if (!this.selectedConversation) return;
    let newConversation = undefined;
    this.isWorking = true;
    if (this.isBroadcast) {
      let newConversation = await this.startConversationFromBroadcast();
      return newConversation;
    }
    try {
      const mdOutput = draftjsToMd(
        convertToRaw(this.messageMarkdowncontent.getCurrentContent())
      );
      await this.arke.conversation.sendConversationMessage(
        this.siteId,
        this.selectedConversation.conversationId,
        { markdownContent: mdOutput }
      );
    } catch (e) {
      console.error(e);
      await showApiErrorToast(e);
    } finally {
      runInAction(() => {
        this.pageToBeRetrieved = 1;
        this.messageMarkdowncontent = EditorState.createEmpty();
        this.messageSubject = "";
        this.isWorking = false;
        this.getConversationMessages();
        this.clearStoredConversationEditorState();
      });
    }
    return newConversation;
  }

  @action
  public async startConversationFromBroadcast() {
    this.isWorking = true;
    let newConversation = undefined;
    try {
      const mdOutput = draftjsToMd(
        convertToRaw(this.messageMarkdowncontent.getCurrentContent())
      );
      if (this.selectedConversation !== null) {
        newConversation = await this.arke.conversation.createBroadcastReply(
          this.siteId,
          this.selectedConversation.conversationId,
          { markdownContent: mdOutput }
        );
      }
    } catch (error) {
      await showApiErrorToast(error);
    } finally {
      runInAction(() => {
        this.clearStoredConversationEditorState();
        this.isWorking = false;
      });
      return newConversation;
    }
  }

  @action
  public async getNextMessages() {
    if (!this.selectedConversation) return;
    if (this.isBroadcast) {
      this.getBroadcastDetails();
      return;
    }
    this.isWorking = true;
    try {
      let messages = await this.arke.conversation.getConversationMessages(
        this.siteId,
        this.selectedConversation.conversationId,
        ++this.conversationMessagePageToBeRetrieved,
        this.numberOfConversationMessages
      );
      runInAction(() => {
        this.isWorking = false;
        if (!this.selectedConversation) return;
        let nextmessages = messages.items.map((value, index) => {
          return {
            messageId: value.messageId,
            markdownContent: value.markdownContent,
            sentAt: value.sentAt,
            from: value.from,
            onBehalfOf: value.onBehalfOf,
          };
        });
        this.selectedConversation.messageList = this.selectedConversation.messageList.concat(
          nextmessages
        );
      });
    } catch (e) {
      runInAction(() => {
        this.isWorking = false;
      });
    }
  }

  public getItems(): Promise<{
    items: ConversationResponseV1[];
    totalAmount: number;
  }> {
    let status =
      this.statusFilter === ConversationStatus.All
        ? undefined
        : this.statusFilter.toString();
    return this.arke.conversation.getConversations(
      this.siteId,
      this.pageToBeRetrieved,
      this.itemsPerPage,
      this.search === "" ? undefined : this.search,
      status
    );
  }

  public saveNewConversationState = async (to: string) => {
    if (this.siteId) {
      let editorContent = convertToRaw(
        this.messageMarkdowncontent.getCurrentContent()
      );
      let stateToStore: StoredEditorState = {
        subject: this.messageSubject,
        editorState: editorContent,
      };

      let userInfo: StorageInfo = {
        site: this.siteId,
        to: to,
      };

      localStorage.setItem(
        JSON.stringify(userInfo),
        JSON.stringify(stateToStore)
      );
    }
  };

  public saveEditorState = async () => {
    if (!this.selectedConversation) return;
    let editorContent = convertToRaw(
      this.messageMarkdowncontent.getCurrentContent()
    );
    let stateToStore: StoredEditorState = {
      subject: "",
      editorState: editorContent,
    };
    localStorage.setItem(
      this.selectedConversation.conversationId,
      JSON.stringify(stateToStore)
    );
  };

  @action
  public getStoredConversationEditorState = async () => {
    if (!this.selectedConversation) return;
    let storedEditorValue = await localStorage.getItem(
      this.selectedConversation.conversationId
    );
    runInAction(() => {
      if (storedEditorValue !== null) {
        let currentEditorState = JSON.parse(storedEditorValue);
        this.messageMarkdowncontent = EditorState.createWithContent(
          convertFromRaw(currentEditorState.editorState)
        );
      }
    });
  };

  @action
  public getNewConversationStoredEditorState = async (to: string) => {
    let userInfo: StorageInfo = {
      site: this.siteId,
      to: to,
    };

    let storedEditorValue = await localStorage.getItem(
      JSON.stringify(userInfo)
    );
    runInAction(() => {
      if (storedEditorValue !== null) {
        let currentEditorState = JSON.parse(storedEditorValue);
        this.messageSubject = currentEditorState.subject;
        this.messageMarkdowncontent = EditorState.createWithContent(
          convertFromRaw(currentEditorState.editorState)
        );
      }
    });
  };

  private getStoredConversationState() {
    if (!this.selectedConversation) return;
    let storedEditorValue = localStorage.getItem(
      this.selectedConversation.conversationId
    );
    runInAction(() => {
      if (storedEditorValue !== null) {
        let currentEditorState = JSON.parse(storedEditorValue);
        this.messageMarkdowncontent = EditorState.createWithContent(
          convertFromRaw(currentEditorState.editorState)
        );
      } else {
        this.messageSubject = "";
        this.messageMarkdowncontent = EditorState.createEmpty();
      }
    });
  }

  @action
  public clearStoredContent(to: string) {
    let userInfo: StorageInfo = {
      site: this.siteId,
      to: to,
    };
    localStorage.removeItem(JSON.stringify(userInfo));
    this.messageSubject = "";
    this.messageMarkdowncontent = EditorState.createEmpty();
  }

  @action
  private clearStoredConversationEditorState() {
    if (!this.selectedConversation) return;
    localStorage.removeItem(this.selectedConversation.conversationId);
    this.messageSubject = "";
    this.messageMarkdowncontent = EditorState.createEmpty();
  }

  private debounceSaveEditorState = debounce(this.saveEditorState, 250);
  private debounceSaveNewConversationState = debounce(
    this.saveNewConversationState,
    250
  );
}
