import { Provider, observer, inject } from "mobx-react";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { NewMessage } from "./NewMessage";
import ConversationsOverview from "./ConversationsOverview";
import ConversationgStore from "./ConversationStore";
import { IArke } from "@intreba/arke-api-client";
import { WithTranslation } from "react-i18next";
import BroadcastsOverview from "./BroadcastsOverview";
import BroadcastStore from "./BroadcastStore";
import NewConversation from "./NewConversation";

export enum MessageType {
  Broadcast = "broadcast",
  Personal = "personal",
}

interface IProps extends WithTranslation {
  arke: IArke;
}

@inject("arke")
@observer
export class MessagingRouter extends React.Component<
  IProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  private conversationStore: ConversationgStore;
  private broadcastStore: BroadcastStore;

  constructor(props: any) {
    super(props);
    this.conversationStore = new ConversationgStore(
      this.props.arke,
      this.props.match.params.siteId
    );

    this.broadcastStore = new BroadcastStore(
      this.props.arke,
      this.props.match.params.siteId
    );

    this.broadcastStore.setUrl(this.props.match.url);
    this.conversationStore.setUrl(this.props.match.url);
  }

  render() {
    return (
      <Provider
        conversationStore={this.conversationStore}
        broadcastStore={this.broadcastStore}
      >
        <Switch>
          <Route
            exact={true}
            path={`${this.props.match.path}/conversations`}
            component={ConversationsOverview}
          />
           <Route
            path={`${this.props.match.path}/conversations/new`}
            exact={true}
            component={NewConversation}
          />
          <Route
            path={`${this.props.match.path}/conversations/new/:hostId`}
            exact={true}
            component={NewConversation}
          />
          <Route
            exact={true}
            path={`${this.props.match.path}/conversations/:conversationId`}
            component={ConversationsOverview}
          />
          <Route
            path={`${this.props.match.path}/broadcasts`}
            exact={true}
            component={BroadcastsOverview}
          />
          <Route
            path={`${this.props.match.path}/broadcasts/new`}
            exact={true}
            component={NewMessage}
          />
          <Route
            path={`${this.props.match.path}/broadcasts/:broadcastId`}
            exact={true}
            component={BroadcastsOverview}
          />          
        </Switch>
      </Provider>
    );
  }
}
