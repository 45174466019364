import {
  VisitResponseV1,
  SiteResponseV1,
  AccountResponseV1,
  WatchlistResponseV1,
  WatchlistType,
} from "@intreba/arke-api-client";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { History } from "history";
import { ChevronLeft } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import {
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
} from "reactstrap";
import { Spinner, LocalisedPhoneNumber } from "../../components";
import PaginationView from "../../services/pagination/PaginationView";
import { VisitRow } from "../visits/VisitRow";
import WatchlistStore from "./WatchlistStore";
import { AddWatchlistEntryStore } from "./AddWatchlistEntryStore";

const EntryDetails = (props: {
  entry: WatchlistResponseV1;
  watchlistStore: WatchlistStore;
  addWatchlistEntryStore: AddWatchlistEntryStore;
  history: History;
  itemContainer: { site: SiteResponseV1 };
}) => {
  const { t } = useTranslation("watchlistEntryDetails");
  const isPhoneNumber = props.entry.type === WatchlistType.PhoneNumber;
  const isLicensePlate = props.entry.type === WatchlistType.LicensePlate;
  return (
    <>
      <Row>
        <Col sm={12}>
          <h5>
            {t("common:" + props.entry.type)} :{" "}
            {isPhoneNumber ? (
              <LocalisedPhoneNumber phoneNumber={props.entry.pattern} />
            ) : (
              props.entry.pattern
            )}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <p>{props.entry.description}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <small className="text-muted">{t("settings")}</small>
          <hr style={{ margin: 0 }} />
        </Col>
        <Col sm={12}>
          <Input
            style={{ position: "relative", margin: "auto" }}
            type="checkbox"
            checked={props.watchlistStore.selectedEntryDetails?.notifyStaff}
            onChange={(e) =>
              props.watchlistStore.onNotifyStaffChange(e.target.checked)
            }
          />
          <Label sm={4}>{t("watchlist:notifyStaff")}</Label>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Input
            style={{ position: "relative", margin: "auto" }}
            type="checkbox"
            checked={props.watchlistStore.selectedEntryDetails?.blockVisits}
            disabled={isLicensePlate}
            onChange={(e) =>
              props.watchlistStore.onBlockVisitsfChange(e.target.checked)
            }
          />
          <Label sm={4}>{t("watchlist:blockVisits")}</Label>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: 5, marginBottom: 5 }}>
          <Button
            color="primary"
            disabled={
              props.watchlistStore.isGettingItems === true ||
              props.watchlistStore.isWorking === true
            }
            onClick={() => props.watchlistStore.updateCurrentEntry()}
          >
            {t("common:save")}
          </Button>
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col sm={12}>
          <h4>{t("relatedEvents")}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          {props.watchlistStore.visitPaginationStore !== null && (
            <PaginationView
              renderItem={({ item }: { item: VisitResponseV1 }) => {
                return (
                  <VisitRow
                    visit={item}
                    t={t}
                    onSelect={(v) => {
                      props.history.push(
                        `/sites/${v.siteId}/visits/${v.visitId}`
                      );
                    }}
                    showStartTimeOnly={false}
                  />
                );
              }}
              keyExtractor={(visit: VisitResponseV1) => visit.visitId}
              store={props.watchlistStore.visitPaginationStore}
              columns={[
                t("common:host"),
                t("common:visitor"),
                t("common:status"),
                t("common:createdVia"),
                t("visits:startAt"),
                "",
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

interface InjectedProps extends IProps {
  watchlistStore: WatchlistStore;
  addWatchlistEntryStore: AddWatchlistEntryStore;
  itemContainer: { site: SiteResponseV1; accounts: AccountResponseV1[] };
}

interface IProps extends WithTranslation {}

@inject("watchlistStore", "addWatchlistEntryStore", "itemContainer")
@observer
class WatchlistEntryPageContent extends React.Component<
  InjectedProps & RouteComponentProps<{ watchlistId: string }>,
  {
    removeModalOpen: boolean;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  constructor(props: any) {
    super(props);
    this.state = {
      removeModalOpen: false,
    };
  }
  public async componentWillMount() {
    this.injectedprops.watchlistStore.signalIsGettingItems();
  }
  public async componentDidMount() {
    this.injectedprops.watchlistStore.getEntryAndDetails(
      this.props.match.params.watchlistId
    );
  }

  private goBack = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.props.history.push(this.injectedprops.watchlistStore.watchlistUrl);
  };

  private removeEntry = () => {
    this.injectedprops.watchlistStore.removeWatchlistEntry(
      this.props.match.params.watchlistId
    );
    this.props.history.push(this.injectedprops.watchlistStore.watchlistUrl);
  };

  public render() {
    const t = this.props.t;
    return (
      <>
        <Modal
          isOpen={this.state.removeModalOpen}
          toggle={() =>
            this.setState({ removeModalOpen: !this.state.removeModalOpen })
          }
          backdrop={true}
        >
          <ModalHeader
            toggle={() =>
              this.setState({ removeModalOpen: !this.state.removeModalOpen })
            }
          >
            {t("common:areYouSure")}
          </ModalHeader>
          <ModalBody>
            {this.injectedprops.watchlistStore.selectedEntryDetails
              ? t("removeConfirmation").replace(
                  "{#variable}",
                  `${t(
                    "common:" +
                      this.injectedprops.watchlistStore.selectedEntryDetails
                        .type
                  ).toLowerCase()}`
                )
              : ""}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.removeEntry()}>
              {t("common:yes")}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() =>
                this.setState({ removeModalOpen: !this.state.removeModalOpen })
              }
            >
              {t("common:cancel")}
            </Button>
          </ModalFooter>
        </Modal>
        <CardHeader>
          <a
            href={this.props.watchlistStore.currentEntryUrl}
            style={{ marginLeft: "-10px" }}
            onClick={this.goBack}
          >
            <ChevronLeft />
            {t("common:back")}
          </a>
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={4}>
              <h2>{t("title")}</h2>
            </Col>
            <Col sm={{ offset: 6, size: 2 }}>
              <Button
                color="danger"
                block={true}
                onClick={() =>
                  this.setState({
                    removeModalOpen: !this.state.removeModalOpen,
                  })
                }
              >
                {t("removeEntry")}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {this.injectedprops.watchlistStore.isGettingItems === true ||
            (this.injectedprops.watchlistStore.isWorking === true && (
              <Spinner />
            ))}
          {this.injectedprops.watchlistStore.isGettingItems === false &&
            this.injectedprops.watchlistStore.selectedEntryDetails === null && (
              <h2>{t("common:notfound")}</h2>
            )}
          {this.injectedprops.watchlistStore.isGettingItems === false &&
            this.injectedprops.watchlistStore.selectedEntryDetails !== null && (
              <EntryDetails
                entry={this.injectedprops.watchlistStore.selectedEntryDetails}
                itemContainer={this.injectedprops.itemContainer}
                watchlistStore={this.injectedprops.watchlistStore}
                addWatchlistEntryStore={
                  this.injectedprops.addWatchlistEntryStore
                }
                history={this.props.history}
              />
            )}
        </CardBody>
      </>
    );
  }
}

const WatchlistEntryDetailPage = withTranslation("watchlistEntryDetails")(
  WatchlistEntryPageContent
);

export { WatchlistEntryDetailPage };
