import { toast } from "react-toastify";
import i18n from "./localisation/i18n";
import ConfirmationWarning from "../components/ConfirmationWarning";
export async function showErrorToast() {
  const t = await i18n;
  toast.error(t("common:errorToast"));
}

export async function showApiErrorToast(e: any) {
  if (e.body !== undefined && e.body.errorMessage !== undefined) {
    toast.error(e.body.errorMessage);
  } else {
    const t = await i18n;
    toast.error(t("common:errorToast"));
  }
}

export async function showSuccessToast(translation?: string) {
  if (translation === undefined) {
    const t = await i18n;
    toast.success(t("common:successToast"));
  } else {
    toast.success(translation);
  }
}

export async function showConfirmationToast(props: () => void) {
  const t = await i18n;
  await toast.warn(
    ConfirmationWarning(props, () => {}, t("common:confirmationToast:body")),
    { autoClose: 10000 }
  );
}
