import React from "react";
import { SiteResponseV1, IArke } from "@intreba/arke-api-client";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, Switch, Route } from "react-router-dom";
import ScreeningScreen from "./ScreeningScreen";

interface IProps {
  arke: IArke;
  itemContainer: { site: SiteResponseV1 };
}

@inject("arke", "itemContainer")
@observer
export default class ScreeningRouter extends React.Component<
  IProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path={this.props.match.path}
          component={ScreeningScreen}
        />
      </Switch>
    );
  }
}
