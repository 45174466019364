import { inject, observer } from "mobx-react";
import * as React from "react";

import { Location } from "history";
import { useTranslation } from "react-i18next";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";

import { Card, CardImg } from "reactstrap";
import ArkeLogoDark from "../../images/arke_logo_dark.png";
import Loading from "./Loading";
import AppState from "../../services/AppState";
import AuthFlow from "../authentication/AuthFlow";
import { decideHomePage } from "../routing/SiteHomePage";

interface ISplashScreenProps extends RouteComponentProps<{}> {
  appState: AppState;
  location: Location;
}

const SplashScreenFlow = observer((props: ISplashScreenProps) => {
  const { t } = useTranslation("splashscreen");
  if (props.appState.hasSplashscreenError) {
    return <p className="text-danger">{t("error")}</p>;
  }
  if (!props.appState.authState.hasUser) {
    console.debug("No user, rendering authflow");
    return (
      <>
        <Loading />
        <AuthFlow authState={props.appState.authState} />
      </>
    );
  }
  if (!props.appState.loadedSites) {
    props.appState.loadSites();
    return <Loading />;
  }
  if (!props.appState.loadedAccounts) {
    props.appState.loadAccounts();
    return <Loading />;
  }
  if (
    props.appState.loadedAccounts &&
    props.appState.accounts.length === 0 &&
    props.appState.sites.length === 0
  ) {
    return <Redirect to="/accounts/choose" />;
  }

  if (props.appState.loadedAccounts && props.appState.accounts.length > 0) {
    const accounts = props.appState.accounts;
    const nonApprovedAccounts = accounts.filter((account) => !account.approved);
    if (nonApprovedAccounts.length === accounts.length) {
      return (
        <Redirect to={`/accounts/${nonApprovedAccounts[0].accountId}/status`} />
      );
    }
    if (props.appState.sites.length === 0) {
      return <Redirect to={`/accounts/${accounts[0].accountId}/sites/add`} />;
    }
  }
  if (props.appState.loadedSites && props.appState.sites.length > 0) {
    const selectedSiteId = window.localStorage.getItem("selectedSiteId");
    const lastLocation = window.localStorage.getItem("lastLocation");
    if (
      selectedSiteId !== null &&
      props.appState.sites.filter((s) => s.siteId === selectedSiteId).length > 0
    ) {
      console.debug("last location is");
      if (
        lastLocation !== null &&
        lastLocation !== "/" &&
        lastLocation !== "/logout"
      ) {
        console.debug("Redirecting to last location ", lastLocation);
        return <Redirect to={lastLocation} />;
      } else {
        const homeLocation = decideHomePage(
          props.appState.sites.filter((s) => s.siteId === selectedSiteId)[0]
            .permissions,
          selectedSiteId
        );
        console.debug("Redirecting to home location ", homeLocation);
        return <Redirect to={homeLocation} />;
      }
    } else {
      const selectedSiteId = props.appState.sites[0].siteId;
      window.localStorage.setItem("selectedSiteId", selectedSiteId);
      const homeLocation = decideHomePage(
        props.appState.sites.filter((s) => s.siteId === selectedSiteId)[0]
          .permissions,
        selectedSiteId
      );
      console.debug("Redirecting to home location ", homeLocation);
      return <Redirect to={homeLocation} />;
    }
  }

  return <Loading />;
});

const SplashScreenFlowWithRouter = withRouter(SplashScreenFlow);

export interface InjectedProps extends IProps {
  appState: AppState;
}

export interface IProps extends RouteComponentProps<{}> {}

@inject("appState")
@observer
export class Splashscreen extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public render() {
    if (
      this.injectedprops.appState.authState.hasUser &&
      (this.injectedprops.location.pathname.startsWith("/accounts") ||
        this.injectedprops.location.pathname.startsWith("/sites"))
    ) {
      return this.props.children;
    }
    return (
      <>
        <div className="wrapper-center">
          <Card body={true}>
            <CardImg top={true} src={ArkeLogoDark} alt="Arke logos" />
            <SplashScreenFlowWithRouter
              appState={this.injectedprops.appState}
            />
          </Card>
        </div>
      </>
    );
  }
}
export default withRouter(Splashscreen);
