import { useTranslation } from "react-i18next";
import React from "react";
import { Alert, Col, FormText, Row, Table } from "reactstrap";
import { SelectedScreeningItem } from "./SelectedScreeningItem";
import { AdditionalFieldResponseV1 } from "@intreba/arke-api-client/dist/types/Entities/AdditionalFields";
import { ScreeningAdditionalField } from "@intreba/arke-api-client/dist/types/Entities/Screening";
import { AddNewQuestionRow } from "./AddNewQuestionRow";
export const ScreeningQuestions = (props: {
  additionalFields: AdditionalFieldResponseV1[];
  selectedQuestions: ScreeningAdditionalField[];
  onChange: (questions: ScreeningAdditionalField[]) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation("sitesettingsscreening");

  const nextOrder =
    props.selectedQuestions
      .map((a) => a.order)
      .sort()
      .reverse()[0] + 1 || 1;

  return (
    <>
      {" "}
      <h3>{t("questions")}</h3>
      <FormText color={"muted"}>{t("questionsHelp")}</FormText>
      <Table responsive={true}>
        <thead>
          <tr>
            <th>{t("order")}</th>
            <th>{t("questionName")}</th>
            <th>{t("desiredAnswer")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.selectedQuestions.map((item) => (
            <SelectedScreeningItem
              disabled={props.disabled}
              key={item.additionalFieldId}
              screeningItem={item}
              selectedQuestions={props.selectedQuestions}
              additionalField={
                props.additionalFields.filter(
                  (a) => a.additionalFieldId === item.additionalFieldId
                )[0]
              }
              onRemove={(additionalfieldId) => {
                const questions = props.selectedQuestions.filter(
                  (f) => f.additionalFieldId !== additionalfieldId
                );
                props.onChange(questions);
              }}
              onChange={(sq) => {
                const questions = props.selectedQuestions.filter(
                  (f) => f.additionalFieldId !== sq.additionalFieldId
                );
                const questionsWhoseOrderIsToBeChanged = questions.filter(
                  (q) => q.order >= sq.order
                );
                if (questionsWhoseOrderIsToBeChanged.length > 0) {
                  //Replacement occuriing
                  questionsWhoseOrderIsToBeChanged.forEach(
                    (q) => (q.order = q.order + 1)
                  );
                }
                questions.push(sq);
                props.onChange(questions);
              }}
            />
          ))}
          {props.additionalFields.length > props.selectedQuestions.length && (
            <AddNewQuestionRow
              disabled={props.disabled}
              key={props.selectedQuestions.length}
              nextOrder={nextOrder}
              additionalFields={props.additionalFields}
              selectedQuestions={props.selectedQuestions}
              onAdd={(newQuestion) => {
                let questions = props.selectedQuestions;
                const questionsWhoseOrderIsToBeChanged = questions.filter(
                  (q) => q.order >= newQuestion.order
                );
                if (questionsWhoseOrderIsToBeChanged.length > 0) {
                  //Replacement occuriing
                  questionsWhoseOrderIsToBeChanged.forEach(
                    (q) => (q.order = q.order + 1)
                  );
                }
                questions.push(newQuestion);
                questions = questions.sort((qa, qb) => qa.order - qb.order);
                props.onChange(questions);
              }}
            />
          )}
        </tbody>
      </Table>
      {props.additionalFields.length === props.selectedQuestions.length && (
        <Row>
          <Col>
            <Alert color="warning">{t("noMoreAvailableFields")}</Alert>
          </Col>
        </Row>
      )}
      {/* //   {props.screeningItems.map((item, index) => {
        //     return (<SelectedScreeningItem key={item.additionalFieldId} screeningItem={item}></SelectedScreeningItem>);                 
        //   })} */}
    </>
  );
};
