import { PaginationStore } from "../../../../services/pagination/PaginationStore";
import {
  AdditionalFieldResponseV1,
  IArke,
  AdditionalFieldUserType,
  AdditionalFieldRequestV1,
} from "@intreba/arke-api-client";
import { observable, computed, action, runInAction } from "mobx";
import {
  showApiErrorToast,
  showSuccessToast,
} from "../../../../services/commonToasts";

export class AdditionalFieldsStore extends PaginationStore<
  AdditionalFieldResponseV1
> {
  private arke: IArke;
  private siteId: string;
  public betaVersion = true;

  @observable
  public isWorking: boolean = false;

  @observable
  public selectedUserType: AdditionalFieldUserType =
    AdditionalFieldUserType.Visitor;

  constructor(arke: IArke, siteId: string) {
    super();
    this.arke = arke;
    this.siteId = siteId;
  }

  @computed
  public get currentAdditionalFieldUrl(): string {
    if (this.selectedItem === null) {
      return "";
    }
    return `/sites/${this.siteId}/settings/additionalfields/${this.selectedItem.additionalFieldId}`;
  }

  @action
  public selectAdditionalField(additionalField: AdditionalFieldResponseV1) {
    this.selectItem(additionalField);
  }

  @action
  public async addAdditionalField(additionalField: AdditionalFieldRequestV1) {
    try {
      this.isWorking = true;
      await this.arke.sites.addAdditionalField(this.siteId, additionalField);
      await showSuccessToast("Additional field added sucessfully");
    } catch (e) {
      await showApiErrorToast(e);
      console.error(e);
    } finally {
      runInAction(() => {
        this.goToPage(1);
        this.isWorking = false;
      });
    }
  }

  @action
  public async updateAdditionalField(
    additionalField: AdditionalFieldRequestV1
  ) {
    if (!this.selectedItem) return;
    try {
      this.isWorking = true;
      await this.arke.sites.updateAdditionalField(this.siteId, {
        ...additionalField,
        additionalFieldId: this.selectedItem.additionalFieldId,
      });
      await showSuccessToast("Additional field updated sucessfully");
    } catch (e) {
      await showApiErrorToast(e);
      console.error(e);
    } finally {
      runInAction(() => {
        this.goToPage(1);
        this.isWorking = false;
      });
    }
  }

  @action
  public async deleteAdditionalField(additionalFieldId: string) {
    try {
      this.isWorking = true;
      await this.arke.sites.deleteAdditionalField(
        this.siteId,
        additionalFieldId
      );
      await showSuccessToast("Additional field deleted sucessfully");
    } catch (e) {
      await showApiErrorToast(e);
      console.error(e);
    } finally {
      runInAction(() => {
        this.goToPage(1);
        this.isWorking = false;
      });
    }
  }

  @action
  public selectAdditionalFieldUserType(userType: AdditionalFieldUserType) {
    this.selectedUserType = userType;
    this.goToPage(1);
  }

  @action
  public async onSumit(additionalField: AdditionalFieldRequestV1) {
    if (!this.selectedItem) {
      await this.addAdditionalField(additionalField);
    } else {
      await this.updateAdditionalField(additionalField);
    }
  }

  public getItems(): Promise<{
    items: AdditionalFieldResponseV1[];
    totalAmount: number;
  }> {
    return this.arke.sites.listAdditionalFields(
      this.siteId,
      this.selectedUserType,
      this.pageToBeRetrieved,
      this.itemsPerPage
    );
  }
}
