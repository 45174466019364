import React, { useState } from "react";
import { VisitLimitInputState } from "./AccessSettingsState";
import {
  Col,
  FormGroup,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Edit2 as Edit } from "react-feather";

const DailyLimitDropdown = (props: {
  groupId: string;
  value: number | null;
  disabled?: boolean;
  editable?: boolean;
  state: VisitLimitInputState;
  onDropdownChange: (groupId: string, value: number) => void;
  onInputChange: (groupId: string, value: number) => void;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputState, setInputState] = useState(
    props.state === VisitLimitInputState.custom ? false : true
  );
  const { t } = useTranslation("sitesettingsaccess");
  const unlimitedDailyLimitMessage = t("unlimitedDailyLimitMessage");

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const changeInputState = (key: number) => {
    if (key === VisitLimitInputState.custom) {
      setInputState(false);
    } else if (key === VisitLimitInputState.unlimited) {
      setInputState(true);
    } else {
      setInputState(true);
    }
    props.onDropdownChange(props.groupId, key);
  };

  const onInputChange = (input: React.ChangeEvent<HTMLInputElement>) => {
    let value: number = input.target.valueAsNumber;
    props.onInputChange(props.groupId, value);
  };

  return (
    <Row>
      <Col sm={6}>
        <FormGroup key={12} style={{ width: "100%" }}>
          {props.value !== null ? (
            <Input
              type="number"
              name="doorFilterOut"
              id="doorFilterOut"
              disabled={inputState}
              min={1}
              value={props.value}
              onChange={onInputChange}
            />
          ) : (
            <Input
              type="text"
              name="doorFilterOut"
              id="doorFilterOut"
              disabled={inputState}
              min={1}
              value={unlimitedDailyLimitMessage}
              onChange={onInputChange}
            />
          )}
        </FormGroup>
      </Col>
      <Col sm={4}>
        {props.editable && (
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret disabled={props.disabled}>
              <Edit height={18} width={18} />
            </DropdownToggle>
            <DropdownMenu>
              {Object.keys(VisitLimitInputState)
                .filter(
                  (k) => typeof VisitLimitInputState[k as any] === "number"
                )
                .map((value, key) => {
                  return (
                    <DropdownItem
                      key={key}
                      onClick={() => {
                        changeInputState(key);
                      }}
                    >
                      {value}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </Dropdown>
        )}
      </Col>
    </Row>
  );
};

export { DailyLimitDropdown };
