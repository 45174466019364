import { IArke, VisitResponseV1 } from "@intreba/arke-api-client";
import { computed } from "mobx";
import { PaginationStore } from "../../services/pagination/PaginationStore";

class HostVisitsStore extends PaginationStore<VisitResponseV1> {
  private arke: IArke;
  private siteId: string;
  private hostId: string;

  @computed
  public get currentVisitUrl(): string {
    if (this.selectedItem === null) {
      return "";
    }
    return `/sites/${this.siteId}/visits/${this.selectedItem.visitId}`;
  }
  constructor(arke: IArke, siteId: string, hostId: string) {
    super();
    this.arke = arke;
    this.hostId = hostId;
    this.siteId = siteId;
    this.itemsPerPage = 10;
  }

  public getItems(): Promise<{
    items: VisitResponseV1[];
    totalAmount: number;
  }> {
    return this.arke.visits.listForHost(
      this.siteId,
      this.hostId,
      this.pageToBeRetrieved,
      this.itemsPerPage
    );
  }
}

export { HostVisitsStore };
