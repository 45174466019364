import React, { useState } from "react";
import { AdditionalFieldsStore } from "./AdditionalFieldsStore";
import { useTranslation } from "react-i18next";
import {
  Col,
  Button,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AdditionalFieldType,
  AdditionalFieldRequestV1,
} from "@intreba/arke-api-client";

export const AdditionalFieldDetails = (props: {
  additionalFieldsStore: AdditionalFieldsStore;
  goback: () => void;
}) => {
  const { t } = useTranslation("sitesettingsAdditionalFieldsDetails");
  const [toggleSelectFieldType, setToggleFieldType] = useState(false);
  const [additionalFieldDetails, setAdditionalFieldDetails] = useState<
    AdditionalFieldRequestV1
  >(
    props.additionalFieldsStore.selectedItem
      ? {
          ...props.additionalFieldsStore.selectedItem,
        }
      : {
          name: "",
          description: "",
          userType: props.additionalFieldsStore.selectedUserType,
          fieldType: AdditionalFieldType.YesNo,
          required: props.additionalFieldsStore.betaVersion ? false : true,
          defaultValue: "",
          fieldOptions: [],
        }
  );

  const toggle = () => {
    if (!props.additionalFieldsStore.betaVersion)
      setToggleFieldType((prevState) => !prevState);
  };

  return (
    <>
      <CardBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            props.additionalFieldsStore.onSumit(additionalFieldDetails);
            props.goback();
          }}
        >
          <FormGroup row={true}>
            <Label for="name" md={10}>
              {t("name")}
            </Label>
            <Col md={10}>
              <Input
                type="text"
                name="name"
                id="name"
                required={true}
                maxLength={150}
                value={additionalFieldDetails.name}
                onChange={(e) => {
                  e.preventDefault();
                  setAdditionalFieldDetails({
                    ...additionalFieldDetails,
                    name: e.target.value,
                  });
                }}
                disabled={false}
              />
            </Col>
          </FormGroup>
          <FormGroup row={true}>
            <Label for="label" md={10}>
              {t("label")}
            </Label>
            <Col md={10}>
              <Input
                type="text"
                name="label"
                id="label"
                maxLength={255}
                required={true}
                value={additionalFieldDetails.description}
                onChange={(e) => {
                  e.preventDefault();
                  setAdditionalFieldDetails({
                    ...additionalFieldDetails,
                    description: e.target.value,
                  });
                }}
                disabled={false}
              />
            </Col>
          </FormGroup>
          <FormGroup row={true} style={{ marginTop: "1rem" }}>
            <Label sm={10}>{t("fieldType")}</Label>{" "}
            <Col sm={4}>
              <Dropdown isOpen={toggleSelectFieldType} toggle={toggle}>
                <DropdownToggle
                  caret
                  style={{ width: "100%" }}
                  disabled={props.additionalFieldsStore.betaVersion}
                >
                  {t(additionalFieldDetails.fieldType)}
                </DropdownToggle>
                <DropdownMenu style={{ width: "100%" }}>
                  {Object.keys(AdditionalFieldType)
                    .filter(
                      (k) => typeof AdditionalFieldType[k as any] !== "number"
                    )
                    .map((value, key) => {
                      return (
                        <DropdownItem
                          key={key}
                          style={{ width: "100%", textAlign: "center" }}
                          onClick={(e) => {
                            setAdditionalFieldDetails({
                              ...additionalFieldDetails,
                              fieldType: AdditionalFieldType[value],
                            });
                          }}
                        >
                          {t(AdditionalFieldType[value])}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </FormGroup>
          {!props.additionalFieldsStore.betaVersion && (
            <FormGroup row={true}>
              <Label for="defaultValue" md={10}>
                {t("defaultValue")}
              </Label>
              <Col md={10}>
                <Input
                  type="text"
                  name="defaultValue"
                  id="defaultValue"
                  maxLength={150}
                  value={additionalFieldDetails.defaultValue}
                  onChange={(e) => {
                    e.preventDefault();
                    setAdditionalFieldDetails({
                      ...additionalFieldDetails,
                      defaultValue: e.target.value,
                    });
                  }}
                  disabled={false}
                />
              </Col>
            </FormGroup>
          )}
          {!props.additionalFieldsStore.betaVersion && (
            <FormGroup row={true}>
              <Label for="required" md={10}>
                {t("required")}
              </Label>
              <Col md={10} style={{ marginLeft: 0 }}>
                <Input
                  style={{ position: "relative", margin: "auto" }}
                  type="checkbox"
                  name="required"
                  id="required"
                  checked={additionalFieldDetails.required}
                  onChange={(e) => {
                    setAdditionalFieldDetails({
                      ...additionalFieldDetails,
                      required: e.target.checked,
                    });
                  }}
                />
                <Label style={{ width: "80%" }} md={8}>
                  {t("requiredHelp")}
                </Label>
              </Col>
            </FormGroup>
          )}
          <Button
            disabled={props.additionalFieldsStore.isWorking}
            type="submit"
            color="primary"
          >
            {props.additionalFieldsStore.selectedItem
              ? t("common:save")
              : t("common:add")}
          </Button>{" "}
          <Button color="secondary" onClick={() => props.goback()}>
            {t("common:cancel")}
          </Button>
        </Form>
      </CardBody>
    </>
  );
};
