import {
  IArke,
  VisitDocumentResponseV1,
  VisitResponseV1,
  FullVisitResponse
} from "@intreba/arke-api-client";
import { History } from "history";
import { debounce } from "lodash";
import {
  action,
  computed,
  IObservableArray,
  observable,
  runInAction
} from "mobx";
import { Moment } from "moment";
import { PaginationStore } from "../../services/pagination/PaginationStore";
import { VisitDetailsResponseV1 } from "./VisitDetailsResponseV1";
let moment = require("moment");

export default class VisitsStore extends PaginationStore<VisitResponseV1> {
  @action
  public goToVisits(history: History): any {
    history.push(this.siteVisitsUrlTimed);
  }
  @action
  public async getVisitAndDetails(visitId: string) {
    this.isGettingItems = true;
    try {
      if (this.selectedItem !== null) {
        if (this.selectedItem.visitId !== visitId) {
          this.selectVisit(null);
          await this.getVisit(visitId);
        }
      } else {
        await this.getVisit(visitId);
      }
      if (this.selectedVisitDetails !== null) {
        if (this.selectedVisitDetails.visitId !== visitId) {
          this.setSelectedVisitDetails(null);
          await this.getSelectedVisitDetails();
        }
      } else {
        await this.getSelectedVisitDetails();
      }
    } finally {
      runInAction(() => {
        this.isGettingItems = false;
      });
    }
  }
  @action
  public setSelectedVisitDetails(details: VisitDetailsResponseV1 | null): void {
    this.selectedVisitDetails = details;
  }
  @observable
  public exportModalOpen: boolean = false;
  private arke: IArke;
  private siteId: string;

  @observable
  public currentDateSource: string;
  @computed public get currentDate(): Moment {
    return moment(this.currentDateSource);
  }
  @computed
  public get currentDateFormatted(): string {
    return this.currentDate.format("L");
  }
  @computed
  public get isToday(): boolean {
    return this.currentDate.isSame(moment(), "days");
  }
  @computed
  public get siteVisitsUrlTimed(): string {
    return `/sites/${this.siteId}/visits?date=${this.currentDate.format(
      "YYYY-MM-DD"
    )}`;
  }
  @computed
  private get siteVisitsUrl(): string {
    return `/sites/${this.siteId}/visits`;
  }
  @computed
  public get currentVisitUrl(): string {
    if (this.selectedItem === null) {
      return "";
    }
    return `/sites/${this.siteId}/visits/${this.selectedItem.visitId}`;
  }
  @computed
  public get currentVisitDocumentsUrl(): string {
    return `${this.currentVisitUrl}/documents`;
  }
  @action
  public toggleExportModal = () => {
    this.exportModalOpen = !this.exportModalOpen;
  };

  @observable
  public selectedVisitDetails: VisitDetailsResponseV1 | null = null;
  @observable
  public visitDocuments: IObservableArray<VisitDocumentResponseV1> = observable(
    []
  );
  @action public selectVisit = async (visit: VisitResponseV1 | null) => {
    this.selectItem(visit);
    if (visit === null) {
      this.selectedVisitDetails = null;
    } else if (
      this.selectedVisitDetails !== null &&
      this.selectedVisitDetails.visitId !== visit.visitId
    ) {
      this.selectedVisitDetails = null;
    }
  };

  @action
  private async getSelectedVisitDetails() {
    if (this.selectedItem === null) {
      return;
    }
    const response = await this.arke.visits.findDetails(
      this.selectedItem.siteId,
      this.selectedItem.visitId
    );
    runInAction(() => {
      this.selectedVisitDetails = response;
    });
  }
  @action
  private async getVisit(visitId: string) {
    const visit = await this.arke.visits.find(this.siteId, visitId);
    this.selectVisit(visit);
  }
  @action
  public async getVisitDocuments(visitId: string) {
    this.visitDocuments.clear();
    const documents = await this.arke.visitDocuments.list(this.siteId, visitId);
    this.setDocuments(documents);
  }

  @action
  private setDocuments(documents: VisitDocumentResponseV1[]) {
    documents.forEach(d => this.visitDocuments.push(d));
  }
  @action
  public setDate = (
    date: Moment | null,
    triggerDebouncedRetrieval: boolean
  ) => {
    if (date === null) {
      this.currentDateSource = moment().format();
    } else {
      this.currentDateSource = date.format();
    }
    this.items.clear();
    this.history.push(this.siteVisitsUrlTimed);
    if (triggerDebouncedRetrieval === true) {
      this.debounced();
    }
  };
  @action
  public nextDay = () => {
    this.setDate(this.currentDate.add(1, "day"), true);
  };
  @action
  public previousDay = () => {
    this.setDate(this.currentDate.subtract(1, "day"), true);
  };
  private history: History;
  private debounced = debounce(this.getPage, 250);
  private canViewAllVisits: boolean;
  constructor(
    arke: IArke,
    siteId: string,
    history: History,
    canViewAllVisits: boolean
  ) {
    super();
    this.arke = arke;
    this.history = history;
    this.siteId = siteId;
    this.canViewAllVisits = canViewAllVisits;
    this.currentDateSource = moment().format();
  }

  public getItems(): Promise<{
    items: FullVisitResponse[];
    totalAmount: number;
  }> {
    return this.arke.visits.fullVisitList(
      this.siteId,
      this.pageToBeRetrieved,
      this.itemsPerPage,
      this.canViewAllVisits ? "All" : "Me",
      this.currentDate.format("YYYY-MM-DD")
    );
  }
}
