import {
  IObservableArray,
  observable,
  runInAction,
  action,
  computed
} from "mobx";
import { IArke } from "@intreba/arke-api-client";
import { AddUserRole } from "./AddUserRole";
import { showApiErrorToast } from "../../../../services/commonToasts";
import history from "../../../../services/history";
class AddUserState {
  private arke: IArke;
  private siteId: string;
  // eslint-disable-next-line no-useless-escape
  private emailRegex: RegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  constructor(arke: IArke, siteId: string) {
    this.arke = arke;
    this.siteId = siteId;
  }

  public roles: IObservableArray<AddUserRole> = observable([]);
  public async getManageableRoles() {
    var result = await this.arke.users.listManageableRoles(this.siteId);
    runInAction(() => {
      this.roles.clear();
      result.forEach(r => this.roles.push(new AddUserRole(r)));
    });
  }
  @action
  public onAddUserSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.isWorking = true;
    try {
      await this.arke.users.add(this.siteId, {
        email: this.email,
        roles: this.roles.filter(r => r.checked === true).map(r => r.role)
      });
      history.push(`/sites/${this.siteId}/settings/users`);
    } catch (e) {
      console.error(e);
      showApiErrorToast(e);
      runInAction(() => {
        this.isWorking = false;
      });
    }
  };
  @computed public get hasSelectedRoles(): boolean {
    return this.roles.filter(r => r.checked === true).length > 0;
  }

  @observable
  public email: string = "";
  @action
  public changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.email = event.target.value;
  };

  @computed
  public get validEmail(): boolean {
    return this.emailRegex.test(this.email);
  }

  @computed
  public get addUserDisabled() {
    return this.isWorking || this.email === "" || !this.hasSelectedRoles;
  }

  @observable public isWorking: boolean = false;
}

export { AddUserState };
