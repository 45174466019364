import * as React from "react";
import { Input, InputGroup } from "reactstrap";
import { useState } from "react";
import PhoneNumberUtility from "./PhoneNumberUtility";

export enum PhoneValidation {
  Mobile,
  All,
}

interface IPhoneNumberInput {
  disabled: boolean;
  id: string;
  countryCode: string;
  onChange: (state: IPhoneNumberInputState) => void;
  value: string;
  validationType: PhoneValidation;
  placeHolder?: string;
}

export interface IPhoneNumberInputState {
  value: string;
  isValid: boolean;
  formattedValue: string;
}

const phoneNumberUtility = new PhoneNumberUtility();

export const ValidatedPhoneNumberInput = (props: IPhoneNumberInput) => {
  const [beenValidated, setBeenValidated] = useState(false);
  const [isValid, setIsValid] = useState(false);
  function changeNumber(event: React.ChangeEvent<HTMLInputElement>) {
    let isValid = false;
    if (props.validationType === PhoneValidation.All) {
      isValid = phoneNumberUtility.validatePhone(
        event.target.value,
        props.countryCode
      );
    } else {
      isValid = phoneNumberUtility.validateMobile(
        event.target.value,
        props.countryCode
      );
    }

    const formattedNumber = isValid
      ? phoneNumberUtility.format(event.target.value, props.countryCode) ?? ""
      : "";

    const newState = {
      isValid: isValid,
      value: event.target.value,
      formattedValue: formattedNumber,
    };
    setIsValid(newState.isValid);
    if (event.target.value.length > 9) {
      setBeenValidated(true);
    }
    props.onChange(newState);
  }
  if (props.value === "" && beenValidated) {
    setIsValid(false);
    setBeenValidated(false);
  }
  return (
    <>
      <InputGroup>
        <Input
          type="text"
          name="phoneNumber"
          placeholder={props.placeHolder}
          maxLength={150}
          value={props.value}
          disabled={props.disabled}
          onChange={changeNumber}
          valid={beenValidated && isValid}
          invalid={beenValidated && !isValid}
        />
      </InputGroup>
    </>
  );
};
