import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Tooltip,
  CardHeader,
  CardBody,
} from "reactstrap";

import { IArke, SiteResponseV1 } from "@intreba/arke-api-client";
import { Spinner } from "../../../../components";
import { CannotEditWarning } from "../CannotEditWarning";
import { TemplatesState } from "./TemplatesState";
import { WithTranslation, withTranslation } from "react-i18next";

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {}
@inject("itemContainer", "arke")
@observer
class MessagesSettingsContent extends React.Component<
  IProps,
  {
    invitationMessageTooltipOpen: boolean;
    welcomeMessageTooltipOpen: boolean;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  private settingsState: TemplatesState;
  public constructor(props: any) {
    super(props);
    this.settingsState = new TemplatesState(
      this.injectedprops.t,
      this.injectedprops.arke,
      this.injectedprops.itemContainer.site
    );
    this.state = {
      invitationMessageTooltipOpen: false,
      welcomeMessageTooltipOpen: false,
    };
  }
  public async componentDidMount() {
    await this.settingsState.load();
  }
  private invitationMessageTooltipOpen = () => {
    this.setState({ invitationMessageTooltipOpen: true });
  };
  private invitationMessageTooltipClose = () => {
    this.setState({ invitationMessageTooltipOpen: false });
  };
  private welcomeMessageTooltipOpen = () => {
    this.setState({ welcomeMessageTooltipOpen: true });
  };
  private welcomeMessageTooltipClose = () => {
    this.setState({ welcomeMessageTooltipOpen: false });
  };
  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardHeader tag="div">
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={12}>
              <h2>{t("title")}</h2>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <Form onSubmit={this.settingsState.onSubmit}>
                <CannotEditWarning state={this.settingsState} />
                <FormGroup row={true}>
                  <Label for="name" lg={2}>
                    {t("invitationMessageLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      disabled={this.settingsState.disabled}
                      type="textarea"
                      name="invitationMessage"
                      onMouseEnter={this.invitationMessageTooltipOpen}
                      onMouseLeave={this.invitationMessageTooltipClose}
                      onFocus={this.invitationMessageTooltipOpen}
                      onBlur={this.invitationMessageTooltipClose}
                      id="invitationMessage"
                      value={this.settingsState.invitationMessage}
                      onChange={this.settingsState.changeInvitationMessage}
                    />
                    <Tooltip
                      placement="right"
                      isOpen={this.state.invitationMessageTooltipOpen}
                      target="invitationMessage"
                    >
                      <h4>{t("variables")}</h4>
                      <p>{t("variablesIntro")}</p>
                      <dl>
                        <dt>{t("codeVariableKey")}</dt>
                        <dd>{t("codeVariableDefinition")}</dd>
                        <dt>{t("siteVariableKey")}</dt>
                        <dd>{t("siteVariableDefinition")}</dd>
                        <dt>{t("expirationVariableKey")}</dt>
                        <dd>{t("expirationVariableDefinition")}</dd>
                        <dt>{t("startVariableKey")}</dt>
                        <dd>{t("startVariableDefinition")}</dd>
                      </dl>
                    </Tooltip>
                    <FormText color="muted">
                      {t("invitationMessageHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="name" lg={2}>
                    {t("welcomeMessageLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      disabled={
                        this.settingsState.disabled ||
                        !this.settingsState.welcomeMessageEnabled
                      }
                      type="textarea"
                      name="welcomeMessage"
                      id="welcomeMessage"
                      rows="6"
                      maxLength={480}
                      value={this.settingsState.welcomeMessage}
                      onChange={this.settingsState.changeWelcomeMessage}
                      onMouseEnter={this.welcomeMessageTooltipOpen}
                      onMouseLeave={this.welcomeMessageTooltipClose}
                      onFocus={this.welcomeMessageTooltipOpen}
                      onBlur={this.welcomeMessageTooltipClose}
                    />
                    <Tooltip
                      placement="right"
                      isOpen={this.state.welcomeMessageTooltipOpen}
                      target="welcomeMessage"
                    >
                      <h4>{t("variables")}</h4>
                      <p>{t("variablesIntro")}</p>
                      <dl>
                        <dt>{t("siteVariableKey")}</dt>
                        <dd>{t("siteVariableDefinition")}</dd>
                        <dt>{t("weblinkVariableKey")}</dt>
                        <dd>{t("weblinkVariableDefinition")}</dd>
                      </dl>
                    </Tooltip>
                    <FormText color="muted">
                      {this.settingsState.welcomeMessageEnabled
                        ? t("welcomeMessageHelp")
                        : t("welcomeMessageDisabledHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                {this.settingsState.disabled ? (
                  <CannotEditWarning state={this.settingsState} />
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={this.settingsState.disabled}
                  >
                    Save
                  </Button>
                )}
              </Form>
              {this.settingsState.loading && <Spinner />}
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

const MessagesSettings = withTranslation("sitesettingsmessages")(
  MessagesSettingsContent
);

export { MessagesSettings };
