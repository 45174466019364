import * as React from "react";
import { Card, CardBody, CardSubtitle } from "reactstrap";
import { Spinner } from "..";

export default class Widget extends React.Component<
  {
    title: string;
    count: number;
    color: string;
    loading: boolean;
  },
  {}
> {
  public render() {
    return (
      <Card>
        <CardBody>
          <div
            style={{ float: "left", color: this.props.color, height: "100%" }}
          >
            {this.props.children}
          </div>
          <CardSubtitle className="text-center">
            <small className="text-muted">{this.props.title}</small>
          </CardSubtitle>
          {this.props.loading && <Spinner />}
          {!this.props.loading && (
            <h3 className="text-center">{this.props.count}</h3>
          )}
        </CardBody>
      </Card>
    );
  }
}
