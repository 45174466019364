import { action, computed, IObservableArray, observable } from "mobx";
import { IPaginationStore } from "./IPaginationStore";
// TODO extract
abstract class PaginationItem {
  public key: string;

  constructor(key: string) {
    this.key = key;
  }
}

abstract class PaginationStore<T> implements IPaginationStore {
  public items: IObservableArray<T> = observable([]);
  @observable public currentPage: number = 1;
  @observable public selectedItem: T | null = null;
  @computed
  public get nextPage(): number {
    return this.currentPage + 1;
  }
  @computed
  public get previousPage(): number {
    return this.currentPage - 1;
  }
  @observable public itemsPerPage: number = 20;
  @observable public totalItems: number = 0;
  @observable public pageToBeRetrieved: number = 1;
  @observable public isGettingItems: boolean = false;
  @action public signalIsGettingItems() {
    this.isGettingItems = true;
  }
  @computed
  public get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }
  public async ensureItems() {
    while (this.isGettingItems) {
      // tslint:disable-next-line:no-string-based-set-timeout
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
  }

  @computed
  public get currentPageStartNumber(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  @computed
  public get currentPageEndNumber(): number {
    if (this.currentPage === this.totalPages && this.items.length > 0) {
      return this.currentPageStartNumber + this.items.length - 1;
    }
    return this.currentPage * this.itemsPerPage;
  }

  @computed
  public get canGoBack(): boolean {
    return this.currentPage > 1;
  }
  @computed
  public get canGoNext(): boolean {
    return this.items.length === this.itemsPerPage;
  }

  @action
  public clearPagination() {
    this.currentPage = 1;
    this.totalItems = this.itemsPerPage;
    this.pageToBeRetrieved = 1;
    this.items.clear();
  }

  @action
  public async getPage() {
    this.isGettingItems = true;
    this.selectedItem = null;
    const items = await this.getItems();
    this.processReceivedItems(items.items, items.totalAmount);
  }

  @action
  public goToPage(page: number) {
    this.pageToBeRetrieved = page;
    this.getPage();
  }

  @action
  private processReceivedItems(newItems: T[], totalItems: number) {
    this.isGettingItems = false;
    this.totalItems = totalItems;
    this.currentPage = this.pageToBeRetrieved;
    this.items.clear();
    newItems.forEach((item) => {
      this.items.push(item);
    });
  }

  @action
  public getNextPage() {
    this.pageToBeRetrieved = this.nextPage;
    this.goToPage(this.nextPage);
  }

  @action
  public getPreviousPage() {
    this.pageToBeRetrieved = this.previousPage;
    this.goToPage(this.previousPage);
  }

  @action
  public actionFailed() {
    this.isGettingItems = false;
  }

  @action
  public selectItem(item: T | null) {
    this.selectedItem = item;
  }
  public abstract getItems(): Promise<{ items: T[]; totalAmount: number }>;
}

class FilterData {
  public key!: string;
  public data: any;
}

interface IFilter {
  key: string;
  filter(item: any, data: any): boolean;
}

abstract class FilterablePaginationStore<T> extends PaginationStore<T> {
  public filterData: IObservableArray<FilterData> = observable([]);
  @action
  public setFilter(filter: FilterData) {
    const currentFilter = this.filterData.filter((x) => x.key === filter.key);
    if (currentFilter.length === 1) {
      currentFilter[0].data = filter.data;
    } else {
      this.filterData.push(filter);
    }
    this.goToPage(1);
  }
  @action
  public clearFilter() {
    this.filterData.clear();
  }
}

export { PaginationItem, FilterablePaginationStore, PaginationStore };
