import { observable, action } from "mobx";
import { UserRoles, ManageableRoleResponseV1 } from "@intreba/arke-api-client";

class AddUserRole implements ManageableRoleResponseV1 {
  @observable
  public role: UserRoles;
  @observable
  public manageable: boolean;
  @observable
  public checked: boolean = false;
  constructor(manageableRole: ManageableRoleResponseV1) {
    this.role = manageableRole.role;
    this.manageable = manageableRole.manageable;
  }
  @action
  public toggleRole = () => {
    this.checked = !this.checked;
  };
}

export { AddUserRole };
