import {
  HostResponseV1,
  IArke,
  HostResponseWithIdentityResponseV1,
} from "@intreba/arke-api-client";
import i18n from "i18next";
import { action, observable, computed, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { PaginationStore } from "../../services/pagination/PaginationStore";
import { HostVisitsStore } from "./HostVisitsStore";
export default class HostsStore extends PaginationStore<HostResponseV1> {
  public async messageIdentity(siteId: string, hostId: string) {
    await this.arke.hosts.messageIdentity(siteId, hostId);
  }
  @observable
  public selectedHostDetails: HostResponseWithIdentityResponseV1 | null = null;
  @action
  public async getHostDetails(hostId: string) {
    this.isGettingItems = true;
    try {
      const hostDetailResponse = await this.arke.hosts.find(
        this.siteId,
        hostId
      );
      runInAction(() => {
        this.selectedHostDetails = hostDetailResponse;
      });
    } finally {
      runInAction(() => {
        this.isGettingItems = false;
      });
    }
  }
  @action public async getHost(hostId: any) {
    this.isGettingItems = true;
    try {
      if (this.selectedItem !== null) {
        if (this.selectedItem.hostId !== hostId) {
          this.selectHost(null);
          const host = await this.arke.hosts.find(this.siteId, hostId);
          this.selectHost(host);
        }
      } else {
        const host = await this.arke.hosts.find(this.siteId, hostId);
        this.selectHost(host);
      }
    } finally {
      runInAction(() => {
        this.isGettingItems = false;
      });
    }
  }
  @computed
  public get currentHostUrl(): string {
    if (this.selectedItem === null) {
      return "";
    }
    return `/sites/${this.siteId}/hosts/${this.selectedItem.hostId}`;
  }
  public hostPaginationStore: HostVisitsStore | null = null;
  @action public selectHost = async (host: HostResponseV1 | null) => {
    this.selectItem(host);
    if (host === null) {
      this.hostPaginationStore = null;
      this.selectedHostDetails = null;
    } else {
      this.hostPaginationStore = new HostVisitsStore(
        this.arke,
        this.siteId,
        host.hostId
      );
      await this.getHostDetails(host.hostId);
      this.hostPaginationStore.goToPage(1);
    }
  };
  @computed public get hostsUrl(): string {
    return `/sites/${this.siteId}/hosts`;
  }
  private arke: IArke;
  private siteId: string;
  @observable
  public search: string = "";

  constructor(arke: IArke, siteId: string) {
    super();
    this.arke = arke;
    this.siteId = siteId;
  }
  public getItems(): Promise<{
    items: HostResponseV1[];
    totalAmount: number;
  }> {
    return this.arke.hosts.list(
      this.siteId,
      this.pageToBeRetrieved,
      this.itemsPerPage,
      this.search === "" ? undefined : this.search
    );
  }
  @action
  public onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.search = event.target.value;
  };

  public remove = async (hostId: string, t: i18n.TFunction) => {
    try {
      await this.arke.hosts.remove(this.siteId, hostId);
      this.goToPage(this.currentPage);
      toast.success(t("removeSuccess"));
    } catch {
      toast.error(t("removeFail"));
    }
  };
}
