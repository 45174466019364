import {
  IArke,
  SitePermissions,
  SiteResponseV1
} from "@intreba/arke-api-client";
import i18n from "i18next";
import { action, observable, runInAction } from "mobx";
import BaseSettingsState from "../../BaseSettingsState";

export class NotificationsState extends BaseSettingsState {
  private arke: IArke;

  constructor(t: i18n.TFunction, arke: IArke, site: SiteResponseV1) {
    super(
      t,
      SitePermissions.sitenotificationsettingsupdate,
      site.permissions,
      site.siteId
    );
    this.arke = arke;
  }
  public async save(): Promise<void> {
    await this.arke.sites.updateNotificationSettings(this.siteId, {
      sendWelcomeMessage: this.sendWelcomeMessage,
      statusEmailList: this.statusEmailList,
      pushStatusNotification: this.sendPushNotifications ? 1 : 0,
      sendHostCalendarInvite: this.sendHostCalendarInvite
    });
  }
  public async retrieve(): Promise<void> {
    const settings = await this.arke.sites.findNotificationSettings(
      this.siteId
    );
    if (settings !== null) {
      runInAction(() => {
        this.sendPushNotifications = settings.pushStatusNotification > 0;
        this.statusEmailList = settings.statusEmailList;
        this.sendWelcomeMessage = settings.sendWelcomeMessage;
        this.sendHostCalendarInvite = settings.sendHostCalendarInvite;
      });
    }
  }
  @observable
  public sendWelcomeMessage: boolean = false;
  @observable
  public statusEmailList: string = "";
  @observable
  public sendPushNotifications: boolean = false;
  @observable
  public sendHostCalendarInvite: boolean = false;
  @action
  public changeStatusEmailList = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.statusEmailList = event.target.value;
  };
  @action
  public changeSendWelcomeMessage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.sendWelcomeMessage = !this.sendWelcomeMessage;
  };
  @action
  public changeSendPushNotifications = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.sendPushNotifications = !this.sendPushNotifications;
  };
  @action
  public changeSendHostCalendarInvite = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.sendHostCalendarInvite = !this.sendHostCalendarInvite;
  };
}
