import * as React from "react";
import "./Spinner.css";

interface ISpinnerProps {
  themed?: boolean;
  inline?: boolean;
  className?: string;
}

export default class Spinner extends React.PureComponent<ISpinnerProps, {}> {
  public static defaultProps: Partial<ISpinnerProps> = {
    themed: true,
  };
  public render() {
    let classNames = this.props.themed === true ? "spinner themed" : "spinner";

    if (this.props.className !== undefined) {
      classNames = classNames + " " + this.props.className;
    }
    return (
      <div
        className={classNames}
        style={{
          display: this.props.inline === true ? "inline-block" : undefined,
        }}
      >
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    );
  }
}
