import React from "react";
import { createState, TState } from "./createState";
import { useLocalStore } from "mobx-react"; // 6.x or mobx-react-lite@1.4.0

const appStateContext = React.createContext<TState | null>(null);

export const AppStateProvider = (props: { children: any }) => {
  const store = useLocalStore(createState);
  return (
    <appStateContext.Provider value={store}>
      {props.children}
    </appStateContext.Provider>
  );
};

export const useAppState = () => {
  const context = React.useContext(appStateContext);
  if (!context) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useAppState must be used within a valid app state provider."
    );
  }
  return context;
};

export const useSite = () => {
  const context = useAppState();
  if (
    context === null ||
    context.site === null ||
    context.itemContainer === undefined ||
    context.itemContainer === null ||
    context.itemContainer.site === null
  ) {
    throw new Error("useSiteAppState must be used within a valid site context");
  }
  return context.itemContainer.site;
};
