import { AdditionalFieldResponseV1 } from "@intreba/arke-api-client/dist/types/Entities/AdditionalFields";
import { ScreeningAdditionalField } from "@intreba/arke-api-client/dist/types/Entities/Screening";
import React, { useEffect } from "react";
import { Input } from "reactstrap";
import { QuestionSelectDesiredAnswer } from "./QuestionSelectDesiredAnswer";
import { ScreeningOrderSelect } from "./ScreeningOrderSelect";

export const AddNewQuestionRow = (props: {
  nextOrder: number;
  disabled: boolean;
  additionalFields: AdditionalFieldResponseV1[];
  selectedQuestions: ScreeningAdditionalField[];
  onAdd: (screeningQuestion: ScreeningAdditionalField) => void;
}) => {
  const [addingQuestionData, setAddingQuestionData] = React.useState<
    ScreeningAdditionalField
  >({
    order: props.nextOrder,
    additionalFieldId: "",
    expectedAnswer: "",
  });

  useEffect(() => {
    if (
      addingQuestionData.expectedAnswer !== "" &&
      addingQuestionData.additionalFieldId !== ""
    ) {
      props.onAdd(addingQuestionData);
    }
  }, [addingQuestionData]);

  return (
    <tr>
      <td>
        <ScreeningOrderSelect
          disabled={props.disabled}
          otherQuestions={props.selectedQuestions}
          value={addingQuestionData.order}
          nextOrder={props.nextOrder}
          onChange={(order) => {
            const newData = {
              ...addingQuestionData,
              order: order,
            };
            setAddingQuestionData(newData);
          }}
        />
      </td>
      <td style={{ width: "60%" }}>
        <Input
          type="select"
          disabled={props.disabled}
          name="selectMulti"
          id="selectAdditionalFieldForQuestion"
          value={addingQuestionData.additionalFieldId}
          onChange={(e) => {
            const newData = {
              ...addingQuestionData,
              additionalFieldId: e.target.value,
            };
            setAddingQuestionData(newData);
          }}
        >
          <option value="" disabled></option>
          {props.additionalFields
            .filter(
              (a) =>
                props.selectedQuestions.filter(
                  (s) => s.additionalFieldId === a.additionalFieldId
                ).length === 0
            )
            .map((a) => (
              <option key={a.additionalFieldId} value={a.additionalFieldId}>
                {a.name} ({a.description})
              </option>
            ))}
        </Input>
      </td>
      <td>
        <QuestionSelectDesiredAnswer
          disabled={props.disabled}
          question={addingQuestionData}
          onChange={(newAnswer) => {
            const newData = {
              ...addingQuestionData,
              expectedAnswer: newAnswer,
            };
            setAddingQuestionData(newData);
          }}
        />
      </td>
      <td></td>
    </tr>
  );
};
