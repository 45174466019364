import React, { useState } from "react";
import {
  Input,
  Badge,
  FormText,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";
import { Spinner } from "../../components";
import { useTranslation } from "react-i18next";

export const validateEmail = (value: string) => {
  let reg = /^( )*([._A-Za-z0-9-\\+]*)+@([_A-Za-z0-9-\\+]*)+\.([_A-Za-z0-9-\\+]*)+( )*$/;
  return reg.test(value ? value : "");
};

export const EmailInputModal = (props: {
  modalState: boolean;
  sendMessage: (recipientList: string[]) => void;
  toggleModal: (value: boolean) => void;
  working: boolean;
}) => {
  const { t } = useTranslation("messaging");

  const [sendPreviewTo, setSendPreviewTo] = useState<string>("");
  const [sendPreviewToList, setSendPreviewToList] = useState<string[]>([]);

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " " || e.key === "Enter") {
      if (validateEmail(sendPreviewTo)) {
        addEmailToSendList(sendPreviewTo.trim().toLowerCase());
      }
    }
  };

  const addEmailToSendList = (value: string) => {
    setSendPreviewToList([...sendPreviewToList, value]);
    setSendPreviewTo("");
  };

  const removeEmailFromList = (value: string) => {
    setSendPreviewToList(sendPreviewToList.filter((e) => e !== value));
  };

  const sendTestEmail = async () => {
    let value = [...sendPreviewToList];
    if (validateEmail(sendPreviewTo)) {
      value.push(sendPreviewTo.trim().toLowerCase());
    }
    await props.sendMessage(value);
    setSendPreviewTo("");
    setSendPreviewToList([]);
    props.toggleModal(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/ /g, "");
    setSendPreviewTo(value);
  };

  return (
    <Modal isOpen={props.modalState} toggle={() => props.toggleModal}>
      <ModalHeader>{t("sendTestMessage")}</ModalHeader>
      <ModalBody>
        <div>{t("sendTestMessageTo")}</div>
        <div>
          <Input
            value={sendPreviewTo}
            onKeyPress={(e) => onEnter(e)}
            onChange={(e) => onInputChange(e)}
            type="email"
            name="messageSubject"
            maxLength={70}
          />
          <FormText color="muted">{t("sendTestMessageHelp")}</FormText>
          {sendPreviewToList.map((value, key) => {
            return (
              <Badge
                key={key}
                style={{ marginLeft: 2, cursor: "pointer" }}
                color="secondary"
                onClick={() => removeEmailFromList(value)}
              >
                {value}
              </Badge>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={props.working || sendPreviewToList.length < 1}
          color="primary"
          onClick={async () => {
            await sendTestEmail();
          }}
        >
          {t("send")}
        </Button>
        <Button
          color="secondary"
          onClick={() => props.toggleModal(false)}
          disabled={props.working}
        >
          {t("common:cancel")}
        </Button>
        <div style={{ display: props.working ? "inline-block" : "none" }}>
          <Spinner className="m-0" />
        </div>
      </ModalFooter>
    </Modal>
  );
};
