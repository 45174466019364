import CardHeader from "reactstrap/lib/CardHeader";
import { ChevronLeft, Slash } from "react-feather";
import CardBody from "reactstrap/lib/CardBody";
import { Spinner, ClickAgainToConfirmButton } from "../../../../components";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { observer, inject } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { UsersStore } from "./UsersStore";
import {
  FormGroup,
  Col,
  Label,
  Input,
  UncontrolledTooltip,
  Button,
  Form,
} from "reactstrap";
import { RoleResponseV1 } from "@intreba/arke-api-client";
import { UserRole } from "./UserRole";

const CannotDeleteSlash = (props: { role: RoleResponseV1 }) => {
  const { t } = useTranslation("sitesettingsusers");
  let id = `r${props.role.role.toString(10)}${
    props.role.permissionId === null ? "" : props.role.permissionId
  }`;
  id = id.replace(/[^a-zA-Z0-9]/g, "");
  return (
    <>
      <Slash id={id} />
      <UncontrolledTooltip placement="right" target={id}>
        {props.role.accountLevelRole
          ? t("cannotManageAccount")
          : t("cannotManageSite")}
      </UncontrolledTooltip>
    </>
  );
};

const UserDetails = observer((props: { usersStore: UsersStore }) => {
  if (
    props.usersStore.userDetails === null ||
    props.usersStore.userDetailsOriginal === null
  ) {
    return null;
  }
  const { t } = useTranslation("sitesettingsusers");

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          props.usersStore.saveRoleChanges();
        }}
      >
        <FormGroup row={true} stlye={{ marginTop: "1rem" }}>
          <Label sm={2}>{t("roles")}</Label>
          <Col sm={4}>
            {props.usersStore.userDetails.map((r, key) => {
              return (
                <FormGroup
                  check={true}
                  key={`${r.role.toString(10)}${r.permission}`}
                >
                  <Label check={true}>
                    <Input
                      type="checkbox"
                      name="roles"
                      value={r.role}
                      checked={r.permission}
                      disabled={!r.manageable || props.usersStore.disabled}
                      onChange={(e) => props.usersStore.toggleRolesState(key)}
                    />
                    {r.accountLevelRole ? t("accountRole") : ""}
                    <UserRole role={r.role} />{" "}
                    {!r.manageable && (
                      <CannotDeleteSlash
                        role={{
                          role: r.role,
                          permissionId: r.permission ? "" : null,
                          manageable: r.manageable,
                          accountLevelRole: r.accountLevelRole,
                        }}
                      />
                    )}
                  </Label>
                </FormGroup>
              );
            })}
          </Col>
        </FormGroup>
        <FormGroup>
          <Button type="submit" color="primary">
            Save
          </Button>{" "}
          <ClickAgainToConfirmButton
            color="danger"
            onClick={() => props.usersStore.removePermissions()}
            disabled={
              !props.usersStore.userDetailsOriginal.deletable ||
              props.usersStore.disabled
            }
          >
            {t("removeUser")}
          </ClickAgainToConfirmButton>
        </FormGroup>
      </Form>
    </div>
  );
});

interface InjectedProps extends IProps {
  usersStore: UsersStore;
}

interface IProps extends WithTranslation {}

@inject("usersStore")
@observer
class UserDetailPageContent extends React.Component<
  InjectedProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public async componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    const email = params.get("email");
    if (email !== null) {
      this.injectedprops.usersStore.getUserDetails(email);
    }
  }

  private goBack = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.props.history.push(this.props.usersStore.usersUrl);
  };

  public render() {
    const props = this.injectedprops;
    return (
      <>
        <CardHeader>
          <a
            href={props.usersStore.usersUrl}
            style={{ marginLeft: "-10px" }}
            onClick={this.goBack}
          >
            <ChevronLeft />
            {props.t("back")}
          </a>
          <h2>
            {props.t("detailsTitle", { email: props.usersStore.selectedItem })}
          </h2>
        </CardHeader>
        <CardBody>
          {props.usersStore.isGettingItems === true && <Spinner />}

          {props.usersStore.isGettingItems === false &&
            props.usersStore.userDetails === null && (
              <h2>{props.t("common:notfound")}</h2>
            )}
          {props.usersStore.isGettingItems === false &&
            props.usersStore.userDetails !== null &&
            props.usersStore.selectedItem !== null && (
              <UserDetails usersStore={props.usersStore} />
            )}
          {props.usersStore.isWorking === true && <Spinner />}
        </CardBody>
      </>
    );
  }
}

const UserDetailPage = withTranslation("sitesettingsusers")(
  UserDetailPageContent
);

export { UserDetailPage };
