import React, { ChangeEvent } from "react";
import { Input, Form, FormGroup } from "reactstrap";
import { X } from "react-feather";

type IProps = {
  value: string;
  type: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled: boolean;
  resetSearch: () => void;
  onSubmit: (page: number) => void;
};

export const EnhancedSearch = (props: IProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);
  };

  const resetSearch = () => {
    if (props.resetSearch) props.resetSearch();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit(1);
  };
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup style={{ marginBottom: 0, padding: "0.3rem" }}>
        <div style={{ position: "relative" }}>
          <Input
            onChange={onChange}
            value={props.value}
            style={{ paddingRight: 35 }}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
          {props.value.length > 1 ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: 5,
                cursor: "pointer",
              }}
              onClick={resetSearch}
            >
              <X />
            </div>
          ) : (
            ""
          )}
        </div>
      </FormGroup>
    </Form>
  );
};
