import React from "react";
import {
  SiteResponseV1,
  IArke,
  WatchlistResponseV1,
  WatchlistType,
} from "@intreba/arke-api-client";
import { WithTranslation, withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";
import {
  CardHeader,
  Row,
  Col,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap";
import { Search } from "react-feather";
import WatchlistStore from "./WatchlistStore";
import PaginationView from "../../services/pagination/PaginationView";
import { WatchlistEntryRow } from "./WatchlistEntryRow";
import {
  ValidatedPhoneNumberInput,
  PhoneValidation,
} from "../../components/phonenumber/PhoneNumberInput";
import { Spinner } from "../../components";
import { AddWatchlistEntryStore } from "./AddWatchlistEntryStore";

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
  addWatchlistEntryStore: AddWatchlistEntryStore;
  watchlistStore: WatchlistStore;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke", "watchlistStore", "addWatchlistEntryStore")
@observer
class ViewWatchlist extends React.Component<
  InjectedProps & RouteComponentProps<{}>,
  {
    addNewEntryModal: boolean;
    selectModalTypeDropdownOpen: boolean;
    searchTypeDropdownOpen: boolean;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  constructor(props: any) {
    super(props);
    this.state = {
      addNewEntryModal: false,
      selectModalTypeDropdownOpen: false,
      searchTypeDropdownOpen: false,
    };
  }

  public extractKey(item: WatchlistResponseV1) {
    return item.watchlistId;
  }
  private onEntrySelect = (entry: WatchlistResponseV1) => {
    this.injectedprops.watchlistStore.selectEntry(entry);
    this.props.history.push(this.injectedprops.watchlistStore.currentEntryUrl);
  };
  public renderItem = ({ item }: { item: WatchlistResponseV1 }) => {
    return (
      <WatchlistEntryRow
        entry={item}
        type={item.type}
        t={this.injectedprops.t}
        onSelect={this.onEntrySelect}
      />
    );
  };

  public componentDidMount() {
    this.injectedprops.watchlistStore.setCountryCode(
      this.injectedprops.itemContainer.site.countryCode
    );
    this.injectedprops.watchlistStore.goToPage(1);
  }

  public onEntryTypeChange(value: WatchlistType) {
    let newEntry = { ...this.injectedprops.addWatchlistEntryStore.newEntry };
    newEntry.type = value;
    newEntry.pattern = "";
    newEntry.isValid = false;
    newEntry.notifyStaff = undefined;
    newEntry.blockVisits = undefined;
    this.injectedprops.addWatchlistEntryStore.onNewEntryInputChange(newEntry);
  }

  public onEntryValueChange(value: string, isValid: boolean) {
    let newEntry = { ...this.injectedprops.addWatchlistEntryStore.newEntry };
    newEntry.isValid = isValid;
    newEntry.pattern = value;
    this.injectedprops.addWatchlistEntryStore.onNewEntryInputChange(newEntry);
  }

  public onDescriptionChange(value: string) {
    let newEntry = { ...this.injectedprops.addWatchlistEntryStore.newEntry };
    newEntry.description = value;
    this.injectedprops.addWatchlistEntryStore.onNewEntryInputChange(newEntry);
  }

  public onNotifyManagersChange(value: boolean) {
    let newEntry = { ...this.injectedprops.addWatchlistEntryStore.newEntry };
    if (value === true) {
      newEntry.notifyStaff = true;
    } else {
      newEntry.notifyStaff = undefined;
    }
    this.injectedprops.addWatchlistEntryStore.onNewEntryInputChange(newEntry);
  }

  public onBlockVisitsChange(value: boolean) {
    let newEntry = { ...this.injectedprops.addWatchlistEntryStore.newEntry };
    if (value === true) {
      newEntry.blockVisits = true;
    } else {
      newEntry.blockVisits = undefined;
    }
    this.injectedprops.addWatchlistEntryStore.onNewEntryInputChange(newEntry);
  }

  public async onAddNewEntry() {
    await this.injectedprops.watchlistStore.addNewWatchlistEntry(
      this.injectedprops.addWatchlistEntryStore.newEntry
    );
  }

  private onSearch = (event: React.FormEvent) => {
    event.preventDefault();
    this.injectedprops.watchlistStore.goToPage(1);
  };

  render() {
    const t = this.injectedprops.t!;
    const isSouthAfrica =
      this.injectedprops.itemContainer.site.countryCode === "ZA";
    return (
      <>
        <Modal
          isOpen={
            this.injectedprops.addWatchlistEntryStore.addNewEntryModalOpen
          }
          toggle={() =>
            this.injectedprops.addWatchlistEntryStore.setNewEntryModal()
          }
          backdrop={true}
        >
          <ModalHeader>
            {t("addEntry").replace(
              "{#phonenumber}",
              isSouthAfrica
                ? ""
                : t(
                    "common:" +
                      WatchlistType[WatchlistType.PhoneNumber].toLowerCase()
                  )
            )}
          </ModalHeader>
          <ModalBody>
            {isSouthAfrica ? (
              <>
                <FormGroup style={{ marginTop: "1rem" }}>
                  <Label>{t("type")}</Label>{" "}
                  <InputGroup>
                    <Dropdown
                      isOpen={this.state.selectModalTypeDropdownOpen}
                      toggle={() =>
                        this.setState({
                          selectModalTypeDropdownOpen: !this.state
                            .selectModalTypeDropdownOpen,
                        })
                      }
                    >
                      <DropdownToggle caret style={{ width: "100%" }}>
                        {t(
                          "common:" +
                            WatchlistType[
                              this.injectedprops.addWatchlistEntryStore.newEntry
                                .type
                            ]
                        )}
                      </DropdownToggle>
                      <DropdownMenu style={{ width: "100%" }}>
                        {Object.keys(WatchlistType)
                          .filter(
                            (k) => typeof WatchlistType[k as any] != "number"
                          )
                          .map((value, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                style={{ width: "100%", textAlign: "center" }}
                                onClick={() =>
                                  this.onEntryTypeChange(WatchlistType[value])
                                }
                              >
                                {t("common:" + WatchlistType[value])}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </Dropdown>
                  </InputGroup>
                </FormGroup>
              </>
            ) : (
              <></>
            )}

            <FormGroup style={{ marginTop: "1rem" }}>
              <Label>
                {t(
                  "common:" +
                    WatchlistType[
                      this.injectedprops.addWatchlistEntryStore.newEntry.type
                    ]
                )}
              </Label>

              <InputGroup>
                {this.injectedprops.addWatchlistEntryStore.newEntry.type ===
                WatchlistType.PhoneNumber ? (
                  <ValidatedPhoneNumberInput
                    disabled={
                      this.injectedprops.watchlistStore.isGettingItems ||
                      this.injectedprops.watchlistStore.isWorking
                    }
                    id="value"
                    countryCode={
                      this.injectedprops.itemContainer.site.countryCode
                    }
                    onChange={(state) =>
                      this.onEntryValueChange(state.value, state.isValid)
                    }
                    value={
                      this.injectedprops.addWatchlistEntryStore.newEntry.pattern
                    }
                    validationType={PhoneValidation.Mobile}
                  />
                ) : (
                  <Input
                    type="text"
                    name="value"
                    id="value"
                    disabled={
                      this.injectedprops.watchlistStore.isGettingItems ||
                      this.injectedprops.watchlistStore.isWorking
                    }
                    maxLength={150}
                    value={
                      this.injectedprops.addWatchlistEntryStore.newEntry.pattern
                    }
                    onChange={(state) =>
                      this.onEntryValueChange(state.target.value, true)
                    }
                  />
                )}
              </InputGroup>
            </FormGroup>
            <FormGroup style={{ marginTop: "1rem" }}>
              <Label>{t("description")}</Label>
              <InputGroup>
                <Input
                  type="textarea"
                  className="form-control"
                  name="description"
                  id="description"
                  disabled={
                    this.injectedprops.watchlistStore.isGettingItems ||
                    this.injectedprops.watchlistStore.isWorking
                  }
                  onChange={(value) =>
                    this.onDescriptionChange(value.target.value)
                  }
                />
              </InputGroup>
            </FormGroup>
            <FormGroup tyle={{ marginTop: "1rem" }} check={true}>
              <Label check={true}>
                <Input
                  name="notifyStaff"
                  type="checkbox"
                  checked={
                    this.injectedprops.addWatchlistEntryStore.newEntry
                      .notifyStaff !== undefined
                  }
                  onChange={(e) =>
                    this.onNotifyManagersChange(e.target.checked)
                  }
                />
                {t("notifyStaff")}
              </Label>
            </FormGroup>
            <FormGroup style={{ marginTop: "1rem" }} check={true}>
              <Label check={true}>
                <Input
                  name="blockVisits"
                  type="checkbox"
                  checked={
                    this.injectedprops.addWatchlistEntryStore.newEntry
                      .blockVisits !== undefined
                  }
                  disabled={
                    this.injectedprops.addWatchlistEntryStore.newEntry.type ===
                    WatchlistType.LicensePlate
                  }
                  onChange={(e) => this.onBlockVisitsChange(e.target.checked)}
                />
                {t("blockVisits")}
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={
                this.injectedprops.watchlistStore.isGettingItems ||
                this.injectedprops.watchlistStore.isWorking ||
                !this.injectedprops.addWatchlistEntryStore.newEntry.isValid
              }
              color="primary"
              onClick={async () => {
                await this.onAddNewEntry();
                this.injectedprops.addWatchlistEntryStore.setNewEntryModal(
                  false
                );
              }}
            >
              {t("common:add")}
            </Button>{" "}
            <Button
              disabled={
                this.injectedprops.watchlistStore.isGettingItems ||
                this.injectedprops.watchlistStore.isWorking
              }
              color="secondary"
              onClick={() =>
                this.injectedprops.addWatchlistEntryStore.setNewEntryModal(
                  false
                )
              }
            >
              {t("common:cancel")}
            </Button>
          </ModalFooter>
          {this.injectedprops.watchlistStore.isGettingItems ||
            (this.injectedprops.watchlistStore.isWorking && <Spinner />)}
        </Modal>
        <CardHeader tag="div">
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={2}>
              <h2>{t("title")}</h2>
            </Col>
            <Col sm={{ offset: 8, size: 2 }}>
              <Button
                outline={true}
                color="secondary"
                block={true}
                onClick={() =>
                  this.injectedprops.addWatchlistEntryStore.setNewEntryModal()
                }
              >
                {t("addEntry").replace("{#phonenumber}", "")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 1, offset: 8 }}>
              <Search
                style={{
                  marginTop: "6px",
                  marginRight: "-25px",
                  float: "right",
                }}
              />
            </Col>

            <Col md={{ size: 3 }}>
              <Form onSubmit={(e) => this.onSearch(e)}>
                <FormGroup style={{ marginBottom: 0 }}>
                  <Input
                    type="text"
                    value={this.injectedprops.watchlistStore.search}
                    onChange={this.injectedprops.watchlistStore.onSearchChange}
                    placeholder={this.props.t("searchPlaceholder")}
                    disabled={
                      this.injectedprops.watchlistStore.isGettingItems ||
                      this.injectedprops.watchlistStore.isWorking
                    }
                  />
                </FormGroup>
                {isSouthAfrica ? (
                  <FormGroup style={{ marginBottom: 0 }}>
                    <Dropdown
                      isOpen={this.state.searchTypeDropdownOpen}
                      toggle={() =>
                        this.setState({
                          searchTypeDropdownOpen: !this.state
                            .searchTypeDropdownOpen,
                        })
                      }
                    >
                      <DropdownToggle caret style={{ width: "100%" }}>
                        {t("show")}
                        {" : "}
                        {t(
                          "common:" +
                            this.injectedprops.watchlistStore.getDropdownTypeFilter()
                        )}
                      </DropdownToggle>
                      <DropdownMenu style={{ width: "100%" }}>
                        <DropdownItem
                          style={{ width: "100%", textAlign: "center" }}
                          onClick={() =>
                            this.injectedprops.watchlistStore.setDropdownTypeFilter()
                          }
                        >
                          {t("all")}
                        </DropdownItem>
                        {Object.keys(WatchlistType)
                          .filter(
                            (k) => typeof WatchlistType[k as any] !== "number"
                          )
                          .map((value, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                style={{ width: "100%", textAlign: "center" }}
                                onClick={() =>
                                  this.injectedprops.watchlistStore.setDropdownTypeFilter(
                                    WatchlistType[value]
                                  )
                                }
                              >
                                {t("common:" + WatchlistType[value])}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                ) : (
                  <></>
                )}
              </Form>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
              >
                {this.injectedprops.watchlistStore.isGettingItems === true && (
                  <Spinner />
                )}
              </div>

              <PaginationView
                renderItem={this.renderItem}
                keyExtractor={this.extractKey}
                store={this.injectedprops.watchlistStore}
                columns={[
                  t("value"),
                  t("type"),
                  t("description"),
                  t("dateAdded"),
                  "",
                ]}
              />
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

export default withTranslation("watchlist")(ViewWatchlist);
