import { inject, observer } from "mobx-react";
import * as React from "react";
import AuthState from "./AuthState";

export interface InjectedProps extends IProps {
  authState: AuthState;
}

export interface IProps {}

@inject("authState")
@observer
export class Signin extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public async componentDidMount() {
    await this.injectedprops.authState.signinSilent();
  }
  public render() {
    return <></>;
  }
}
