import AuthState from "../../services/authentication/AuthState";

export async function resolveAccessToken(
  authState: AuthState
): Promise<string | null> {
  if (authState.user === undefined) {
    return Promise.reject("No user in authState");
  }
  console.debug(
    "Resolving access token for Arke Client: " + authState.user.access_token
  );
  if (authState.user.expired) {
    await authState.signinSilent();
  }
  return Promise.resolve(authState.user.access_token);
}
