import React, { useRef, useEffect } from "react";
import marked from "marked";
import DOMpurify from "dompurify";
import moment from "moment";
import { ArrowLeft, Unlock, Lock, Send } from "react-feather";
import ShowWithPermission from "../../components/user/ShowWithPermission";
import { SitePermissions, ConversationStatus } from "@intreba/arke-api-client";
import { Button, Col, Row } from "reactstrap";
import { RichEditor } from "./RichEditor";
import { Spinner } from "../../components";
import { ConversationContent, MessageContent } from "./ConversationStore";
import { EditorState } from "draft-js";
import { useTranslation } from "react-i18next";

type IProps = {
  messageId: string;
  markdownContent: string;
  sentAt: string;
  from?: string;
};

const getMarkdownText = (html: string) => {
  var rawMarkup = DOMpurify.sanitize(marked(html));
  return { __html: rawMarkup };
};

export const ChatText = (props: IProps) => {
  if (!props.from) {
    return (
      <div
        key={props.messageId}
        className="message message-right"
        style={{
          transform: "rotateX(180deg)",
        }}
      >
        <div className="message-body">
          <div className="message-row">
            <div className="d-flex align-items-center justify-content-end">
              <div className="message-content bg-primary text-white">
                <div
                  dangerouslySetInnerHTML={getMarkdownText(
                    props.markdownContent
                  )}
                ></div>
                <div className="mt-1">
                  <small style={{ opacity: 0.65 }}>
                    {moment.utc(props.sentAt).local().format("LT l")}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      key={props.messageId}
      className="message"
      style={{
        transform: "rotateX(180deg)",
      }}
    >
      <div className="message-body">
        <div className="message-row">
          <div className="d-flex align-items-center">
            <div className="message-content bg-light">
              <h5 className="mb-2">{props.from}</h5>
              <div
                dangerouslySetInnerHTML={getMarkdownText(props.markdownContent)}
              ></div>

              <div className="mt-1">
                <small
                  className="text-muted opacity-65"
                  style={{ opacity: 0.65 }}
                >
                  {moment.utc(props.sentAt).local().format("LT l")}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type HeaderProps = {
  closeChat: () => void;
  subject?: string;
  participants?: { [key: string]: string };
  status: ConversationStatus;
  updateStatus?: (status: ConversationStatus) => void;
};

export const ChatHeader = (props: HeaderProps) => {
  const { t } = useTranslation("conversations");
  return (
    <div className="chat-header border-bottom py-4 py-lg-6 px-lg-8">
      <div className="container-xxl">
        <div className="row align-items-center">
          <div className="col-3 d-xl-none">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <Button
                  color="link"
                  className="text-muted px-0"
                  href="#"
                  data-chat="open"
                >
                  <ArrowLeft
                    onClick={(e) => {
                      e.preventDefault();
                      props.closeChat();
                    }}
                  />
                  <i className="icon-md fe-chevron-left"></i>
                </Button>
              </li>
            </ul>
          </div>

          <div className="col-6 col-xl-6">
            <div className="media text-center text-xl-left">
              <div className="avatar avatar-sm d-none d-xl-inline-block mr-5"></div>

              <div
                className="media-body align-self-center"
                style={{ whiteSpace: "nowrap" }}
              >
                <h6 className="mb-n1" style={{ fontWeight: "bold" }}>
                  {props.subject}
                </h6>
                <small className="text-muted">
                  {props.participants ? (
                    <div>
                      {"Conversation with <"}
                      {Object.entries(props.participants).map((value, key) => {
                        if (key === 0) return `${value[1]}`;
                        return `, ${value[1]}`;
                      })}
                      {">"}
                    </div>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </div>
          </div>

          <div className="col-3 col-xl-6 text-right">
            <ul className="nav justify-content-end">
              <li
                className="nav-item list-inline-item d-none d-xl-block mr-3"
                style={{ textAlign: "center" }}
              >
                <ShowWithPermission
                  permission={SitePermissions.broadcastcreate}
                >
                  <Button
                    color="link"
                    className="nav-link px-3"
                    title="Change conversation status"
                    onClick={(e) => {
                      e.preventDefault();
                      if (props.updateStatus !== undefined) {
                        let response = ConversationStatus.Closed;
                        if (props.status === ConversationStatus.Closed) {
                          response = ConversationStatus.Open;
                        }
                        props.updateStatus(response);
                      }
                    }}
                  >
                    {props.status === 0 ? (
                      <>
                        <Lock></Lock>
                        <p>
                          <small>{t("markAsClosed")}</small>
                        </p>
                      </>
                    ) : props.status === 1 ? (
                      <>
                        <Unlock></Unlock>
                        <p>
                          <small>{t("markAsOpen")}</small>
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </Button>
                </ShowWithPermission>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChatContent = (props: {
  mainChatVisible: boolean;
  subject: string;
  isStaff: boolean;
  closeChat: () => void;
  participants?: { [key: string]: string };
  status: number;
  updateStatus?: (staus: ConversationStatus) => Promise<void>;
  selectedConversation: ConversationContent;
  pageNumber: number;
  isWorking: boolean;
  messageMarkdowncontent: EditorState;
  setMessageMarkdowncontent: (state: EditorState) => void;
  sendMessage: () => Promise<void>;
  getNextMessages?: () => Promise<void>;
  selectedConversationUrl: string;
  disabledReply?: boolean;
}) => {
  const messagesContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (messagesContainer.current != null) {
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight;
    }
  }, []);

  const onScroll = async () => {
    if (!messagesContainer.current) return;
    if (props.getNextMessages) {
      if (messagesContainer.current.scrollTop === 0 && !props.isWorking) {
        messagesContainer.current.scrollTop = 5;
        await props.getNextMessages();
      }
    }
  };

  useEffect(() => {
    if (messagesContainer.current === null) return;
    if (props.pageNumber === 1)
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight;
  });

  window.history.pushState(
    {},
    "selectedConversation",
    props.selectedConversationUrl
  );

  const sendMessage = async () => {
    await props.sendMessage();
  };

  const updateStatus = async (status: ConversationStatus) => {
    if (props.updateStatus) {
      await props.updateStatus(status);
    }
  };

  return (
    <>
      <div
        className={`main ${props.mainChatVisible ? "main-visible" : ""}`}
        data-mobile-height=""
      >
        <div
          id="chat-2"
          className="chat dropzone-form-js"
          data-dz-url="some.php"
        >
          <div className="chat-body">
            <ChatHeader
              subject={props.subject}
              participants={props.participants}
              status={props.status}
              updateStatus={(status: ConversationStatus) =>
                updateStatus(status)
              }
              closeChat={() => props.closeChat()}
            />
            <div
              className="chat-content px-lg-8"
              ref={messagesContainer}
              onScroll={onScroll}
              style={{
                minHeight: 100,
                overflowY: "scroll",
                maxHeight: props.disabledReply ? "100%" : 500,
                paddingRight: "2rem",
                paddingLeft: "2rem",
              }}
            >
              <div
                className="container-xxl vertical flip-container py-6 py-lg-10"
                style={{
                  transform: "rotateX(180deg)",
                  width: "100%",
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: "auto",
                  marginLeft: "auto",
                  paddingTop: "3.5rem",
                  paddingBottom: "3.5rem",
                }}
              >
                {props.selectedConversation.messageList.map(
                  (item: MessageContent, index) => {
                    let check =
                      (item.onBehalfOf === null && props.isStaff === false) ||
                      (item.onBehalfOf !== null && props.isStaff === true);
                    let from: undefined | string = undefined;
                    if (!check) {
                      from = props.participants
                        ? props.participants[item.from]
                        : "";
                      if (from === undefined) {
                        from = "Management";
                      }
                    }
                    return (
                      <ChatText
                        key={index}
                        messageId={item.messageId}
                        markdownContent={item.markdownContent}
                        sentAt={item.sentAt}
                        from={from}
                      />
                    );
                  }
                )}
              </div>

              <div className="end-of-chat"></div>
            </div>
            {props.disabledReply === true ? (
              <></>
            ) : (
              <div
                className="chat-footer border-top py-lg-6 px-lg-8"
                style={{ overflowY: "auto", overflowX: "unset" }}
              >
                <Row style={{ margin: 0 }}>
                  <Col style={{ padding: 0 }}>
                    {props.isWorking && (
                      <div style={{ position: "absolute", width: "100%" }}>
                        <Spinner />
                      </div>
                    )}
                    <RichEditor
                      onChange={(state) =>
                        props.setMessageMarkdowncontent(state)
                      }
                      state={props.messageMarkdowncontent}
                      actionButton={
                        <Button
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            margin: "auto",
                          }}
                          color={"primary"}
                          disabled={
                            props.isWorking ||
                            !props.messageMarkdowncontent
                              .getCurrentContent()
                              .hasText()
                          }
                          onClick={sendMessage}
                        >
                          <Send />
                        </Button>
                      }
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
