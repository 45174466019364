import { IArke, FullVisitResponse } from "@intreba/arke-api-client";
import { computed } from "mobx";
import { PaginationStore } from "../../services/pagination/PaginationStore";

class WatchlistVisitsStore extends PaginationStore<FullVisitResponse> {
  private arke: IArke;
  private siteId: string;
  private watchlistId: string;

  @computed
  public get currentVisitUrl(): string {
    if (this.selectedItem === null) {
      return "";
    }
    return `/sites/${this.siteId}/visits/${this.selectedItem.visitId}`;
  }
  constructor(arke: IArke, siteId: string, watchlistId: string) {
    super();
    this.arke = arke;
    this.watchlistId = watchlistId;
    this.siteId = siteId;
    this.itemsPerPage = 10;
  }

  public getItems(): Promise<{
    items: FullVisitResponse[];
    totalAmount: number;
  }> {
    let items = this.arke.visits.listWatchlistEntryEvents(
      this.siteId,
      this.watchlistId,
      this.pageToBeRetrieved,
      this.itemsPerPage
    );
    return items;
  }
}

export { WatchlistVisitsStore };
